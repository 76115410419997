import {
    IonCol,
    IonContent,
    IonPage,
    IonRow,
    useIonViewDidEnter,
  } from "@ionic/react";
  import styles from "./register.module.scss";
  import { useEffect } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import useState from "react-usestateref";
  import { AuthState } from "../../_redux/models/auth.model";
  import { RecuperarPartida } from "../../utils/functions";
  import { PartidaState } from "../../_redux/models/partida.model";
import ConfettiExplosion from "@reonomy/react-confetti-explosion";
import CorrectoLottie from "../../components/Lotties/CorrectoLottie";
import { useFirebaseAnalytics } from "../../hooks/useFirebaseAnalytics";



  const Register: React.FC<any> = (props) => {
    const { logScreenView } = useFirebaseAnalytics();
    useIonViewDidEnter(() => {
      logScreenView('RegistroOff');
    });

    const dispatch = useDispatch();
    const authUser: AuthState = useSelector((state: any) => state.auth);
    const partida : PartidaState = useSelector((state: any) => state.partida);
    const [isExploding, setIsExploding] = useState(false);

   

    const handleClick = () => {
        setIsExploding(false);
      };

    useEffect(() => {
        setIsExploding(true);
        RecuperarPartida();
        
    }, []);
  
    return (
      <IonPage>
        <IonContent>
          <IonRow>
            <IonCol size="12" size-md="9" className={styles.centro_pagina_quiz}>
             
                <div className={styles.container_center_item}>
                    
                         {isExploding ? <ConfettiExplosion  duration={6000} floorWidth={1000}  floorHeight={800} force={0.6} particleCount={150} /> :''}
                </div>
                   <h1>A Coruña es la respuesta correcta</h1>
                   
                   <CorrectoLottie/>
                   
                </IonCol>
  
            <IonCol size="12" size-md="3" className={styles.lateral_quiz}>
              <h2>Publicidad aqui, quiero mucha pasta</h2>
              
            </IonCol>
          </IonRow>
  
    
        </IonContent>
      </IonPage>
    );
  };
  
  export default Register;
  
  
  