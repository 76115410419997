import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  useIonActionSheet,
  useIonViewDidEnter,
} from "@ionic/react";
import { useSelector } from "react-redux";
import { AuthState } from "../../_redux/models/auth.model";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { authActions, gameActions } from "../../_redux/actions";
import styles from "./profile.module.scss";
import { useEffect, useState } from "react";
import { getVersion } from "../../utils/functions";
import {
  apps,
  chevronForward,
  closeOutline,
  create,
  ellipsisHorizontal,
  ellipsisVertical,
  fileTrayFull,
  gameController,
  images,
  language,
  logoFacebook,
  logoInstagram,
  logoTwitter,
  logoWebComponent,
  logoYoutube,
  map,
  person,
  personAdd,
  settings,
  trash,
} from "ionicons/icons";
import { history } from "../../_helpers/history";
import { gameService } from "../../_redux/services";
import ListAvatar from "../../components/Avatar/ListAvatar";
import { useFirebaseAnalytics } from "../../hooks/useFirebaseAnalytics";
import CrearCuenta from "../../components/Modal/CrearCuenta";
import { MenuApp } from "../../utils/functions";
import i18n from "../../i18nextConf";

const Profile: React.FC = () => {
  const { logScreenView } = useFirebaseAnalytics();
  useIonViewDidEnter(() => {
    logScreenView("PerfilUsuario");
  });
  const { t } = useTranslation();
  const authUser: AuthState = useSelector((state: any) => state.auth);
  const [present, dismiss] = useIonActionSheet();
  const [loginModal, setLoginModal] = useState(false);
  const abrirModal = () => {
    setLoginModal(true);
  };

  const cerrarModal = () => {
    setLoginModal(false);
  };
  const dispatch = useDispatch();
  const [version, setVersion] = useState("");
  const [myProfile, setMyProfile] = useState(null);
  const [shownickModal, setShownickModal] = useState(false);
  const [nick, setNick] = useState<string>("Nick");

  const [showAvatarModal, setShowAvatarModal] = useState(false);
  const [showIdiomaModal, setShowIdiomaModal] = useState(false);
  const [showPrivacidadModal, setShowPrivacidadModal] = useState(false);
  const [showCuentaModal, setShowCuentaModal] = useState(false);
  const [showAppModal, setShowAppModal] = useState(false);

  const [avatar, setAvatar] = useState<string>("");
  const final = [];

  const modificarIdioma = (language: string) => {
    i18n.changeLanguage(language);
    setShowIdiomaModal(false);
  };

  const facebookLink = () => {
    window.open(
      "https://www.facebook.com/people/Peke-Games/100037288136176/",
      "_blank"
    );
  };
  const twitterLink = () => {
    window.open("https://twitter.com/PekeGames", "_blank");
  };
  const pekegamesLink = () => {
    window.open("https://www.pekegames.app", "_blank");
  };
  const youtubeLink = () => {
    window.open(
      "https://www.youtube.com/channel/UCGUe2P_-tURFmAtEHvpUGoQ",
      "_blank"
    );
  };
  const instagramLink = () => {
    window.open("https://www.instagram.com/pekegamess/", "_blank");
  };

  const modificarAvatar = (myavatar: number) => {
    try {
      setAvatar("a" + myavatar + ".svg");
      authUser.user.Avatar = "a" + myavatar + ".svg";
      gameService.CambiarAvatar(authUser.user);
      setShowAvatarModal(false);
    } catch (error) {
      // alert(error);
    }
  };

  for (let i = 1; i <= 99; i++) {
    final.push(
      <img
        alt="avatar"
        src={`https://www.pekegames.app/img/avatar/a${i}.svg`}
        onClick={() => modificarAvatar(i)}
        className="img_avatar_mini"
        key={i}
      ></img>
    );
  }

  useEffect(() => {
    console.log("useEffect", authUser.user);
    async function fetchVersion() {
      dispatch(gameActions.IsBusy());
      setVersion(await getVersion());

      if (authUser?.user?.Auth && authUser.user.Id !== "-1") {
        const perfil = await gameService.Perfil();
        if (perfil !== null) {
          //dispatch(authActions.cargarDatosSinNavegacion(perfil));
          setMyProfile(perfil);
          //setNick(perfil.Nick);
        }
      } else {
      }
      dispatch(gameActions.QuitarIsBusy());
    }
    fetchVersion();
  }, []);

  useEffect(() => {
    setNick(authUser?.user?.Nick);
  }, [authUser]);

  const modalEliminarCuenta = () => {
    setShowCuentaModal(true);
  };

  const modalSobreLaApp = () => {
    setShowAppModal(true);
  };
  const deleteCuentaPeke = () => {
    alert("eliminar cuenta");
  };
  const modalNick = () => {
    setShownickModal(true);
  };
  const modalAvatar = () => {
    setShowAvatarModal(true);
  };

  const modalIdioma = () => {
    setShowIdiomaModal(true);
  };

  const modalPrivacidad = () => {
    setShowPrivacidadModal(true);
  };

  const modificarNick = () => {
    try {
      if (nick !== null && nick.length >= 3) {
        authUser.user.Nick = nick;
        gameService.CambiarNick(authUser.user);
        setShownickModal(false);
      }
    } catch (error) {
      alert(error);
    }
  };

  const logout = () => {
    dispatch(authActions.logout());
  };

  return (
    <IonPage id="page-dashboard" className="page-dashboard">
      <IonContent className="bg_app_content">
        <IonToolbar>
          {MenuApp(present, t)}
          <IonTitle>{t("settings")}</IonTitle>
          <IonButtons slot="start">
            <IonBackButton defaultHref="dashboard" />
          </IonButtons>
        </IonToolbar>
        <IonRow>
          <IonCol size="12" size-md="8" className={styles.centro_pagina}>
            <div className={styles.headerPhotoProfile}>
              {authUser?.user?.Auth && (
                <img
                  className={styles.avatar1}
                  alt="Avatar"
                  src={`https://www.pekegames.app/img/avatar/${authUser.user?.Avatar}`}
                ></img>
              )}

              {!authUser?.user?.Auth && (
                <img
                  className={styles.avatar1}
                  alt="Avatar"
                  src={`https://www.pekegames.app/img/avatar/a0.svg`}
                ></img>
              )}
            </div>

            <div className={styles.headerDataProfile}>
              <span>
                {authUser.user?.Nick == null ? t("guest") : authUser.user?.Nick}
              </span>
            </div>

            {authUser?.user?.Auth && (
              <div className={styles.caja_profile}>
                <h3 className="titulo_principal">
                  {t("balance_in_pekeGames")}
                </h3>
                <IonRow>
                  <IonCol className="text_center"></IonCol>
                  <IonCol className="text_center"></IonCol>
                </IonRow>
                <IonRow>
                  <IonCol className="text_center ">
                    <img
                      width={60}
                      src="/assets/icon/moneda.png"
                      alt="moneda"
                    ></img>
                    <span>{authUser.user?.DatosExtendido?.MonedaGlobal}</span>
                  </IonCol>
                  <IonCol className="text_center">
                    <img
                      width={60}
                      src="/assets/icon/puntos.png"
                      alt={t("points")}
                    ></img>
                    <span>
                      {" "}
                      {authUser.user?.DatosExtendido?.PuntosGlobales}
                    </span>
                  </IonCol>
                </IonRow>
              </div>
            )}

            {!authUser?.user?.Auth && (
              <div className={styles.caja_profile}>
                <h3>{t("create_account")}</h3>
                <p>{t("create_account_description")}</p>
                <IonButton
                  color="success"
                  shape="round"
                  size="large"
                  onClick={abrirModal}
                >
                  <IonIcon slot="start" icon={personAdd} />
                  {t("create_account")}
                </IonButton>
              </div>
            )}

            {authUser?.user?.Auth && Array.isArray(myProfile?.LogrosUsuario) && (
              <div className={styles.caja_profile}>
                <h3 className="titulo_principal">Logros</h3>
                <div className="horizontal-scroll-wrapper squares">
                  {Array.isArray(myProfile?.LogrosUsuario) &&
                    myProfile?.LogrosUsuario.map((item, index) => {
                      return (
                        <IonCard className="ionCard  squares" key={index}>
                          <img
                            alt="logo"
                            className={styles.logo_img}
                            src={
                              "https://www.pekegames.app/img/logro/" +
                              item?.Image
                            }
                          ></img>
                          <IonCardContent>
                            <IonCardTitle>{t("number")}</IonCardTitle>
                          </IonCardContent>
                        </IonCard>
                      );
                    })}
                </div>
              </div>
            )}

            {authUser?.user?.Auth && (
              <div className={styles.caja_profile}>
                <IonRow>
                  <IonCol sizeLg="3" sizeXs="0"></IonCol>
                  <IonCol className={styles.cabecera}>{t("games")}</IonCol>
                  <IonCol className={styles.cabecera}>{t("correct")}</IonCol>
                  <IonCol className={styles.cabecera}>{t("incorrect")}</IonCol>
                  <IonCol sizeLg="3" sizeXs="0"></IonCol>
                </IonRow>
                <IonRow>
                  <IonCol sizeLg="3" sizeXs="0"></IonCol>
                  <IonCol
                    className={styles.item_valor + " " + styles.color_primary}
                  >
                    {authUser.user?.DatosExtendido?.NumeroPartida}
                  </IonCol>
                  <IonCol className={styles.item_valor + " " + styles.verde}>
                    {authUser.user?.DatosExtendido?.Aciertos}
                  </IonCol>
                  <IonCol className={styles.item_valor + " " + styles.error}>
                    {authUser.user?.DatosExtendido?.Errores}
                  </IonCol>
                  <IonCol sizeLg="3" sizeXs="0"></IonCol>
                </IonRow>
              </div>
            )}
            <div className={styles.caja_profile}>
              <h3>{t("game_helps")}</h3>
              <IonRow>
                <IonCol className="text_center">
                  <img
                    alt={t("help_50")}
                    src="/assets/icon/ayuda50.png"
                    className="icon_ayuda_perfil"
                  ></img>
                </IonCol>
                <IonCol className="text_center">
                  <img
                    alt={t("help_longer")}
                    src="/assets/icon/ayudaMasTiempo.png"
                    className="icon_ayuda_perfil"
                  ></img>
                </IonCol>
                <IonCol className="text_center">
                  <img
                    alt={t("help_not_to_lose_points")}
                    src="/assets/icon/ayudaRecuperarPuntos.png"
                    className="icon_ayuda_perfil"
                  ></img>
                </IonCol>
                <IonCol className="text_center">
                  <img
                    alt={t("help_correct_answer")}
                    src="/assets/icon/ayudaRespuestaCorrecta.png"
                    className="icon_ayuda_perfil"
                  ></img>
                </IonCol>
                <IonCol className="text_center">
                  <img
                    alt={t("help_doble")}
                    src="/assets/icon/oportunidadDoble.png"
                    className="icon_ayuda_perfil"
                  ></img>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol className="text_center">
                  {authUser.user?.DatosExtendido?.Ayuda50}
                </IonCol>
                <IonCol className="text_center">
                  {authUser.user?.DatosExtendido?.AyudaMasTiempo}
                </IonCol>
                <IonCol className="text_center">
                  {authUser.user?.DatosExtendido?.RecuperarPuntosPerdido}
                </IonCol>
                <IonCol className="text_center">
                  {authUser.user?.DatosExtendido?.AyudaPreguntaCorrecta}
                </IonCol>
                <IonCol className="text_center">
                  {authUser.user?.DatosExtendido?.AyudaContestar2Veces}
                </IonCol>
              </IonRow>
            </div>

            {authUser?.user?.Auth && (
              <div className={styles.caja_profile}>
                <IonRow>
                  <IonCol sizeLg="3" sizeXs="0"></IonCol>
                  <IonCol className={styles.cabecera}>
                    {" "}
                    {t("better_position")}
                  </IonCol>
                  <IonCol className={styles.cabecera}>
                    {" "}
                    {t("best_score")}
                  </IonCol>
                  <IonCol className={styles.cabecera}>
                    {t("highest_level")}
                  </IonCol>
                  <IonCol sizeLg="3" sizeXs="0"></IonCol>
                </IonRow>
                <IonRow>
                  <IonCol sizeLg="3" sizeXs="0"></IonCol>
                  <IonCol
                    className={styles.item_valor + " " + styles.color_primary}
                  >
                    {authUser.user?.DatosExtendido?.MejorPosicion}
                  </IonCol>
                  <IonCol
                    className={styles.item_valor + " " + styles.color_primary}
                  >
                    {authUser.user?.DatosExtendido?.MejorPuntuacion}
                  </IonCol>
                  <IonCol
                    className={styles.item_valor + " " + styles.color_primary}
                  >
                    {authUser.user?.DatosExtendido?.MejorNivel}
                  </IonCol>
                  <IonCol sizeLg="3" sizeXs="0"></IonCol>
                </IonRow>
              </div>
            )}

            <div className="c_menu_lateral">
              {authUser?.user?.Auth && (
                <IonList>
                  <IonItem onClick={modalNick}>
                    <IonIcon color="primary" icon={create} />
                    <IonLabel className="btn_menu_home" color="primary">
                      {t("change_nickname")}
                    </IonLabel>
                    <IonIcon icon={chevronForward} />
                  </IonItem>

                  <IonItem onClick={modalAvatar}>
                    <IonIcon color="primary" icon={person} />
                    <IonLabel className="btn_menu_home">
                      {" "}
                      {t("change_avatar")}
                    </IonLabel>
                    <IonIcon icon={chevronForward} />
                  </IonItem>

                  <IonItem onClick={modalIdioma}>
                    <IonIcon color="primary" icon={language} />
                    <IonLabel className="btn_menu_home">
                      {" "}
                      {t("change_language")}
                    </IonLabel>
                    <IonIcon icon={chevronForward} />
                  </IonItem>

                  <IonItem onClick={modalEliminarCuenta}>
                    <IonIcon color="primary" icon={trash} />
                    <IonLabel className="btn_menu_home">
                      {" "}
                      {t("delete_account")}
                    </IonLabel>
                    <IonIcon icon={chevronForward} />
                  </IonItem>
                  <IonItem onClick={modalPrivacidad}>
                    <IonIcon color="primary" icon={fileTrayFull} />
                    <IonLabel className="btn_menu_home">
                      {" "}
                      {t("privacy_policy")}
                    </IonLabel>
                    <IonIcon icon={chevronForward} />
                  </IonItem>
                  <IonItem onClick={modalSobreLaApp}>
                    <IonIcon color="primary" icon={gameController} />
                    <IonLabel className="btn_menu_home">
                      {" "}
                      {t("about_the_app")}
                    </IonLabel>
                    <IonIcon icon={chevronForward} />
                  </IonItem>
                  <IonItem onClick={facebookLink}>
                    <IonIcon color="primary" icon={logoFacebook} />
                    <IonLabel className="btn_menu_home"> Facebook</IonLabel>
                    <IonIcon icon={chevronForward} />
                  </IonItem>
                  <IonItem onClick={twitterLink}>
                    <IonIcon color="primary" icon={logoTwitter} />
                    <IonLabel className="btn_menu_home"> Twitter</IonLabel>
                    <IonIcon icon={chevronForward} />
                  </IonItem>
                  <IonItem onClick={youtubeLink}>
                    <IonIcon color="primary" icon={logoYoutube} />
                    <IonLabel className="btn_menu_home"> Youtube</IonLabel>
                    <IonIcon icon={chevronForward} />
                  </IonItem>
                  <IonItem onClick={instagramLink}>
                    <IonIcon color="primary" icon={logoInstagram} />
                    <IonLabel className="btn_menu_home">Instagram</IonLabel>
                    <IonIcon icon={chevronForward} />
                  </IonItem>
                  <IonItem onClick={pekegamesLink}>
                    <IonIcon color="primary" icon={logoWebComponent} />
                    <IonLabel className="btn_menu_home"> PekeGames</IonLabel>
                    <IonIcon icon={chevronForward} />
                  </IonItem>
                </IonList>
              )}
              {!authUser?.user?.Auth && (
                <IonList>
                  <IonItem onClick={modalIdioma}>
                    <IonIcon color="primary" icon={language} />
                    <IonLabel className="btn_menu_home">
                      {" "}
                      {t("change_language")}
                    </IonLabel>
                    <IonIcon icon={chevronForward} />
                  </IonItem>
                  <IonItem onClick={modalPrivacidad}>
                    <IonIcon color="primary" icon={fileTrayFull} />
                    <IonLabel className="btn_menu_home">
                      {" "}
                      {t("privacy_policy")}
                    </IonLabel>
                    <IonIcon icon={chevronForward} />
                  </IonItem>
                  <IonItem onClick={modalSobreLaApp}>
                    <IonIcon color="primary" icon={gameController} />
                    <IonLabel className="btn_menu_home">
                      {" "}
                      {t("about_the_app")}
                    </IonLabel>
                    <IonIcon icon={chevronForward} />
                  </IonItem>
                  <IonItem onClick={facebookLink}>
                    <IonIcon color="primary" icon={logoFacebook} />
                    <IonLabel className="btn_menu_home"> Facebook</IonLabel>
                    <IonIcon icon={chevronForward} />
                  </IonItem>
                  <IonItem onClick={twitterLink}>
                    <IonIcon color="primary" icon={logoTwitter} />
                    <IonLabel className="btn_menu_home"> Twitter</IonLabel>
                    <IonIcon icon={chevronForward} />
                  </IonItem>
                  <IonItem onClick={youtubeLink}>
                    <IonIcon color="primary" icon={logoYoutube} />
                    <IonLabel className="btn_menu_home"> Youtube</IonLabel>
                    <IonIcon icon={chevronForward} />
                  </IonItem>
                  <IonItem onClick={pekegamesLink}>
                    <IonIcon color="primary" icon={logoWebComponent} />
                    <IonLabel className="btn_menu_home"> PekeGames</IonLabel>
                    <IonIcon icon={chevronForward} />
                  </IonItem>
                </IonList>
              )}
            </div>
            

            {authUser?.user?.Auth && (
              <div className={styles.caja_logout}>
                <IonButton color="danger" onClick={logout}>
                  {t("logout")}
                </IonButton>
              </div>
            )}
          </IonCol>
          <IonCol sizeMd="4"></IonCol>
        </IonRow>

        <IonModal isOpen={shownickModal} cssClass={styles.mini}>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="end">
                <IonButton onClick={() => setShownickModal(false)}>
                  {" "}
                  {t("close")}
                </IonButton>
              </IonButtons>
              <IonTitle>{t("change_nickname")}</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <img
              alt={t("logo_peke")}
              className={styles.login_logo}
              src="/assets/icon/logo_pk.png"
            ></img>
            <p className="titulo_modal"> {t("change_nickname")}</p>
            <IonItem>
              <IonLabel color="secondary" position="floating">
                {t("change_nickname")}
              </IonLabel>
              <IonInput
                value={nick}
                onIonChange={(e) => setNick(e.detail.value!)}
                clearInput
              ></IonInput>
            </IonItem>

            <div className={styles.caja_boton}>
              <IonButton
                shape="round"
                className="btn_sty"
                color="primary"
                onClick={modificarNick}
              >
                {t("save")}
              </IonButton>
            </div>
          </IonContent>
        </IonModal>

        <IonModal isOpen={showAvatarModal} cssClass={styles.mini}>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="end">
                <IonButton onClick={() => setShowAvatarModal(false)}>
                  {" "}
                  {t("close")}
                </IonButton>
              </IonButtons>
              <IonTitle>{t("change_avatar")}</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <img
              alt={t("logo_peke")}
              className={styles.login_logo}
              src="/assets/icon/logo_pk.png"
            ></img>
            <p className="titulo_modal"> {t("change_avatar")}</p>
            <IonItem>
              <div className="text_center">{final}</div>
            </IonItem>
          </IonContent>
        </IonModal>

        <IonModal isOpen={showIdiomaModal} cssClass={styles.mini}>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="end">
                <IonButton onClick={() => setShowIdiomaModal(false)}>
                  {" "}
                  {t("close")}
                </IonButton>
              </IonButtons>
              <IonTitle>{t("change_language")}</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <img
              alt={t("logo_peke")}
              className={styles.login_logo}
              src="/assets/icon/logo_pk.png"
            ></img>
            <p className="titulo_modal"> {t("change_language")}</p>
            <div className="c_menu_lateral">
              <IonList>
                <IonItem onClick={() => modificarIdioma("en")}>
                  <IonIcon color="primary" icon={language} />
                  <IonLabel className="btn_menu_home" color="primary">
                    {t("lang_en")}
                  </IonLabel>
                  <IonIcon icon={chevronForward} />
                </IonItem>
                <IonItem onClick={() => modificarIdioma("es")}>
                  <IonIcon color="primary" icon={language} />
                  <IonLabel className="btn_menu_home" color="primary">
                    {t("lang_es")}
                  </IonLabel>
                  <IonIcon icon={chevronForward} />
                </IonItem>
                <IonItem onClick={() => modificarIdioma("pt")}>
                  <IonIcon color="primary" icon={language} />
                  <IonLabel className="btn_menu_home" color="primary">
                    {t("lang_pt")}
                  </IonLabel>
                  <IonIcon icon={chevronForward} />
                </IonItem>
              </IonList>
            </div>
          </IonContent>
        </IonModal>

        <IonModal isOpen={showPrivacidadModal} cssClass="fullscreen">
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="end">
                <IonButton onClick={() => setShowPrivacidadModal(false)}>
                  {" "}
                  {t("close")}
                </IonButton>
              </IonButtons>
              <IonTitle> {t("privacy_policy")}</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <iframe
              id="mymodal"
              src="https://www.elbrinner.com/politica-privacidad-ios.html"
              title={t("privacy_policy")}
              className="iframeStyle"
            ></iframe>
          </IonContent>
        </IonModal>

        <CrearCuenta
          estadoModal={loginModal}
          eventoCerrar={cerrarModal}
          navegar={false}
        ></CrearCuenta>

        <IonModal isOpen={showCuentaModal} cssClass={styles.mini}>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="end">
                <IonButton onClick={() => setShowCuentaModal(false)}>
                  {" "}
                  {t("close")}
                </IonButton>
              </IonButtons>
              <IonTitle>{t("delete_account")}</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <img
              alt={t("logo_peke")}
              className={styles.login_logo}
              src="/assets/icon/logo_pk.png"
            ></img>
            <p className="titulo_modal"> {t("delete_account")}</p>
            <p className={styles.caja_profile}>
              {" "}
              {t("delete_account_descripcion")}
            </p>
            <div className={styles.caja_logout}>
              <IonButton color="danger" onClick={deleteCuentaPeke}>
                {t("delete_account")}
              </IonButton>
            </div>
          </IonContent>
        </IonModal>

        <IonModal isOpen={showAppModal} cssClass={styles.mini}>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="end">
                <IonButton onClick={() => setShowAppModal(false)}>
                  {" "}
                  {t("close")}
                </IonButton>
              </IonButtons>
              <IonTitle>{t("about_the_app")}</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <img
              alt={t("logo_peke")}
              className={styles.login_logo}
              src="/assets/icon/logo_pk.png"
            ></img>
            <p className="titulo_modal"> {t("about_the_app")}</p>
            <p className={styles.caja_profile}>
              {" "}
              {t("about_the_app_descripcion")}
            </p>
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default Profile;
