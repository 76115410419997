import { partidaTypes } from "../types";
import {PartidaAction , PartidaState } from '../models/partida.model'
import { DataGame } from "../models/game.model";
import { jsonService } from "../services";
import { RecuperarPartida } from "../../utils/functions";
const aciertoQuestion = (
    pointQuestion: number,
    pointCurrent: number,
    aciertoCurrent: number,
    tiempoCurrent: number,
    tiempo: number
  ): PartidaAction => {
    return {
      type: partidaTypes.FINE_QUESTION,
      point: pointCurrent + pointQuestion,
      aciertos: aciertoCurrent + 1,
      tiempoControl: tiempoCurrent + tiempo
      
    };
  };
  
  const erroresQuestion = (
    pointQuestion: number,
    pointCurrent: number,
    errorCurrent: number,
    tiempoCurrent: number,
    tiempo: number
  ): PartidaAction => {
    return {
      type: partidaTypes.BAD_QUESTION,
      point: pointCurrent >=30 ? pointCurrent - 30 : 0,
      errores: errorCurrent + 1,
      tiempoControl: tiempoCurrent + tiempo,
      aciertos: 0,
    };
  };

  const partidaRequest = (): PartidaAction => {
    return {
      type: partidaTypes.REQUEST,
    };
  };

  const resetPatida = (): PartidaAction => {
    return {
      type: partidaTypes.RESET,
    };
  };

  const newQuestion = (
    question: DataGame,
    answered: string[],
    currentQuestion: number,
    quiz: DataGame[]
  ): PartidaAction => {
    return {
      type: partidaTypes.NEXT_QUESTION,
      answered: answered,
    response: question,
    currentQuestion: currentQuestion,
    quiz: quiz,

    time: 100,
    tipo: question.T,
    aciertos: 0,
    tiempoControl: 0,
    errores: 0
    };
  };

  
  const proximaPregunta = (
    answered: string[],
    currentQuestion: number,
    cities: DataGame[],
    provinceCities: DataGame[]
  ) => {
    return async (dispatch: any): Promise<any> => {
      dispatch(partidaRequest());
      try {
        const question: DataGame = await jsonService.NextQuestionGameService(
          answered,
          provinceCities
        );
        const quiz: DataGame[] = await jsonService.QuizService(
          cities,
          provinceCities,
          question
        );
        answered.push(question.N);
        currentQuestion++;
        dispatch(newQuestion(question, answered, currentQuestion, quiz));
      } catch (e) {
        dispatch(errorRequest(e.message));
      }
    };
  };

  const errorRequest = (error: string): PartidaAction => {
    return {
      type: partidaTypes.ERROR,
      error: error,
    };
  };

  const recuperar_guardar_store = (partida: PartidaState): PartidaAction => {
    console.log("recuperar partida",partida);
    return {
      type: partidaTypes.RECUPERAR_PARTIDA,
      answered: partida.answered,
      response: partida.response,
      currentQuestion: partida.currentQuestion,
      quiz: partida.quiz,
      time: partida.time,
      aciertos: partida.aciertos,
      tiempoControl: partida.tiempoControl,
      errores: partida.errores,
      level:partida.level,
      numberOfQuestions:partida.numberOfQuestions,
      point:partida.point,
      gameStart: false,
      id:partida.id
    };
  };

  const finalizar_juego_fin_pregunta = (): PartidaAction => {
    return {
      type: partidaTypes.FINALIZAR_JUEGO_FIN_PREGUNTA,
      finalizarJuego: true
    };
  };

  const cambio_nivel = (level: number): PartidaAction => {
    return {
      type: partidaTypes.CAMBIO_NIVEL,
      level: level+1,
    };
  };

  const actualizar_posicion = (posicionActual: number): PartidaAction => {
    return {
      type: partidaTypes.POSICION,
      posicion: posicionActual,
    };
  };

  const recuperarPartida = () => {
    return async (dispatch: any) => {
      dispatch(partidaRequest());
      try {
        const mypartida : PartidaState =  RecuperarPartida();
        if(mypartida.currentQuestion!==null){
          mypartida.gameStart=false;
        }
        dispatch(recuperar_guardar_store(mypartida));
      } catch (error) {
        dispatch(errorRequest(null));
      }
    };
  };

  export const partidaActions = {
    aciertoQuestion,
    erroresQuestion,
    proximaPregunta,
    finalizar_juego_fin_pregunta,
    cambio_nivel,
    recuperarPartida,
    resetPatida,
    actualizar_posicion
  };