export enum authTypes {
    REQUEST = 'FETCH_AUTH_REQUEST',
    AUTH_SUCCESS = 'FETCH_AUTH_SUCCESS',
    AUTH_INVITATION = 'FETCH_AUTH_INVITATION',
    CURRENT_SESSION_SUCCESS = 'FETCH_CURRENT_SESSION_SUCCESS',
    FAILURE = 'FETCH_AUTH_FAILURE',
    RESET = 'RESET_AUTH_DATA',
    LOGOUT = 'FETCH_AUTH_LOGOUT',
    REFRESH_TOKEN = 'REFRESH_TOKEN',
    QUITAR_LOADER = 'QUITAR_LOADER',
    ERROR = 'ERROR_SISTEMA'
  }