import { authTypes } from "../types";
import { gameService } from "../services";
import { AuthState, AuthAction, AuthStore, MyUser } from "../models/auth.model";
import { Guid } from "guid-typescript";
import { history } from "../../_helpers/history";
import { filtrarMeuDatos } from "../../utils/functions";

const authRequest = (): AuthAction => {
  return {
    type: authTypes.REQUEST,
  };
};

const authSucess = (user: MyUser): AuthAction => {
  return {
    type: authTypes.AUTH_SUCCESS,
    user: user,
  };
};

const errorRequest = (error: string): AuthAction => {
  return {
    type: authTypes.FAILURE,
    error: error,
  };
};

const errorSistema = (error: string): AuthAction => {
  return {
    type: authTypes.ERROR,
    error: error,
  };
};

const authInvitation = (user: MyUser): AuthAction => {

  console.log("authInvitation",user);
  return {
    type: authTypes.AUTH_INVITATION,
    user: user,
  };
};

const loginInvitation = () => {
  return async (dispatch: any): Promise<any> => {
    dispatch(authRequest());
    try {
      const user: MyUser = {
        Id: "-1",
        Avatar: "a1.jpg",
        Nick: "Invitado",
        NuevoUsuarioEnElJuego: false,
        PaisId: -1,
        Token: "",
        DatosExtendido: {
          Id: -1,
          UsuarioId: "-1",
          MiAppId: -1,
          Punto: 0,
          RachaEnDia: 0,
          Ayuda50: 0,
          AyudaPreguntaCorrecta: 0,
          AyudaContestar2Veces: 0,
          AyudaMasTiempo: 0,
          RecuperarPuntosPerdido: 0,
          RecuperarVida: 0,
          RecuperarRacha: 0,
          Vida: 0,
          NumeroPartida: 0,
          MejorPosicion: 0,
          MejorPuntuacion: 0,
          MejorNivel: 0,
          PerfilPrivado: false,
          Aciertos: 0,
          Errores: 0,
          TipoPlataforma: 3,
          FormatoApp: 3,
          Creado: null,
          FechaUltimoAcceso: null,
          MonedaGlobal: 0,
          PuntosGlobales: 0,
        },
        Username: "Invitado",
        Password: "",
        Auth: false,
      };

      dispatch(authInvitation(user));
      localStorage.setItem("ctpk", filtrarMeuDatos(JSON.stringify(user)));
      history.push("/dashboard");
    } catch (e) {
      dispatch("loginInvitation:"+errorRequest(e.message));
    }
  };
};

const loginInvitationArrancarApp = () => {
  return async (dispatch: any): Promise<any> => {
    dispatch(authRequest());
    try {
      const user: MyUser = {
        Id: "-1",
        Avatar: "a1.jpg",
        Nick: "Invitado",
        NuevoUsuarioEnElJuego: false,
        PaisId: -1,
        Token: "",
        DatosExtendido: {
          Id: -1,
          UsuarioId: "-1",
          MiAppId: -1,
          Punto: 0,
          RachaEnDia: 0,
          Ayuda50: 0,
          AyudaPreguntaCorrecta: 0,
          AyudaContestar2Veces: 0,
          AyudaMasTiempo: 0,
          RecuperarPuntosPerdido: 0,
          RecuperarVida: 0,
          RecuperarRacha: 0,
          Vida: 0,
          NumeroPartida: 0,
          MejorPosicion: 0,
          MejorPuntuacion: 0,
          MejorNivel: 0,
          PerfilPrivado: false,
          Aciertos: 0,
          Errores: 0,
          TipoPlataforma: 3,
          FormatoApp: 3,
          Creado: null,
          FechaUltimoAcceso: null,
          MonedaGlobal: 0,
          PuntosGlobales: 0,
        },
        Username: "Invitado",
        Password: "",
        Auth: false,
      };
     console.log("usurio es",user);
      dispatch(authInvitation(user));
      localStorage.setItem("ctpk", filtrarMeuDatos(JSON.stringify(user)));
    } catch (e) {
      dispatch(errorRequest("loginInvitationArrancarApp:"+e.message));
    }
  };
};

const loginPeke = (login: MyUser) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(authRequest());
    try {
      const user = await gameService.Login(login);
      if(user===null || user?.Id ===null){
        throw new TypeError("No es posible se conectar al servidor");
      }
      console.log("respuesta de login", user);
      dispatch(authSucess(user));
      history.push("/dashboard");
    } catch (error) {
      dispatch(errorRequest("loginPeke"+error.message));
    }
  };
};


const loginPekeSinNavegacion = (login: MyUser) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(authRequest());
    try {
      const user = await gameService.Login(login);
      if(user==null){
        throw new TypeError("No es posible se conectar al servidor");
      }
      dispatch(authSucess(user));
    } catch (error) {
      dispatch(errorRequest("loginPeke"+error.message));
    }
  };
};

const cargarDatosSinNavegacion = (user: MyUser) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(authRequest());
    try {
      dispatch(authSucess(user));
    } catch (error) {
      dispatch(errorRequest("cargarDatosSinNavegacion:"+error.message));
    }
  };
};


const crearCuentaPeke = (login: MyUser) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(authRequest());
    try {
      const user: MyUser = await gameService.CrearCuenta(login);

      dispatch(authSucess(user));
      history.push("/dashboard");
    } catch (e) {
      dispatch(errorRequest(e.message));
    }
  };
};

const crearCuentaPekeSinNavegar = (login: MyUser) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(authRequest());
    try {
      const user: MyUser = await gameService.CrearCuenta(login);

      dispatch(authSucess(user));
    } catch (e) {
      dispatch(errorRequest(e.message));
    }
  };
};

const checkAuth = () => {
  return async (dispatch: any) => {
    dispatch(authRequest());
    try {
      const myuser: MyUser = await gameService.checkAuth();
      dispatch(authSucess(myuser));
      //history.replace("/dashboard");
    } catch (error) {
      dispatch(errorRequest(null));
    }
  };
};

const logout = (): AuthAction => {
  localStorage.removeItem("ctpk");
  history.replace("/");
  return {
    type: authTypes.LOGOUT,
  };
};

export const authActions = {
  loginInvitation,
  loginPeke,
  crearCuentaPeke,
  checkAuth,
  logout,
  loginInvitationArrancarApp,
  authInvitation,
  cargarDatosSinNavegacion,
  loginPekeSinNavegacion,
  errorSistema,
  authSucess,
  crearCuentaPekeSinNavegar
};
