import {
  IonButton,
  IonContent,
  IonFabButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
} from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";

import { history } from "../../_helpers/history";
import { partidaActions } from "../../_redux/actions";
import { useEffect, useState } from "react";
import { GameState } from "../../_redux/models/game.model";
import { useTranslation } from "react-i18next";
import {
  person,
  notifications,
  chevronForward,
  gameController,
  earth,
  apps,
  locate,
  image,
  play,
  globe,
  storefrontSharp,
  closeOutline,
  contract,
  contrastOutline,
  settings,
} from "ionicons/icons";
import { PartidaState } from "../../_redux/models/partida.model";
import { SocialRed } from "../Social/SocialRed";
import { appActions } from "../../_redux/actions/app.actions";
import AdsGoogleAdsense from "../Ads/AdsGoogleAdsense";
import Ads from "../Ads/GoogleAd";

export const MenuLateral: React.FC<any> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const partida: PartidaState = useSelector((state: any) => state.partida);
  const game: GameState = useSelector((state: any) => state.game);
  const [showModal, setShowModal] = useState(false);
  const [urlSocial, setUrlSocial] = useState<string>(
    "https://twitter.com/PekeGames"
  );
  const handlePlay = () => {
    dispatch(
      partidaActions.proximaPregunta(
        partida.answered,
        partida.currentQuestion,
        game.cities,
        game.provinceCities
      )
    );
    partida.gameStart = true;
    console.log(partida);
    history.push("/quiz");
  };

  const modoOscuro = () => {
    try {
      if (localStorage.getItem("appTheme") === "") {
        dispatch(appActions.setAppTheme("dark"));
      } else {
        dispatch(appActions.setAppTheme(""));
      }
    } catch (error) {
      dispatch(appActions.setAppTheme(""));
    }
  };
  const handleRanking = () => {
    history.push("/ranking");
  };

  const handleProfile = () => {
    history.push("/profile");
  };

  const handleCity = () => {
    history.push("/cities");
  };

  const handlePhoto = () => {
    history.push("/photo");
  };

  const handleApps = () => {
    history.push("/apps");
  };

  useEffect(() => {
    dispatch(partidaActions.recuperarPartida());
  }, []);

  return (
    <div className="c_menu_lateral">
      <div className="social_barra">
        <IonFabButton size="small" color="light">
          <IonIcon icon={notifications} />
        </IonFabButton>
        <IonFabButton size="small" color="light">
          <IonIcon icon={person} />
        </IonFabButton>
      </div>

      <IonFabButton
        onClick={handleProfile}
        className="icon_settings hide_pc_global margin_icon"
        color="light"
      >
        <IonIcon color="medium" size="large" icon={settings} />
      </IonFabButton>
      <IonFabButton
        onClick={modoOscuro}
        className="icon_settings hide_pc_global margin_icon"
        color="dark"
      >
        <IonIcon size="large" icon={contrastOutline} />
      </IonFabButton>
      <IonFabButton
        routerLink="store"
        className="icon_settings hide_pc_global margin_icon"
        color="warning"
      >
        <IonIcon color="danger" size="large" icon={storefrontSharp} />
      </IonFabButton>

      

      <div className="login_buttons_group">
        <IonButton
          shape="round"
          size="large"
          className="btn_sty"
          onClick={handlePlay}
        >
          <IonIcon slot="start" icon={play} />
          {partida.gameStart && partida.currentQuestion > 1
            ? t("continue_game")
            : t("play")}
        </IonButton>
        <IonButton
          shape="round"
          size="large"
          color="tertiary"
          className="btn_sty"
          onClick={handleRanking}
        >
          <IonIcon slot="start" icon={globe} />
          {t("ranking")}
        </IonButton>{" "}
      </div>

      <div className="menu_lateral_pc">
        <IonList>
          <IonItem onClick={handlePlay}>
            <IonIcon color="primary" icon={gameController} />
            <IonLabel className="btn_menu_home" color="primary">
              {" "}
              {partida.gameStart ? t("continue_game") : t("play")}
            </IonLabel>
            <IonIcon icon={chevronForward} />
          </IonItem>

          <IonItem onClick={handleRanking}>
            <IonIcon color="primary" icon={earth} />
            <IonLabel className="btn_menu_home"> {t("ranking")}</IonLabel>
            <IonIcon icon={chevronForward} />
          </IonItem>

          <IonItem onClick={handleCity}>
            <IonIcon color="primary" icon={locate} />
            <IonLabel className="btn_menu_home"> {t("cities")}</IonLabel>
            <IonIcon icon={chevronForward} />
          </IonItem>

          <IonItem onClick={handlePhoto}>
            <IonIcon color="primary" icon={image} />
            <IonLabel className="btn_menu_home">
              {" "}
              {t("tourist_points")}
            </IonLabel>
            <IonIcon icon={chevronForward} />
          </IonItem>

          <IonItem onClick={handleApps}>
            <IonIcon color="primary" icon={apps} />
            <IonLabel className="btn_menu_home"> {t("other_apps")}</IonLabel>
            <IonIcon icon={chevronForward} />
          </IonItem>

          {/*
          <IonItem onClick={handleProfile}>
            <IonIcon icon={person} />
            <IonLabel> {t("profile")}</IonLabel>
            <IonIcon icon={chevronForward} />
          </IonItem>

          <IonItem>
            <IonIcon icon={notifications} />
            <IonLabel> {t("notifications")}</IonLabel>
            <IonIcon icon={chevronForward} />
          </IonItem>

         

          <IonItem>
            <IonIcon icon={share} />
            <IonLabel> {t("share")}</IonLabel>
            <IonIcon icon={chevronForward} />
          </IonItem>
*/}
        </IonList>

        <SocialRed></SocialRed>
        <Ads></Ads>
      </div>
      <IonModal isOpen={showModal} cssClass="fullscreen">
        <IonContent>
          <iframe
            id="mymodal"
            src={partida.response?.W}
            className="iframeStyle"
          ></iframe>
        </IonContent>
        <IonIcon
          className="modal_close"
          onClick={() => setShowModal(false)}
          icon={closeOutline}
        />
      </IonModal>
    </div>
  );
};

export default MenuLateral;
