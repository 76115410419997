export enum partidaTypes {
    FINE_QUESTION = "FINE_QUESTION",
    BAD_QUESTION = "BAD_QUESTION",
    REQUEST_QUESTION = "REQUEST_QUESTION",
    LOAD_NEXT_QUESTION = "LOAD_NEXT_QUESTION",
    RESET = "RESET",
    EMPEZAR_PARTIDA = "EMPEZAR_PARTIDA",
    CONTINUAR_PARTIDA = "CONTINUAR_PARTIDA",
    REQUEST="REQUEST",
    NEXT_QUESTION="NEXT_QUESTION",
    ERROR = "ERROR",
    FINALIZAR_JUEGO_FIN_PREGUNTA="FINALIZAR_JUEGO_FIN_PREGUNTA",
    GUARDAR_JUEGO="GUARDAR_JUEGO",
    CAMBIO_NIVEL="CAMBIO_NIVEL",
    RECUPERAR_PARTIDA ="RECUPERAR_PARTIDA",
    POSICION = "POSICION_RANKING"
  }