export const configData = {
  idioma: "es",
  provincia_o_estado: "Coruña",
  ciudad: "municipio",
  eltiempo: [
    {
      "@value": "19611",
      "#text": "A Ameixenda",
    },
    {
      "@value": "24491",
      "#text": "A Baiuca",
    },
    {
      "@value": "5919",
      "#text": "A Baña",
    },
    {
      "@value": "19591",
      "#text": "A Barqueira",
    },
    {
      "@value": "24312",
      "#text": "A Cabana",
    },
    {
      "@value": "5908",
      "#text": "A Capela",
    },
    {
      "@value": "5926",
      "#text": "A Coruña",
    },
    {
      "@value": "19439",
      "#text": "A Graña",
    },
    {
      "@value": "5885",
      "#text": "A Laracha",
    },
    {
      "@value": "25125",
      "#text": "A Malata",
    },
    {
      "@value": "19346",
      "#text": "A Mariña",
    },
    {
      "@value": "5859",
      "#text": "A Pobra do Caramiñal",
    },
    {
      "@value": "14677",
      "#text": "A seara",
    },
    {
      "@value": "24294",
      "#text": "Abad",
    },
    {
      "@value": "24274",
      "#text": "Abeancos",
    },
    {
      "@value": "5925",
      "#text": "Abegondo",
    },
    {
      "@value": "24270",
      "#text": "Abeijón",
    },
    {
      "@value": "24260",
      "#text": "Abellá",
    },
    {
      "@value": "24259",
      "#text": "Abellas",
    },
    {
      "@value": "24258",
      "#text": "Abelleira",
    },
    {
      "@value": "24203",
      "#text": "Adragonte",
    },
    {
      "@value": "24197",
      "#text": "Agar",
    },
    {
      "@value": "24193",
      "#text": "Agra",
    },
    {
      "@value": "24192",
      "#text": "Agranzón",
    },
    {
      "@value": "24191",
      "#text": "Agrón",
    },
    {
      "@value": "24188",
      "#text": "Agualada",
    },
    {
      "@value": "23429",
      "#text": "Aiazo",
    },
    {
      "@value": "24109",
      "#text": "Albarellos",
    },
    {
      "@value": "24108",
      "#text": "Albariza",
    },
    {
      "@value": "24099",
      "#text": "Albijoy",
    },
    {
      "@value": "24092",
      "#text": "Alboreda",
    },
    {
      "@value": "24091",
      "#text": "Alborés",
    },
    {
      "@value": "24033",
      "#text": "Aldeavella",
    },
    {
      "@value": "24018",
      "#text": "Aldemunde",
    },
    {
      "@value": "23972",
      "#text": "Almeiras",
    },
    {
      "@value": "23964",
      "#text": "Alón",
    },
    {
      "@value": "23936",
      "#text": "Amarela",
    },
    {
      "@value": "23918",
      "#text": "Ambroa",
    },
    {
      "@value": "23914",
      "#text": "Ameijenda",
    },
    {
      "@value": "5924",
      "#text": "Ames",
    },
    {
      "@value": "23899",
      "#text": "Añá",
    },
    {
      "@value": "23894",
      "#text": "Anca",
    },
    {
      "@value": "23893",
      "#text": "Anceis",
    },
    {
      "@value": "23886",
      "#text": "Andabao",
    },
    {
      "@value": "23881",
      "#text": "Andeade",
    },
    {
      "@value": "23873",
      "#text": "Andoyo",
    },
    {
      "@value": "23870",
      "#text": "Andrade",
    },
    {
      "@value": "23859",
      "#text": "Ángeles",
    },
    {
      "@value": "23856",
      "#text": "Angeriz",
    },
    {
      "@value": "23982",
      "#text": "Anllóns",
    },
    {
      "@value": "23827",
      "#text": "Anseán",
    },
    {
      "@value": "23819",
      "#text": "Antes",
    },
    {
      "@value": "23858",
      "#text": "Ánxeles",
    },
    {
      "@value": "23801",
      "#text": "Aparral",
    },
    {
      "@value": "23790",
      "#text": "Arabejo",
    },
    {
      "@value": "23777",
      "#text": "Arán",
    },
    {
      "@value": "5923",
      "#text": "Aranga",
    },
    {
      "@value": "23769",
      "#text": "Araño",
    },
    {
      "@value": "23763",
      "#text": "Arantón",
    },
    {
      "@value": "23736",
      "#text": "Arca",
    },
    {
      "@value": "23728",
      "#text": "Arceo",
    },
    {
      "@value": "23714",
      "#text": "Arcos",
    },
    {
      "@value": "23708",
      "#text": "Ardaña",
    },
    {
      "@value": "23706",
      "#text": "Ardemil",
    },
    {
      "@value": "23683",
      "#text": "Areosa",
    },
    {
      "@value": "5922",
      "#text": "Ares",
    },
    {
      "@value": "23638",
      "#text": "Arines",
    },
    {
      "@value": "23622",
      "#text": "Armán",
    },
    {
      "@value": "23617",
      "#text": "Armear",
    },
    {
      "@value": "23613",
      "#text": "Armental",
    },
    {
      "@value": "23607",
      "#text": "Armentón",
    },
    {
      "@value": "23606",
      "#text": "Armentón de Arriba",
    },
    {
      "@value": "23590",
      "#text": "Arnois",
    },
    {
      "@value": "23583",
      "#text": "Arou",
    },
    {
      "@value": "5921",
      "#text": "Arteixo",
    },
    {
      "@value": "23518",
      "#text": "Artes",
    },
    {
      "@value": "23508",
      "#text": "Artón",
    },
    {
      "@value": "5920",
      "#text": "Arzúa",
    },
    {
      "@value": "5856",
      "#text": "As Pontes de García Rodríguez",
    },
    {
      "@value": "5845",
      "#text": "As Somozas",
    },
    {
      "@value": "23498",
      "#text": "Asados",
    },
    {
      "@value": "23490",
      "#text": "Asenso",
    },
    {
      "@value": "24184",
      "#text": "Augasantas",
    },
    {
      "@value": "23395",
      "#text": "Babío",
    },
    {
      "@value": "23386",
      "#text": "Badernado",
    },
    {
      "@value": "23373",
      "#text": "Baíñas",
    },
    {
      "@value": "23136",
      "#text": "Baio",
    },
    {
      "@value": "23135",
      "#text": "Baio Grande",
    },
    {
      "@value": "24795",
      "#text": "Baleixo",
    },
    {
      "@value": "23350",
      "#text": "Balsoma",
    },
    {
      "@value": "23348",
      "#text": "Balteiro",
    },
    {
      "@value": "23346",
      "#text": "Bama",
    },
    {
      "@value": "23343",
      "#text": "Bamiro",
    },
    {
      "@value": "23336",
      "#text": "Bando",
    },
    {
      "@value": "23335",
      "#text": "Bandoja",
    },
    {
      "@value": "23329",
      "#text": "Baño",
    },
    {
      "@value": "23321",
      "#text": "Banzas",
    },
    {
      "@value": "23319",
      "#text": "Baos",
    },
    {
      "@value": "23306",
      "#text": "Barallobre",
    },
    {
      "@value": "23294",
      "#text": "Barbeiros",
    },
    {
      "@value": "23293",
      "#text": "Barbeito",
    },
    {
      "@value": "23291",
      "#text": "Barbos",
    },
    {
      "@value": "23287",
      "#text": "Barcala",
    },
    {
      "@value": "23259",
      "#text": "Barciela",
    },
    {
      "@value": "23255",
      "#text": "Bardaos",
    },
    {
      "@value": "23253",
      "#text": "Bardullas",
    },
    {
      "@value": "23250",
      "#text": "Bares",
    },
    {
      "@value": "23246",
      "#text": "Barizo",
    },
    {
      "@value": "23234",
      "#text": "Baroña",
    },
    {
      "@value": "23227",
      "#text": "Barrañán",
    },
    {
      "@value": "23219",
      "#text": "Barreiro",
    },
    {
      "@value": "23183",
      "#text": "Barro",
    },
    {
      "@value": "23167",
      "#text": "Basadre",
    },
    {
      "@value": "23155",
      "#text": "Bascoi",
    },
    {
      "@value": "23149",
      "#text": "Basoñas",
    },
    {
      "@value": "23146",
      "#text": "Bastavales",
    },
    {
      "@value": "24797",
      "#text": "Bastiagueiro",
    },
    {
      "@value": "23130",
      "#text": "Bazar",
    },
    {
      "@value": "23129",
      "#text": "Beán",
    },
    {
      "@value": "23126",
      "#text": "Beba",
    },
    {
      "@value": "23084",
      "#text": "Belmil",
    },
    {
      "@value": "23073",
      "#text": "Bemantes",
    },
    {
      "@value": "23070",
      "#text": "Bembibre",
    },
    {
      "@value": "23048",
      "#text": "Benza",
    },
    {
      "@value": "23028",
      "#text": "Berdeogas",
    },
    {
      "@value": "23027",
      "#text": "Berdía",
    },
    {
      "@value": "23026",
      "#text": "Berdillo",
    },
    {
      "@value": "23022",
      "#text": "Beres",
    },
    {
      "@value": "5918",
      "#text": "Bergondo",
    },
    {
      "@value": "23009",
      "#text": "Bermuy",
    },
    {
      "@value": "22993",
      "#text": "Berredo",
    },
    {
      "@value": "22991",
      "#text": "Berreo",
    },
    {
      "@value": "22990",
      "#text": "Berres",
    },
    {
      "@value": "22977",
      "#text": "Bertoa",
    },
    {
      "@value": "5917",
      "#text": "Betanzos",
    },
    {
      "@value": "22915",
      "#text": "Boa",
    },
    {
      "@value": "22910",
      "#text": "Boado",
    },
    {
      "@value": "22907",
      "#text": "Boaño",
    },
    {
      "@value": "22886",
      "#text": "Boimil",
    },
    {
      "@value": "5916",
      "#text": "Boimorto",
    },
    {
      "@value": "5915",
      "#text": "Boiro",
    },
    {
      "@value": "22854",
      "#text": "Boqueijón",
    },
    {
      "@value": "5914",
      "#text": "Boqueixón",
    },
    {
      "@value": "22840",
      "#text": "Bornalle",
    },
    {
      "@value": "22833",
      "#text": "Borrifáns",
    },
    {
      "@value": "22829",
      "#text": "Boudañeira",
    },
    {
      "@value": "22828",
      "#text": "Boullón",
    },
    {
      "@value": "22825",
      "#text": "Bouza",
    },
    {
      "@value": "22813",
      "#text": "Brabío",
    },
    {
      "@value": "22812",
      "#text": "Bragad",
    },
    {
      "@value": "22806",
      "#text": "Brañas",
    },
    {
      "@value": "22802",
      "#text": "Brañas Verdes",
    },
    {
      "@value": "22798",
      "#text": "Brandeso",
    },
    {
      "@value": "22796",
      "#text": "Brandomil",
    },
    {
      "@value": "22793",
      "#text": "Brantoas",
    },
    {
      "@value": "22791",
      "#text": "Branzá",
    },
    {
      "@value": "22790",
      "#text": "Brates",
    },
    {
      "@value": "22788",
      "#text": "Brea",
    },
    {
      "@value": "22787",
      "#text": "Breamo",
    },
    {
      "@value": "22783",
      "#text": "Brens",
    },
    {
      "@value": "22780",
      "#text": "Bretal",
    },
    {
      "@value": "22774",
      "#text": "Bribes",
    },
    {
      "@value": "22768",
      "#text": "Briño",
    },
    {
      "@value": "5913",
      "#text": "Brión",
    },
    {
      "@value": "22764",
      "#text": "Broño",
    },
    {
      "@value": "22758",
      "#text": "Bruma",
    },
    {
      "@value": "22756",
      "#text": "Buazo",
    },
    {
      "@value": "22747",
      "#text": "Budiño",
    },
    {
      "@value": "22735",
      "#text": "Bugallido",
    },
    {
      "@value": "22731",
      "#text": "Buiste",
    },
    {
      "@value": "22730",
      "#text": "Buiturón",
    },
    {
      "@value": "22726",
      "#text": "Buján",
    },
    {
      "@value": "22716",
      "#text": "Buño",
    },
    {
      "@value": "22703",
      "#text": "Burres",
    },
    {
      "@value": "22699",
      "#text": "Buscás",
    },
    {
      "@value": "22659",
      "#text": "Busto",
    },
    {
      "@value": "22648",
      "#text": "Caaveiro",
    },
    {
      "@value": "22646",
      "#text": "Cabalar",
    },
    {
      "@value": "22645",
      "#text": "Cabaleiros",
    },
    {
      "@value": "22640",
      "#text": "Cabana",
    },
    {
      "@value": "5912",
      "#text": "Cabana de Bergantiños",
    },
    {
      "@value": "22635",
      "#text": "Cabanamoura",
    },
    {
      "@value": "5911",
      "#text": "Cabanas",
    },
    {
      "@value": "22619",
      "#text": "Cabanude",
    },
    {
      "@value": "22598",
      "#text": "Cabovilaño",
    },
    {
      "@value": "22595",
      "#text": "Cabrais",
    },
    {
      "@value": "22582",
      "#text": "Cabrui",
    },
    {
      "@value": "22575",
      "#text": "Cacheiras",
    },
    {
      "@value": "21985",
      "#text": "Caión",
    },
    {
      "@value": "22535",
      "#text": "Caldebarcos",
    },
    {
      "@value": "22519",
      "#text": "Calo",
    },
    {
      "@value": "22516",
      "#text": "Calvente",
    },
    {
      "@value": "22512",
      "#text": "Calvos de Sobre Camiño",
    },
    {
      "@value": "5910",
      "#text": "Camariñas",
    },
    {
      "@value": "22491",
      "#text": "Cambás",
    },
    {
      "@value": "5909",
      "#text": "Cambre",
    },
    {
      "@value": "22487",
      "#text": "Camelle",
    },
    {
      "@value": "22468",
      "#text": "Campara",
    },
    {
      "@value": "22462",
      "#text": "Campelo",
    },
    {
      "@value": "22443",
      "#text": "Campo",
    },
    {
      "@value": "22413",
      "#text": "Campo-Xesteda",
    },
    {
      "@value": "22429",
      "#text": "Campolongo",
    },
    {
      "@value": "22421",
      "#text": "Campos",
    },
    {
      "@value": "22399",
      "#text": "Cañás",
    },
    {
      "@value": "22390",
      "#text": "Cances Grande",
    },
    {
      "@value": "22376",
      "#text": "Candoás",
    },
    {
      "@value": "22377",
      "#text": "Candosa",
    },
    {
      "@value": "22354",
      "#text": "Canosa",
    },
    {
      "@value": "22340",
      "#text": "Capela",
    },
    {
      "@value": "22339",
      "#text": "Capelada",
    },
    {
      "@value": "22326",
      "#text": "Carantoña",
    },
    {
      "@value": "22325",
      "#text": "Caranza",
    },
    {
      "@value": "22309",
      "#text": "Carballa",
    },
    {
      "@value": "22306",
      "#text": "Carballal",
    },
    {
      "@value": "22303",
      "#text": "Carballido",
    },
    {
      "@value": "5907",
      "#text": "Carballo",
    },
    {
      "@value": "22297",
      "#text": "Carboal",
    },
    {
      "@value": "22289",
      "#text": "Carcacia",
    },
    {
      "@value": "22284",
      "#text": "Cardama",
    },
    {
      "@value": "22281",
      "#text": "Cardeiro",
    },
    {
      "@value": "22273",
      "#text": "Carelle",
    },
    {
      "@value": "5906",
      "#text": "Cariño",
    },
    {
      "@value": "22268",
      "#text": "Carleo de Arriba",
    },
    {
      "@value": "22262",
      "#text": "Carnoedo",
    },
    {
      "@value": "5905",
      "#text": "Carnota",
    },
    {
      "@value": "5904",
      "#text": "Carral",
    },
    {
      "@value": "22232",
      "#text": "Carreira",
    },
    {
      "@value": "22228",
      "#text": "Carres",
    },
    {
      "@value": "22202",
      "#text": "Casais",
    },
    {
      "@value": "22194",
      "#text": "Casares",
    },
    {
      "@value": "22147",
      "#text": "Castañeda",
    },
    {
      "@value": "22134",
      "#text": "Castelán",
    },
    {
      "@value": "22129",
      "#text": "Castellana",
    },
    {
      "@value": "22113",
      "#text": "Castelo",
    },
    {
      "@value": "22110",
      "#text": "Castenda",
    },
    {
      "@value": "22092",
      "#text": "Castillo",
    },
    {
      "@value": "22079",
      "#text": "Castiñeiras",
    },
    {
      "@value": "22077",
      "#text": "Castrallón",
    },
    {
      "@value": "22075",
      "#text": "Castrarón",
    },
    {
      "@value": "22045",
      "#text": "Castriz",
    },
    {
      "@value": "22041",
      "#text": "Castro",
    },
    {
      "@value": "22023",
      "#text": "Castrofeito",
    },
    {
      "@value": "21996",
      "#text": "Catoira",
    },
    {
      "@value": "21974",
      "#text": "Cebráns",
    },
    {
      "@value": "21972",
      "#text": "Cebreiro",
    },
    {
      "@value": "21971",
      "#text": "Cecebre",
    },
    {
      "@value": "5903",
      "#text": "Cedeira",
    },
    {
      "@value": "5901",
      "#text": "Cee",
    },
    {
      "@value": "21960",
      "#text": "Ceilán",
    },
    {
      "@value": "21953",
      "#text": "Cela",
    },
    {
      "@value": "21942",
      "#text": "Celas",
    },
    {
      "@value": "21934",
      "#text": "Céltigos",
    },
    {
      "@value": "21918",
      "#text": "Centroña",
    },
    {
      "@value": "21913",
      "#text": "Cerbo",
    },
    {
      "@value": "5902",
      "#text": "Cerceda",
    },
    {
      "@value": "5900",
      "#text": "Cerdido",
    },
    {
      "@value": "21893",
      "#text": "Cereo",
    },
    {
      "@value": "21892",
      "#text": "Cereo Vello",
    },
    {
      "@value": "21870",
      "#text": "Cerqueda",
    },
    {
      "@value": "21864",
      "#text": "Cervás",
    },
    {
      "@value": "21858",
      "#text": "César",
    },
    {
      "@value": "21854",
      "#text": "Cesullas",
    },
    {
      "@value": "5899",
      "#text": "Cesuras",
    },
    {
      "@value": "21847",
      "#text": "Chacín",
    },
    {
      "@value": "21842",
      "#text": "Chamín de Abaixo",
    },
    {
      "@value": "21841",
      "#text": "Chamín do Medio",
    },
    {
      "@value": "21813",
      "#text": "Chayán",
    },
    {
      "@value": "21812",
      "#text": "Cheis",
    },
    {
      "@value": "21802",
      "#text": "Chorente",
    },
    {
      "@value": "21801",
      "#text": "Choupana",
    },
    {
      "@value": "21800",
      "#text": "Choura",
    },
    {
      "@value": "21793",
      "#text": "Cibrán",
    },
    {
      "@value": "21745",
      "#text": "Cidadela",
    },
    {
      "@value": "21743",
      "#text": "Cives de Arriba",
    },
    {
      "@value": "121520",
      "#text": "Club de Golf Val de Rois",
    },
    {
      "@value": "21709",
      "#text": "Codeso",
    },
    {
      "@value": "21708",
      "#text": "Codesoso",
    },
    {
      "@value": "21693",
      "#text": "Coiro",
    },
    {
      "@value": "5898",
      "#text": "Coirós",
    },
    {
      "@value": "21674",
      "#text": "Colantres",
    },
    {
      "@value": "21660",
      "#text": "Colúns",
    },
    {
      "@value": "21659",
      "#text": "Comareiro",
    },
    {
      "@value": "21631",
      "#text": "Conxo",
    },
    {
      "@value": "21610",
      "#text": "Corcoesto",
    },
    {
      "@value": "5897",
      "#text": "Corcubión",
    },
    {
      "@value": "21600",
      "#text": "Cores",
    },
    {
      "@value": "5896",
      "#text": "Coristanco",
    },
    {
      "@value": "21597",
      "#text": "Corme-Aldea",
    },
    {
      "@value": "21596",
      "#text": "Corme-Porto",
    },
    {
      "@value": "21593",
      "#text": "Cornanda",
    },
    {
      "@value": "21590",
      "#text": "Corneda",
    },
    {
      "@value": "21587",
      "#text": "Cornes",
    },
    {
      "@value": "21585",
      "#text": "Cornido",
    },
    {
      "@value": "21539",
      "#text": "Cortiñán",
    },
    {
      "@value": "21532",
      "#text": "Coruxou",
    },
    {
      "@value": "21524",
      "#text": "Corzón",
    },
    {
      "@value": "21522",
      "#text": "Cos",
    },
    {
      "@value": "21514",
      "#text": "Cospindo",
    },
    {
      "@value": "21513",
      "#text": "Costa",
    },
    {
      "@value": "24664",
      "#text": "Cotaredo",
    },
    {
      "@value": "21493",
      "#text": "Coucieiro",
    },
    {
      "@value": "21486",
      "#text": "Couso",
    },
    {
      "@value": "21480",
      "#text": "Couzadoiro",
    },
    {
      "@value": "21470",
      "#text": "Covas",
    },
    {
      "@value": "21463",
      "#text": "Crendes",
    },
    {
      "@value": "21462",
      "#text": "Creo",
    },
    {
      "@value": "21455",
      "#text": "Cruces",
    },
    {
      "@value": "21378",
      "#text": "Cuiña",
    },
    {
      "@value": "5895",
      "#text": "Culleredo",
    },
    {
      "@value": "21374",
      "#text": "Cumbraos",
    },
    {
      "@value": "21368",
      "#text": "Cuño",
    },
    {
      "@value": "21366",
      "#text": "Cuns",
    },
    {
      "@value": "21362",
      "#text": "Cures",
    },
    {
      "@value": "21360",
      "#text": "Curra",
    },
    {
      "@value": "21358",
      "#text": "Currais",
    },
    {
      "@value": "21357",
      "#text": "Currás",
    },
    {
      "@value": "5894",
      "#text": "Curtis",
    },
    {
      "@value": "21350",
      "#text": "Cutián",
    },
    {
      "@value": "21349",
      "#text": "Cuvide",
    },
    {
      "@value": "21339",
      "#text": "Daneiro",
    },
    {
      "@value": "21335",
      "#text": "Deán Pequeño",
    },
    {
      "@value": "21326",
      "#text": "Deijebre",
    },
    {
      "@value": "21319",
      "#text": "Denle",
    },
    {
      "@value": "21313",
      "#text": "Devesa",
    },
    {
      "@value": "21311",
      "#text": "Deveso",
    },
    {
      "@value": "21310",
      "#text": "Devesos",
    },
    {
      "@value": "21324",
      "#text": "Dexo",
    },
    {
      "@value": "5892",
      "#text": "Dodro",
    },
    {
      "@value": "21293",
      "#text": "Dombodán",
    },
    {
      "@value": "21281",
      "#text": "Donas",
    },
    {
      "@value": "21277",
      "#text": "Doniños",
    },
    {
      "@value": "21275",
      "#text": "Dor",
    },
    {
      "@value": "21273",
      "#text": "Dordaño",
    },
    {
      "@value": "21270",
      "#text": "Dormeá",
    },
    {
      "@value": "21269",
      "#text": "Dorneda",
    },
    {
      "@value": "21266",
      "#text": "Doroña",
    },
    {
      "@value": "21260",
      "#text": "Doso",
    },
    {
      "@value": "5893",
      "#text": "Dumbría",
    },
    {
      "@value": "21244",
      "#text": "Duyo",
    },
    {
      "@value": "21214",
      "#text": "Eijo",
    },
    {
      "@value": "21052",
      "#text": "El Pino",
    },
    {
      "@value": "20975",
      "#text": "Elviña",
    },
    {
      "@value": "20963",
      "#text": "Enchousas",
    },
    {
      "@value": "20961",
      "#text": "Encrobas",
    },
    {
      "@value": "20959",
      "#text": "Enfesta",
    },
    {
      "@value": "20955",
      "#text": "Enquerentes",
    },
    {
      "@value": "20943",
      "#text": "Entrecruces",
    },
    {
      "@value": "20958",
      "#text": "Enxilde",
    },
    {
      "@value": "20932",
      "#text": "Erbecedo",
    },
    {
      "@value": "20931",
      "#text": "Erboedo",
    },
    {
      "@value": "20919",
      "#text": "Erines",
    },
    {
      "@value": "20916",
      "#text": "Ermida",
    },
    {
      "@value": "12210",
      "#text": "Ermo",
    },
    {
      "@value": "20907",
      "#text": "Erviñóu",
    },
    {
      "@value": "20859",
      "#text": "Esfarrapa",
    },
    {
      "@value": "20855",
      "#text": "Esmelle",
    },
    {
      "@value": "20852",
      "#text": "Esmorode",
    },
    {
      "@value": "20848",
      "#text": "Esparrelle",
    },
    {
      "@value": "20845",
      "#text": "Espasante",
    },
    {
      "@value": "20838",
      "#text": "Esperante",
    },
    {
      "@value": "20832",
      "#text": "Espigas",
    },
    {
      "@value": "20824",
      "#text": "Espiñaredo",
    },
    {
      "@value": "20784",
      "#text": "Esteiro",
    },
    {
      "@value": "20775",
      "#text": "Esto",
    },
    {
      "@value": "20774",
      "#text": "Estorde",
    },
    {
      "@value": "20763",
      "#text": "Eume",
    },
    {
      "@value": "20752",
      "#text": "Fadibón",
    },
    {
      "@value": "20748",
      "#text": "Faeira",
    },
    {
      "@value": "20715",
      "#text": "Feás",
    },
    {
      "@value": "20712",
      "#text": "Fecha",
    },
    {
      "@value": "5891",
      "#text": "Fene",
    },
    {
      "@value": "20693",
      "#text": "Ferreira",
    },
    {
      "@value": "20685",
      "#text": "Ferreiros",
    },
    {
      "@value": "5890",
      "#text": "Ferrol",
    },
    {
      "@value": "20668",
      "#text": "Fervenzas",
    },
    {
      "@value": "20665",
      "#text": "Fieiro",
    },
    {
      "@value": "20662",
      "#text": "Figaredo",
    },
    {
      "@value": "20659",
      "#text": "Figueira",
    },
    {
      "@value": "20657",
      "#text": "Figueiras",
    },
    {
      "@value": "20649",
      "#text": "Figueiroa",
    },
    {
      "@value": "20645",
      "#text": "Figueroa",
    },
    {
      "@value": "20635",
      "#text": "Filgueira",
    },
    {
      "@value": "20634",
      "#text": "Filgueira de Traba",
    },
    {
      "@value": "20628",
      "#text": "Fiopáns",
    },
    {
      "@value": "5889",
      "#text": "Fisterra",
    },
    {
      "@value": "20625",
      "#text": "Fisteus",
    },
    {
      "@value": "20615",
      "#text": "Fojado",
    },
    {
      "@value": "20614",
      "#text": "Fojanes",
    },
    {
      "@value": "20613",
      "#text": "Fojas",
    },
    {
      "@value": "20603",
      "#text": "Folgoso",
    },
    {
      "@value": "20591",
      "#text": "Folladela",
    },
    {
      "@value": "20583",
      "#text": "Fondo",
    },
    {
      "@value": "20562",
      "#text": "Fontecada",
    },
    {
      "@value": "20555",
      "#text": "Fontefría",
    },
    {
      "@value": "20536",
      "#text": "Formarís",
    },
    {
      "@value": "20523",
      "#text": "Fornelos",
    },
    {
      "@value": "20517",
      "#text": "Fornís",
    },
    {
      "@value": "20516",
      "#text": "Fornos",
    },
    {
      "@value": "5888",
      "#text": "Frades",
    },
    {
      "@value": "20490",
      "#text": "Franza",
    },
    {
      "@value": "20478",
      "#text": "Freijeiro",
    },
    {
      "@value": "20470",
      "#text": "Freires",
    },
    {
      "@value": "20471",
      "#text": "Freixo",
    },
    {
      "@value": "20438",
      "#text": "Frije",
    },
    {
      "@value": "20429",
      "#text": "Fruime",
    },
    {
      "@value": "20378",
      "#text": "Fuentes-Rosas",
    },
    {
      "@value": "20368",
      "#text": "Fumiñeo",
    },
    {
      "@value": "20365",
      "#text": "Furelos",
    },
    {
      "@value": "20353",
      "#text": "Gafoy",
    },
    {
      "@value": "20331",
      "#text": "Galegos",
    },
    {
      "@value": "17114",
      "#text": "Galteiros",
    },
    {
      "@value": "20305",
      "#text": "Gándara",
    },
    {
      "@value": "20294",
      "#text": "Garabanja",
    },
    {
      "@value": "20261",
      "#text": "Gastrar",
    },
    {
      "@value": "20228",
      "#text": "Gestoso",
    },
    {
      "@value": "20219",
      "#text": "Gian",
    },
    {
      "@value": "20209",
      "#text": "Ginzo",
    },
    {
      "@value": "20191",
      "#text": "Goente",
    },
    {
      "@value": "20021",
      "#text": "Goimil",
    },
    {
      "@value": "20185",
      "#text": "Golán",
    },
    {
      "@value": "20179",
      "#text": "Golmar",
    },
    {
      "@value": "20174",
      "#text": "Goltar",
    },
    {
      "@value": "20162",
      "#text": "Gondollín",
    },
    {
      "@value": "20157",
      "#text": "Gontalde",
    },
    {
      "@value": "20155",
      "#text": "Gonte",
    },
    {
      "@value": "20153",
      "#text": "Gonzar",
    },
    {
      "@value": "20146",
      "#text": "Gorgullos",
    },
    {
      "@value": "20140",
      "#text": "Gosende",
    },
    {
      "@value": "20133",
      "#text": "Goyanes",
    },
    {
      "@value": "20109",
      "#text": "Grañas",
    },
    {
      "@value": "20106",
      "#text": "Grandal",
    },
    {
      "@value": "20100",
      "#text": "Granxa",
    },
    {
      "@value": "20082",
      "#text": "Grijoa",
    },
    {
      "@value": "20088",
      "#text": "Grixalba",
    },
    {
      "@value": "20077",
      "#text": "Grobas",
    },
    {
      "@value": "20054",
      "#text": "Gueima",
    },
    {
      "@value": "20038",
      "#text": "Guiende",
    },
    {
      "@value": "20017",
      "#text": "Guísamo",
    },
    {
      "@value": "20005",
      "#text": "Gundar",
    },
    {
      "@value": "19969",
      "#text": "Herbón",
    },
    {
      "@value": "19960",
      "#text": "Hermedelo",
    },
    {
      "@value": "19934",
      "#text": "Herves",
    },
    {
      "@value": "19880",
      "#text": "Hospital",
    },
    {
      "@value": "19810",
      "#text": "Iglesiafeita",
    },
    {
      "@value": "19798",
      "#text": "Illobre",
    },
    {
      "@value": "19793",
      "#text": "Iñaño",
    },
    {
      "@value": "19791",
      "#text": "Iñás",
    },
    {
      "@value": "19772",
      "#text": "Insúa",
    },
    {
      "@value": "24658",
      "#text": "Insuela",
    },
    {
      "@value": "19755",
      "#text": "Iria",
    },
    {
      "@value": "5886",
      "#text": "Irixoa",
    },
    {
      "@value": "19748",
      "#text": "Irroa",
    },
    {
      "@value": "19726",
      "#text": "Isorna",
    },
    {
      "@value": "19679",
      "#text": "Javiña",
    },
    {
      "@value": "19676",
      "#text": "Jestosa",
    },
    {
      "@value": "19671",
      "#text": "Jobre",
    },
    {
      "@value": "19665",
      "#text": "Jornes",
    },
    {
      "@value": "19662",
      "#text": "Juanceda",
    },
    {
      "@value": "19648",
      "#text": "Juno",
    },
    {
      "@value": "19407",
      "#text": "La Iglesia",
    },
    {
      "@value": "19172",
      "#text": "La Riba",
    },
    {
      "@value": "18975",
      "#text": "La Toja",
    },
    {
      "@value": "19601",
      "#text": "Labacengos",
    },
    {
      "@value": "19502",
      "#text": "Ladrido",
    },
    {
      "@value": "19448",
      "#text": "Lago",
    },
    {
      "@value": "19445",
      "#text": "Lagoa",
    },
    {
      "@value": "19441",
      "#text": "Lagostelle",
    },
    {
      "@value": "19343",
      "#text": "Lamas",
    },
    {
      "@value": "19340",
      "#text": "Lamasapín",
    },
    {
      "@value": "19338",
      "#text": "Lamastredo",
    },
    {
      "@value": "19303",
      "#text": "Lampay",
    },
    {
      "@value": "19295",
      "#text": "Lañas",
    },
    {
      "@value": "19286",
      "#text": "Landeira",
    },
    {
      "@value": "19283",
      "#text": "Landoi",
    },
    {
      "@value": "19276",
      "#text": "Langueirón",
    },
    {
      "@value": "19269",
      "#text": "Lantarou",
    },
    {
      "@value": "19261",
      "#text": "Lanzá",
    },
    {
      "@value": "19189",
      "#text": "Laraño",
    },
    {
      "@value": "19193",
      "#text": "Laraxe",
    },
    {
      "@value": "19183",
      "#text": "Lardeiros",
    },
    {
      "@value": "19164",
      "#text": "Larín",
    },
    {
      "@value": "19163",
      "#text": "Lariño",
    },
    {
      "@value": "19106",
      "#text": "Las Bouzas",
    },
    {
      "@value": "24805",
      "#text": "Lavacolla",
    },
    {
      "@value": "5887",
      "#text": "Laxe",
    },
    {
      "@value": "18909",
      "#text": "Lazo",
    },
    {
      "@value": "18904",
      "#text": "Leboreiro",
    },
    {
      "@value": "18882",
      "#text": "Ledoira",
    },
    {
      "@value": "18881",
      "#text": "Ledoño",
    },
    {
      "@value": "18874",
      "#text": "Leiloyo",
    },
    {
      "@value": "18868",
      "#text": "Leiro",
    },
    {
      "@value": "18865",
      "#text": "Leis",
    },
    {
      "@value": "18876",
      "#text": "Leixa",
    },
    {
      "@value": "18861",
      "#text": "Lema",
    },
    {
      "@value": "18860",
      "#text": "Lemaio",
    },
    {
      "@value": "18857",
      "#text": "Lendo",
    },
    {
      "@value": "18856",
      "#text": "Lens",
    },
    {
      "@value": "18853",
      "#text": "Leobalde",
    },
    {
      "@value": "18842",
      "#text": "Leroño",
    },
    {
      "@value": "18839",
      "#text": "Lesa",
    },
    {
      "@value": "18837",
      "#text": "Lesende",
    },
    {
      "@value": "18835",
      "#text": "Lesón",
    },
    {
      "@value": "18832",
      "#text": "Lesta",
    },
    {
      "@value": "18831",
      "#text": "Lestedo",
    },
    {
      "@value": "18829",
      "#text": "Lestelle",
    },
    {
      "@value": "18828",
      "#text": "Lestimoño",
    },
    {
      "@value": "18827",
      "#text": "Lestón",
    },
    {
      "@value": "24656",
      "#text": "Lestrobe",
    },
    {
      "@value": "18821",
      "#text": "Liáns",
    },
    {
      "@value": "18796",
      "#text": "Limodre",
    },
    {
      "@value": "18792",
      "#text": "Liñares",
    },
    {
      "@value": "18784",
      "#text": "Liñayo",
    },
    {
      "@value": "18778",
      "#text": "Linteiros",
    },
    {
      "@value": "18774",
      "#text": "Lira",
    },
    {
      "@value": "18773",
      "#text": "Lires",
    },
    {
      "@value": "18706",
      "#text": "Loalo",
    },
    {
      "@value": "18701",
      "#text": "Lobelos",
    },
    {
      "@value": "18682",
      "#text": "Logrosa",
    },
    {
      "@value": "18681",
      "#text": "Loiba",
    },
    {
      "@value": "18679",
      "#text": "Loira",
    },
    {
      "@value": "18675",
      "#text": "Lojo",
    },
    {
      "@value": "18668",
      "#text": "Lomba",
    },
    {
      "@value": "18636",
      "#text": "Loroño",
    },
    {
      "@value": "18553",
      "#text": "Lourdes",
    },
    {
      "@value": "18552",
      "#text": "Loureda",
    },
    {
      "@value": "18543",
      "#text": "Lourido",
    },
    {
      "@value": "18541",
      "#text": "Louriño",
    },
    {
      "@value": "18538",
      "#text": "Louro",
    },
    {
      "@value": "5884",
      "#text": "Lousame",
    },
    {
      "@value": "18532",
      "#text": "Loyos",
    },
    {
      "@value": "18524",
      "#text": "Luama",
    },
    {
      "@value": "18523",
      "#text": "Luaña",
    },
    {
      "@value": "18519",
      "#text": "Lubre",
    },
    {
      "@value": "18510",
      "#text": "Lueiro",
    },
    {
      "@value": "18500",
      "#text": "Luhía",
    },
    {
      "@value": "18493",
      "#text": "Luou",
    },
    {
      "@value": "18484",
      "#text": "Mabegondo",
    },
    {
      "@value": "18482",
      "#text": "Maceda",
    },
    {
      "@value": "18464",
      "#text": "Magalofes",
    },
    {
      "@value": "18456",
      "#text": "Magdalena",
    },
    {
      "@value": "18454",
      "#text": "Magro",
    },
    {
      "@value": "18183",
      "#text": "Maianca",
    },
    {
      "@value": "18448",
      "#text": "Major",
    },
    {
      "@value": "18438",
      "#text": "Mallas",
    },
    {
      "@value": "18430",
      "#text": "Mallón",
    },
    {
      "@value": "18429",
      "#text": "Mallou",
    },
    {
      "@value": "5883",
      "#text": "Malpica de Bergantiños",
    },
    {
      "@value": "18420",
      "#text": "Mámoa",
    },
    {
      "@value": "18419",
      "#text": "Mámoas",
    },
    {
      "@value": "18414",
      "#text": "Manda",
    },
    {
      "@value": "18412",
      "#text": "Mandaio",
    },
    {
      "@value": "18411",
      "#text": "Mandiá",
    },
    {
      "@value": "18406",
      "#text": "Mangüeiro",
    },
    {
      "@value": "5882",
      "#text": "Mañón",
    },
    {
      "@value": "18402",
      "#text": "Manselle",
    },
    {
      "@value": "18398",
      "#text": "Mántaras",
    },
    {
      "@value": "18397",
      "#text": "Mantoño",
    },
    {
      "@value": "18371",
      "#text": "Marantes",
    },
    {
      "@value": "18364",
      "#text": "Marcelle",
    },
    {
      "@value": "18351",
      "#text": "Margarida",
    },
    {
      "@value": "18340",
      "#text": "Marmancón",
    },
    {
      "@value": "18335",
      "#text": "Marojo",
    },
    {
      "@value": "18334",
      "#text": "Maroñas",
    },
    {
      "@value": "18330",
      "#text": "Marquiño",
    },
    {
      "@value": "18327",
      "#text": "Marracín de Arriba",
    },
    {
      "@value": "18325",
      "#text": "Marrozos",
    },
    {
      "@value": "18313",
      "#text": "Martín",
    },
    {
      "@value": "18300",
      "#text": "Marzoa",
    },
    {
      "@value": "18206",
      "#text": "Matasueiro",
    },
    {
      "@value": "5881",
      "#text": "Mazaricos",
    },
    {
      "@value": "18138",
      "#text": "Meá",
    },
    {
      "@value": "18159",
      "#text": "Meangos",
    },
    {
      "@value": "18157",
      "#text": "Meanos",
    },
    {
      "@value": "18144",
      "#text": "Medín",
    },
    {
      "@value": "18135",
      "#text": "Meijigo",
    },
    {
      "@value": "18130",
      "#text": "Meirama",
    },
    {
      "@value": "18128",
      "#text": "Meirás",
    },
    {
      "@value": "18127",
      "#text": "Meire",
    },
    {
      "@value": "5880",
      "#text": "Melide",
    },
    {
      "@value": "18112",
      "#text": "Mella",
    },
    {
      "@value": "18082",
      "#text": "Mens",
    },
    {
      "@value": "24696",
      "#text": "Mera",
    },
    {
      "@value": "18070",
      "#text": "Merejo",
    },
    {
      "@value": "18069",
      "#text": "Merelle",
    },
    {
      "@value": "18063",
      "#text": "Merín",
    },
    {
      "@value": "5879",
      "#text": "Mesía",
    },
    {
      "@value": "18049",
      "#text": "Mesós",
    },
    {
      "@value": "18043",
      "#text": "Mezonzo",
    },
    {
      "@value": "18038",
      "#text": "Mieites",
    },
    {
      "@value": "18020",
      "#text": "Millón",
    },
    {
      "@value": "5878",
      "#text": "Miño",
    },
    {
      "@value": "18008",
      "#text": "Miñóns",
    },
    {
      "@value": "18007",
      "#text": "Miñortos",
    },
    {
      "@value": "17994",
      "#text": "Mirandela",
    },
    {
      "@value": "17991",
      "#text": "Mirás",
    },
    {
      "@value": "17986",
      "#text": "Miraz",
    },
    {
      "@value": "17976",
      "#text": "Moar",
    },
    {
      "@value": "5877",
      "#text": "Moeche",
    },
    {
      "@value": "17965",
      "#text": "Mogor",
    },
    {
      "@value": "17960",
      "#text": "Moimenta",
    },
    {
      "@value": "17953",
      "#text": "Moldes",
    },
    {
      "@value": "17945",
      "#text": "Molinos",
    },
    {
      "@value": "17925",
      "#text": "Mondego",
    },
    {
      "@value": "5876",
      "#text": "Monfero",
    },
    {
      "@value": "17904",
      "#text": "Montaña",
    },
    {
      "@value": "17895",
      "#text": "Montaos",
    },
    {
      "@value": "17887",
      "#text": "Monte",
    },
    {
      "@value": "17882",
      "#text": "Monteagudo",
    },
    {
      "@value": "17877",
      "#text": "Montecelos",
    },
    {
      "@value": "17858",
      "#text": "Montemaior",
    },
    {
      "@value": "17845",
      "#text": "Montesalgueiro",
    },
    {
      "@value": "17839",
      "#text": "Montesiños",
    },
    {
      "@value": "17825",
      "#text": "Montón",
    },
    {
      "@value": "24617",
      "#text": "Montouto",
    },
    {
      "@value": "17826",
      "#text": "Montoxo",
    },
    {
      "@value": "24666",
      "#text": "Montrove",
    },
    {
      "@value": "17808",
      "#text": "Monzo",
    },
    {
      "@value": "17791",
      "#text": "Morás",
    },
    {
      "@value": "17783",
      "#text": "Mórdomo",
    },
    {
      "@value": "17761",
      "#text": "Morlán",
    },
    {
      "@value": "17756",
      "#text": "Morpeguite",
    },
    {
      "@value": "17755",
      "#text": "Morquintián",
    },
    {
      "@value": "17750",
      "#text": "Moruxo",
    },
    {
      "@value": "17734",
      "#text": "Mosteiro",
    },
    {
      "@value": "17733",
      "#text": "Mosteirón",
    },
    {
      "@value": "17725",
      "#text": "Mourazos",
    },
    {
      "@value": "17718",
      "#text": "Mourín",
    },
    {
      "@value": "17713",
      "#text": "Mouzo",
    },
    {
      "@value": "5875",
      "#text": "Mugardos",
    },
    {
      "@value": "17689",
      "#text": "Muiña",
    },
    {
      "@value": "17688",
      "#text": "Muiño",
    },
    {
      "@value": "17687",
      "#text": "Muiños",
    },
    {
      "@value": "17672",
      "#text": "Muniferral",
    },
    {
      "@value": "17644",
      "#text": "Muro",
    },
    {
      "@value": "5873",
      "#text": "Muros",
    },
    {
      "@value": "5874",
      "#text": "Muxía",
    },
    {
      "@value": "17612",
      "#text": "Nande",
    },
    {
      "@value": "17609",
      "#text": "Nantón",
    },
    {
      "@value": "17607",
      "#text": "Narahío",
    },
    {
      "@value": "5871",
      "#text": "Narón",
    },
    {
      "@value": "17527",
      "#text": "Neaño",
    },
    {
      "@value": "17526",
      "#text": "Nebra",
    },
    {
      "@value": "5872",
      "#text": "Neda",
    },
    {
      "@value": "17523",
      "#text": "Negradas",
    },
    {
      "@value": "5870",
      "#text": "Negreira",
    },
    {
      "@value": "17512",
      "#text": "Nemenzo",
    },
    {
      "@value": "17492",
      "#text": "Neves",
    },
    {
      "@value": "17489",
      "#text": "Niñán",
    },
    {
      "@value": "17488",
      "#text": "Nine",
    },
    {
      "@value": "17486",
      "#text": "Niñodaguía",
    },
    {
      "@value": "17485",
      "#text": "Niñóns",
    },
    {
      "@value": "17482",
      "#text": "Niveiro",
    },
    {
      "@value": "17480",
      "#text": "Noal",
    },
    {
      "@value": "17474",
      "#text": "Noceda",
    },
    {
      "@value": "17446",
      "#text": "Nogueirosa",
    },
    {
      "@value": "5869",
      "#text": "Noia",
    },
    {
      "@value": "17441",
      "#text": "Noicela",
    },
    {
      "@value": "17435",
      "#text": "Nós",
    },
    {
      "@value": "17434",
      "#text": "Nóutigos",
    },
    {
      "@value": "17428",
      "#text": "Novela",
    },
    {
      "@value": "17420",
      "#text": "Noya",
    },
    {
      "@value": "17410",
      "#text": "Numide",
    },
    {
      "@value": "24680",
      "#text": "O Allo",
    },
    {
      "@value": "21178",
      "#text": "O Burgo",
    },
    {
      "@value": "20761",
      "#text": "O Ézaro",
    },
    {
      "@value": "122001",
      "#text": "O Milladoiro",
    },
    {
      "@value": "21053",
      "#text": "O Pindo",
    },
    {
      "@value": "5860",
      "#text": "O Pino",
    },
    {
      "@value": "24667",
      "#text": "O Puntal de Abaixo",
    },
    {
      "@value": "24313",
      "#text": "O Seixo",
    },
    {
      "@value": "14081",
      "#text": "O Temple",
    },
    {
      "@value": "17398",
      "#text": "Obre",
    },
    {
      "@value": "17395",
      "#text": "Oca",
    },
    {
      "@value": "17372",
      "#text": "Ogas",
    },
    {
      "@value": "17369",
      "#text": "Oín",
    },
    {
      "@value": "17368",
      "#text": "Oinés",
    },
    {
      "@value": "17363",
      "#text": "Ois",
    },
    {
      "@value": "17347",
      "#text": "Olás",
    },
    {
      "@value": "5868",
      "#text": "Oleiros",
    },
    {
      "@value": "17300",
      "#text": "Olveira",
    },
    {
      "@value": "19921",
      "#text": "Ombre",
    },
    {
      "@value": "17280",
      "#text": "Ons",
    },
    {
      "@value": "17260",
      "#text": "Orbellido",
    },
    {
      "@value": "5867",
      "#text": "Ordes",
    },
    {
      "@value": "17250",
      "#text": "Ordoeste",
    },
    {
      "@value": "17221",
      "#text": "Orois",
    },
    {
      "@value": "5866",
      "#text": "Oroso",
    },
    {
      "@value": "17208",
      "#text": "Orro",
    },
    {
      "@value": "5865",
      "#text": "Ortigueira",
    },
    {
      "@value": "17199",
      "#text": "Orto",
    },
    {
      "@value": "17196",
      "#text": "Ortoño",
    },
    {
      "@value": "17194",
      "#text": "Oruxo",
    },
    {
      "@value": "24883",
      "#text": "Os Anxeles",
    },
    {
      "@value": "17185",
      "#text": "Osedo",
    },
    {
      "@value": "17184",
      "#text": "Oseiro",
    },
    {
      "@value": "17128",
      "#text": "Ouces",
    },
    {
      "@value": "17126",
      "#text": "Oural",
    },
    {
      "@value": "17116",
      "#text": "Outeiro",
    },
    {
      "@value": "5864",
      "#text": "Outes",
    },
    {
      "@value": "17113",
      "#text": "Outón",
    },
    {
      "@value": "17096",
      "#text": "Oza",
    },
    {
      "@value": "17095",
      "#text": "Oza Bella",
    },
    {
      "@value": "17094",
      "#text": "Oza de los Ríos",
    },
    {
      "@value": "5863",
      "#text": "Oza dos Ríos",
    },
    {
      "@value": "5862",
      "#text": "Paderne",
    },
    {
      "@value": "17076",
      "#text": "Padreiro",
    },
    {
      "@value": "17075",
      "#text": "Padreiro de Abajo",
    },
    {
      "@value": "17072",
      "#text": "Padris",
    },
    {
      "@value": "5861",
      "#text": "Padrón",
    },
    {
      "@value": "17067",
      "#text": "Pajareiras",
    },
    {
      "@value": "17016",
      "#text": "Palmeira",
    },
    {
      "@value": "17004",
      "#text": "Panchés",
    },
    {
      "@value": "16983",
      "#text": "Pantín",
    },
    {
      "@value": "16982",
      "#text": "Pantiñobre",
    },
    {
      "@value": "16978",
      "#text": "Papucín",
    },
    {
      "@value": "16972",
      "#text": "Parada",
    },
    {
      "@value": "16952",
      "#text": "Paradela",
    },
    {
      "@value": "16935",
      "#text": "Páramos",
    },
    {
      "@value": "16893",
      "#text": "Pasarelos",
    },
    {
      "@value": "16885",
      "#text": "Pastor",
    },
    {
      "@value": "16883",
      "#text": "Pastoriza",
    },
    {
      "@value": "16869",
      "#text": "Pazo",
    },
    {
      "@value": "16865",
      "#text": "Pazos",
    },
    {
      "@value": "16864",
      "#text": "Pazos de Abajo",
    },
    {
      "@value": "16863",
      "#text": "Pazos de Arriba",
    },
    {
      "@value": "16637",
      "#text": "Pedra",
    },
    {
      "@value": "16855",
      "#text": "Pedra do Frade",
    },
    {
      "@value": "16854",
      "#text": "Pedrafigueira",
    },
    {
      "@value": "24310",
      "#text": "Pedreira",
    },
    {
      "@value": "16838",
      "#text": "Pedriña",
    },
    {
      "@value": "16824",
      "#text": "Pedroso",
    },
    {
      "@value": "16820",
      "#text": "Pedrouzo",
    },
    {
      "@value": "16818",
      "#text": "Pedrouzos",
    },
    {
      "@value": "16795",
      "#text": "Pena",
    },
    {
      "@value": "16751",
      "#text": "Penedo",
    },
    {
      "@value": "16721",
      "#text": "Perbes",
    },
    {
      "@value": "16710",
      "#text": "Peregrina",
    },
    {
      "@value": "16707",
      "#text": "Pereira",
    },
    {
      "@value": "16702",
      "#text": "Pereiriña",
    },
    {
      "@value": "16694",
      "#text": "Perillo",
    },
    {
      "@value": "16691",
      "#text": "Perlio",
    },
    {
      "@value": "16677",
      "#text": "Perros",
    },
    {
      "@value": "16670",
      "#text": "Pesadoira de Abaixo",
    },
    {
      "@value": "16669",
      "#text": "Pesadoira de Arriba",
    },
    {
      "@value": "16665",
      "#text": "Pesqueira",
    },
    {
      "@value": "16655",
      "#text": "Pezobre",
    },
    {
      "@value": "16653",
      "#text": "Piadela",
    },
    {
      "@value": "16624",
      "#text": "Piedramayor",
    },
    {
      "@value": "16608",
      "#text": "Piloño",
    },
    {
      "@value": "16589",
      "#text": "Piñeiro",
    },
    {
      "@value": "16587",
      "#text": "Piñeiros",
    },
    {
      "@value": "13560",
      "#text": "Pino de Val",
    },
    {
      "@value": "16568",
      "#text": "Piñor",
    },
    {
      "@value": "16549",
      "#text": "Piroxa",
    },
    {
      "@value": "16493",
      "#text": "Pombal",
    },
    {
      "@value": "5858",
      "#text": "Ponteceso",
    },
    {
      "@value": "5857",
      "#text": "Pontedeume",
    },
    {
      "@value": "16483",
      "#text": "Pontellas",
    },
    {
      "@value": "16476",
      "#text": "Porcar",
    },
    {
      "@value": "16437",
      "#text": "Porto",
    },
    {
      "@value": "16273",
      "#text": "Porto do Barqueiro",
    },
    {
      "@value": "5855",
      "#text": "Porto do Son",
    },
    {
      "@value": "16433",
      "#text": "Portodemouros",
    },
    {
      "@value": "16432",
      "#text": "Portomeiro",
    },
    {
      "@value": "16430",
      "#text": "Portomouro",
    },
    {
      "@value": "16428",
      "#text": "Portor",
    },
    {
      "@value": "16427",
      "#text": "Portosin",
    },
    {
      "@value": "16426",
      "#text": "Portugalete",
    },
    {
      "@value": "16425",
      "#text": "Porzomillos",
    },
    {
      "@value": "16415",
      "#text": "Postmarcos",
    },
    {
      "@value": "16412",
      "#text": "Poulo",
    },
    {
      "@value": "16410",
      "#text": "Pousacarro",
    },
    {
      "@value": "16409",
      "#text": "Pousada",
    },
    {
      "@value": "16379",
      "#text": "Prado",
    },
    {
      "@value": "16363",
      "#text": "Pragais",
    },
    {
      "@value": "16358",
      "#text": "Pravio",
    },
    {
      "@value": "16349",
      "#text": "Présaras",
    },
    {
      "@value": "16348",
      "#text": "Presedo",
    },
    {
      "@value": "16345",
      "#text": "Prevediños",
    },
    {
      "@value": "16330",
      "#text": "Probaos",
    },
    {
      "@value": "16316",
      "#text": "Puebla del Caramiñal",
    },
    {
      "@value": "16309",
      "#text": "Puente",
    },
    {
      "@value": "16299",
      "#text": "Puente de Don Alonso",
    },
    {
      "@value": "16286",
      "#text": "Puente-Olveira",
    },
    {
      "@value": "16280",
      "#text": "Puente-Ulla",
    },
    {
      "@value": "16279",
      "#text": "Puentevea",
    },
    {
      "@value": "16275",
      "#text": "Puerto",
    },
    {
      "@value": "24316",
      "#text": "Punta Bañobre",
    },
    {
      "@value": "16230",
      "#text": "Queijeiro",
    },
    {
      "@value": "16227",
      "#text": "Queiroso",
    },
    {
      "@value": "16226",
      "#text": "Queiruga",
    },
    {
      "@value": "16232",
      "#text": "Queixas",
    },
    {
      "@value": "16220",
      "#text": "Quembre",
    },
    {
      "@value": "16218",
      "#text": "Quenje",
    },
    {
      "@value": "16211",
      "#text": "Quian",
    },
    {
      "@value": "16209",
      "#text": "Quilmas",
    },
    {
      "@value": "16115",
      "#text": "Quintáns",
    },
    {
      "@value": "16114",
      "#text": "Quintas",
    },
    {
      "@value": "16101",
      "#text": "Quion",
    },
    {
      "@value": "16076",
      "#text": "Rairiz",
    },
    {
      "@value": "16069",
      "#text": "Ramallosa",
    },
    {
      "@value": "16064",
      "#text": "Ramil",
    },
    {
      "@value": "16044",
      "#text": "Rasamonde",
    },
    {
      "@value": "16037",
      "#text": "Raso",
    },
    {
      "@value": "16033",
      "#text": "Razo da Costa",
    },
    {
      "@value": "121521",
      "#text": "Real Aero Club de Santiago - Campo de Golf",
    },
    {
      "@value": "121519",
      "#text": "Real Club de Golf de La Coruña",
    },
    {
      "@value": "16000",
      "#text": "Reboredo",
    },
    {
      "@value": "15995",
      "#text": "Recemel",
    },
    {
      "@value": "15994",
      "#text": "Recesende",
    },
    {
      "@value": "24314",
      "#text": "Redes",
    },
    {
      "@value": "15982",
      "#text": "Redonda",
    },
    {
      "@value": "15971",
      "#text": "Rego",
    },
    {
      "@value": "15970",
      "#text": "Régoa",
    },
    {
      "@value": "15968",
      "#text": "Regoelle",
    },
    {
      "@value": "15965",
      "#text": "Regueira",
    },
    {
      "@value": "15930",
      "#text": "Rendal",
    },
    {
      "@value": "15918",
      "#text": "Reparada",
    },
    {
      "@value": "15902",
      "#text": "Requián",
    },
    {
      "@value": "15895",
      "#text": "Restande",
    },
    {
      "@value": "15891",
      "#text": "Resua",
    },
    {
      "@value": "15863",
      "#text": "Reyes",
    },
    {
      "@value": "15858",
      "#text": "Rial",
    },
    {
      "@value": "15857",
      "#text": "Rial de Abajo",
    },
    {
      "@value": "15856",
      "#text": "Rial de Arriba",
    },
    {
      "@value": "5854",
      "#text": "Rianxo",
    },
    {
      "@value": "15851",
      "#text": "Rías",
    },
    {
      "@value": "15842",
      "#text": "Ribadeume",
    },
    {
      "@value": "15841",
      "#text": "Ribadulla",
    },
    {
      "@value": "15831",
      "#text": "Ribasar",
    },
    {
      "@value": "15823",
      "#text": "Ribasieira",
    },
    {
      "@value": "5853",
      "#text": "Ribeira",
    },
    {
      "@value": "15810",
      "#text": "Ribeiras do Sor",
    },
    {
      "@value": "15816",
      "#text": "Ribeiro",
    },
    {
      "@value": "15796",
      "#text": "Rieiro",
    },
    {
      "@value": "15776",
      "#text": "Ríobarba",
    },
    {
      "@value": "15774",
      "#text": "Riobó",
    },
    {
      "@value": "15726",
      "#text": "Ríotorto",
    },
    {
      "@value": "15720",
      "#text": "Rivadeza",
    },
    {
      "@value": "15715",
      "#text": "Roade",
    },
    {
      "@value": "15663",
      "#text": "Rodeiro",
    },
    {
      "@value": "15662",
      "#text": "Rodeiros",
    },
    {
      "@value": "15651",
      "#text": "Rodiño-Pequeño",
    },
    {
      "@value": "15649",
      "#text": "Rodís",
    },
    {
      "@value": "15645",
      "#text": "Rogüela",
    },
    {
      "@value": "5852",
      "#text": "Rois",
    },
    {
      "@value": "15638",
      "#text": "Roma",
    },
    {
      "@value": "15630",
      "#text": "Romar",
    },
    {
      "@value": "15629",
      "#text": "Romaris",
    },
    {
      "@value": "15615",
      "#text": "Roo de Abajo",
    },
    {
      "@value": "15614",
      "#text": "Roo de Arriba",
    },
    {
      "@value": "15604",
      "#text": "Roupar",
    },
    {
      "@value": "15554",
      "#text": "Rumille",
    },
    {
      "@value": "15552",
      "#text": "Runes",
    },
    {
      "@value": "15549",
      "#text": "Rus",
    },
    {
      "@value": "15548",
      "#text": "Rutis",
    },
    {
      "@value": "15542",
      "#text": "Sábade",
    },
    {
      "@value": "15526",
      "#text": "Sabugueira",
    },
    {
      "@value": "5851",
      "#text": "Sada",
    },
    {
      "@value": "24317",
      "#text": "Sada d'Arriba",
    },
    {
      "@value": "15474",
      "#text": "Sales",
    },
    {
      "@value": "15468",
      "#text": "Salgueiros",
    },
    {
      "@value": "15443",
      "#text": "Salto",
    },
    {
      "@value": "15424",
      "#text": "Sampayo",
    },
    {
      "@value": "24762",
      "#text": "San Andres de Teixido",
    },
    {
      "@value": "15363",
      "#text": "San Claudio",
    },
    {
      "@value": "15353",
      "#text": "San Crimenso",
    },
    {
      "@value": "15348",
      "#text": "San Cristóbal",
    },
    {
      "@value": "15248",
      "#text": "San Julián",
    },
    {
      "@value": "25753",
      "#text": "San Lázaro",
    },
    {
      "@value": "15220",
      "#text": "San Mamede",
    },
    {
      "@value": "24659",
      "#text": "San Marcos",
    },
    {
      "@value": "15194",
      "#text": "San Martín de Arriba",
    },
    {
      "@value": "19657",
      "#text": "San Martin de Jubia",
    },
    {
      "@value": "15111",
      "#text": "San Payo",
    },
    {
      "@value": "15055",
      "#text": "San Román",
    },
    {
      "@value": "15041",
      "#text": "San Roque",
    },
    {
      "@value": "5850",
      "#text": "San Sadurniño",
    },
    {
      "@value": "15039",
      "#text": "San Salvador",
    },
    {
      "@value": "15032",
      "#text": "San Saturnino",
    },
    {
      "@value": "14814",
      "#text": "San Tirso",
    },
    {
      "@value": "15240",
      "#text": "San Xusto",
    },
    {
      "@value": "15374",
      "#text": "Sancibrao",
    },
    {
      "@value": "15328",
      "#text": "Sandrejo",
    },
    {
      "@value": "15292",
      "#text": "Sanfins",
    },
    {
      "@value": "15289",
      "#text": "Sanfoga",
    },
    {
      "@value": "15243",
      "#text": "Sanjurjo",
    },
    {
      "@value": "15235",
      "#text": "Sanles",
    },
    {
      "@value": "15026",
      "#text": "Sansobre",
    },
    {
      "@value": "5849",
      "#text": "Santa Comba",
    },
    {
      "@value": "24665",
      "#text": "Santa Cristina",
    },
    {
      "@value": "24719",
      "#text": "Santa Cruz de Oleiros",
    },
    {
      "@value": "14903",
      "#text": "Santa Marina",
    },
    {
      "@value": "14869",
      "#text": "Santa Sabina",
    },
    {
      "@value": "14965",
      "#text": "Santa Uxía",
    },
    {
      "@value": "14966",
      "#text": "Santa Uxía de Ribeira",
    },
    {
      "@value": "14859",
      "#text": "Santeles",
    },
    {
      "@value": "5848",
      "#text": "Santiago de Compostela",
    },
    {
      "@value": "5847",
      "#text": "Santiso",
    },
    {
      "@value": "20952",
      "#text": "Santo Ourente",
    },
    {
      "@value": "14735",
      "#text": "Sarandón",
    },
    {
      "@value": "14734",
      "#text": "Sarandones",
    },
    {
      "@value": "14723",
      "#text": "Sardiñeiro",
    },
    {
      "@value": "14722",
      "#text": "Sardiñeiro de Abajo",
    },
    {
      "@value": "14676",
      "#text": "Seavia",
    },
    {
      "@value": "14667",
      "#text": "Sedes",
    },
    {
      "@value": "14666",
      "#text": "Sedor",
    },
    {
      "@value": "14651",
      "#text": "Seira",
    },
    {
      "@value": "14657",
      "#text": "Seixas",
    },
    {
      "@value": "14654",
      "#text": "Seixo",
    },
    {
      "@value": "14644",
      "#text": "Sejo",
    },
    {
      "@value": "14626",
      "#text": "Sembra",
    },
    {
      "@value": "14618",
      "#text": "Sendelle",
    },
    {
      "@value": "14615",
      "#text": "Sendón",
    },
    {
      "@value": "14609",
      "#text": "Senra",
    },
    {
      "@value": "14601",
      "#text": "Seone",
    },
    {
      "@value": "14598",
      "#text": "Sequeiro",
    },
    {
      "@value": "14596",
      "#text": "Ser",
    },
    {
      "@value": "14593",
      "#text": "Serantes",
    },
    {
      "@value": "14586",
      "#text": "Sergude",
    },
    {
      "@value": "14580",
      "#text": "Serramo",
    },
    {
      "@value": "14573",
      "#text": "Serres",
    },
    {
      "@value": "14562",
      "#text": "Sésamo",
    },
    {
      "@value": "14555",
      "#text": "Sestayo",
    },
    {
      "@value": "14553",
      "#text": "Sesto",
    },
    {
      "@value": "14541",
      "#text": "Siavo",
    },
    {
      "@value": "14529",
      "#text": "Sierra de Outes",
    },
    {
      "@value": "14517",
      "#text": "Sigrás de Arriba",
    },
    {
      "@value": "14516",
      "#text": "Sigüeiro",
    },
    {
      "@value": "14510",
      "#text": "Sillobre",
    },
    {
      "@value": "14508",
      "#text": "Silva",
    },
    {
      "@value": "14502",
      "#text": "Silvarredonda",
    },
    {
      "@value": "14496",
      "#text": "Silvosa",
    },
    {
      "@value": "14491",
      "#text": "Sinagoga",
    },
    {
      "@value": "14489",
      "#text": "Sinde",
    },
    {
      "@value": "14476",
      "#text": "Sísamo",
    },
    {
      "@value": "14475",
      "#text": "Sismundi",
    },
    {
      "@value": "14470",
      "#text": "Sisto",
    },
    {
      "@value": "14467",
      "#text": "Soandres",
    },
    {
      "@value": "14465",
      "#text": "Soaserra",
    },
    {
      "@value": "5846",
      "#text": "Sobrado",
    },
    {
      "@value": "14446",
      "#text": "Sobreira",
    },
    {
      "@value": "14434",
      "#text": "Sobrido",
    },
    {
      "@value": "14426",
      "#text": "Sofán",
    },
    {
      "@value": "14394",
      "#text": "Solleiros",
    },
    {
      "@value": "14377",
      "#text": "Somozas",
    },
    {
      "@value": "14375",
      "#text": "Son",
    },
    {
      "@value": "14370",
      "#text": "Soñeiro",
    },
    {
      "@value": "14351",
      "#text": "Sorribas",
    },
    {
      "@value": "14347",
      "#text": "Sorrizo",
    },
    {
      "@value": "14278",
      "#text": "Soutullo",
    },
    {
      "@value": "14277",
      "#text": "Soutullos",
    },
    {
      "@value": "14263",
      "#text": "Sueiro",
    },
    {
      "@value": "14256",
      "#text": "Suevos",
    },
    {
      "@value": "14254",
      "#text": "Sumio",
    },
    {
      "@value": "14248",
      "#text": "Susana",
    },
    {
      "@value": "14236",
      "#text": "Tabeayo",
    },
    {
      "@value": "14218",
      "#text": "Taboada",
    },
    {
      "@value": "14202",
      "#text": "Tal",
    },
    {
      "@value": "14196",
      "#text": "Tállara",
    },
    {
      "@value": "14194",
      "#text": "Tallo",
    },
    {
      "@value": "14192",
      "#text": "Tallós",
    },
    {
      "@value": "14168",
      "#text": "Tapia",
    },
    {
      "@value": "14162",
      "#text": "Taragoña",
    },
    {
      "@value": "14159",
      "#text": "Taramancos",
    },
    {
      "@value": "14152",
      "#text": "Tarás",
    },
    {
      "@value": "14137",
      "#text": "Tarrío",
    },
    {
      "@value": "14129",
      "#text": "Tavilo",
    },
    {
      "@value": "14127",
      "#text": "Teaño",
    },
    {
      "@value": "14124",
      "#text": "Tedín",
    },
    {
      "@value": "14114",
      "#text": "Teixeiro",
    },
    {
      "@value": "14108",
      "#text": "Teixido",
    },
    {
      "@value": "14107",
      "#text": "Teixidón",
    },
    {
      "@value": "24315",
      "#text": "Telle",
    },
    {
      "@value": "5844",
      "#text": "Teo",
    },
    {
      "@value": "14074",
      "#text": "Terelle",
    },
    {
      "@value": "14045",
      "#text": "Tines",
    },
    {
      "@value": "14041",
      "#text": "Tiobre",
    },
    {
      "@value": "14032",
      "#text": "Toba de Arriba",
    },
    {
      "@value": "14020",
      "#text": "Tojos Outos",
    },
    {
      "@value": "5843",
      "#text": "Toques",
    },
    {
      "@value": "13979",
      "#text": "Torás",
    },
    {
      "@value": "5842",
      "#text": "Tordoia",
    },
    {
      "@value": "13888",
      "#text": "Torres",
    },
    {
      "@value": "13864",
      "#text": "Touriñán",
    },
    {
      "@value": "5841",
      "#text": "Touro",
    },
    {
      "@value": "13859",
      "#text": "Touzas",
    },
    {
      "@value": "13855",
      "#text": "Traba",
    },
    {
      "@value": "13845",
      "#text": "Trabe",
    },
    {
      "@value": "13844",
      "#text": "Trabes",
    },
    {
      "@value": "13834",
      "#text": "Trasancos",
    },
    {
      "@value": "13833",
      "#text": "Trasanquelos",
    },
    {
      "@value": "13820",
      "#text": "Trasmonte",
    },
    {
      "@value": "13809",
      "#text": "Trasufre",
    },
    {
      "@value": "13805",
      "#text": "Travesas",
    },
    {
      "@value": "13802",
      "#text": "Traviesas",
    },
    {
      "@value": "5840",
      "#text": "Trazo",
    },
    {
      "@value": "13784",
      "#text": "Treos",
    },
    {
      "@value": "13759",
      "#text": "Triñanes",
    },
    {
      "@value": "13748",
      "#text": "Trobe",
    },
    {
      "@value": "13745",
      "#text": "Troitosende",
    },
    {
      "@value": "13744",
      "#text": "Tronceda",
    },
    {
      "@value": "13727",
      "#text": "Tufiones",
    },
    {
      "@value": "13716",
      "#text": "Turces",
    },
    {
      "@value": "13672",
      "#text": "Uhía",
    },
    {
      "@value": "13632",
      "#text": "Urdilde",
    },
    {
      "@value": "13629",
      "#text": "Ures",
    },
    {
      "@value": "13568",
      "#text": "Vaamonde",
    },
    {
      "@value": "13559",
      "#text": "Val",
    },
    {
      "@value": "5838",
      "#text": "Val do Dubra",
    },
    {
      "@value": "5839",
      "#text": "Valdoviño",
    },
    {
      "@value": "13418",
      "#text": "Valencia",
    },
    {
      "@value": "13402",
      "#text": "Valiña",
    },
    {
      "@value": "13398",
      "#text": "Valladares",
    },
    {
      "@value": "25826",
      "#text": "Valón",
    },
    {
      "@value": "13292",
      "#text": "Varelas",
    },
    {
      "@value": "5837",
      "#text": "Vedra",
    },
    {
      "@value": "13237",
      "#text": "Veiga",
    },
    {
      "@value": "13233",
      "#text": "Veigue",
    },
    {
      "@value": "13232",
      "#text": "Veira",
    },
    {
      "@value": "13189",
      "#text": "Ventín",
    },
    {
      "@value": "13165",
      "#text": "Verdes",
    },
    {
      "@value": "13157",
      "#text": "Verines",
    },
    {
      "@value": "13143",
      "#text": "Viceso",
    },
    {
      "@value": "13119",
      "#text": "Viduido",
    },
    {
      "@value": "13103",
      "#text": "Vigo",
    },
    {
      "@value": "13100",
      "#text": "Viján",
    },
    {
      "@value": "13097",
      "#text": "Vila",
    },
    {
      "@value": "13095",
      "#text": "Vilaboa",
    },
    {
      "@value": "13092",
      "#text": "Vilachá",
    },
    {
      "@value": "13089",
      "#text": "Vilacoba",
    },
    {
      "@value": "13086",
      "#text": "Vilacova",
    },
    {
      "@value": "12943",
      "#text": "Viladavil",
    },
    {
      "@value": "13081",
      "#text": "Viladomonte",
    },
    {
      "@value": "13080",
      "#text": "Viladonelle",
    },
    {
      "@value": "12918",
      "#text": "Vilaestévez",
    },
    {
      "@value": "12752",
      "#text": "Vilamor",
    },
    {
      "@value": "13066",
      "#text": "Vilaño",
    },
    {
      "@value": "13065",
      "#text": "Vilanova",
    },
    {
      "@value": "12723",
      "#text": "Vilantime",
    },
    {
      "@value": "13057",
      "#text": "Vilar",
    },
    {
      "@value": "13051",
      "#text": "Vilar de Abajo",
    },
    {
      "@value": "13048",
      "#text": "Vilar de Céltigos",
    },
    {
      "@value": "13047",
      "#text": "Vilar de Costa",
    },
    {
      "@value": "13025",
      "#text": "Vilar Ramiro",
    },
    {
      "@value": "13055",
      "#text": "Vilarbello de Sujo",
    },
    {
      "@value": "13053",
      "#text": "Vilarcovo",
    },
    {
      "@value": "13028",
      "#text": "Vilariño",
    },
    {
      "@value": "5835",
      "#text": "Vilarmaior",
    },
    {
      "@value": "12714",
      "#text": "Vilarnovo",
    },
    {
      "@value": "12475",
      "#text": "Vilarraso",
    },
    {
      "@value": "5836",
      "#text": "Vilasantar",
    },
    {
      "@value": "13019",
      "#text": "Vilaseco",
    },
    {
      "@value": "13012",
      "#text": "Vilavella",
    },
    {
      "@value": "13004",
      "#text": "Vilela",
    },
    {
      "@value": "12945",
      "#text": "Villadabad",
    },
    {
      "@value": "12781",
      "#text": "Villamaior",
    },
    {
      "@value": "12786",
      "#text": "Villamateo",
    },
    {
      "@value": "12782",
      "#text": "Villamayor",
    },
    {
      "@value": "12743",
      "#text": "Villamorel",
    },
    {
      "@value": "12711",
      "#text": "Villanueva",
    },
    {
      "@value": "12629",
      "#text": "Villar",
    },
    {
      "@value": "12504",
      "#text": "Villarmayor",
    },
    {
      "@value": "12496",
      "#text": "Villarmid",
    },
    {
      "@value": "12466",
      "#text": "Villarromarís",
    },
    {
      "@value": "12464",
      "#text": "Villarrube",
    },
    {
      "@value": "12424",
      "#text": "Villastose",
    },
    {
      "@value": "12344",
      "#text": "Villestro",
    },
    {
      "@value": "12317",
      "#text": "Villozás",
    },
    {
      "@value": "12308",
      "#text": "Vilouchada",
    },
    {
      "@value": "12307",
      "#text": "Vilouriz",
    },
    {
      "@value": "5834",
      "#text": "Vimianzo",
    },
    {
      "@value": "12298",
      "#text": "Viña",
    },
    {
      "@value": "12293",
      "#text": "Viñas",
    },
    {
      "@value": "12283",
      "#text": "Viñós",
    },
    {
      "@value": "12275",
      "#text": "Viojo",
    },
    {
      "@value": "12274",
      "#text": "Viones",
    },
    {
      "@value": "12270",
      "#text": "Viqueira",
    },
    {
      "@value": "12266",
      "#text": "Viro",
    },
    {
      "@value": "12261",
      "#text": "Visantoña",
    },
    {
      "@value": "12259",
      "#text": "Viseo",
    },
    {
      "@value": "12258",
      "#text": "Visma",
    },
    {
      "@value": "12251",
      "#text": "Vistavós",
    },
    {
      "@value": "12247",
      "#text": "Vitiriz",
    },
    {
      "@value": "12245",
      "#text": "Vitre",
    },
    {
      "@value": "12238",
      "#text": "Vivente",
    },
    {
      "@value": "13099",
      "#text": "Vixoi",
    },
    {
      "@value": "12228",
      "#text": "Vizoño",
    },
    {
      "@value": "19695",
      "#text": "Xallas de Abaixo",
    },
    {
      "@value": "19684",
      "#text": "Xavestre",
    },
    {
      "@value": "20246",
      "#text": "Xende",
    },
    {
      "@value": "12222",
      "#text": "Xerne",
    },
    {
      "@value": "20234",
      "#text": "Xesteda",
    },
    {
      "@value": "24311",
      "#text": "Xubia",
    },
    {
      "@value": "19999",
      "#text": "Xures",
    },
    {
      "@value": "12170",
      "#text": "Zapatal",
    },
    {
      "@value": "5833",
      "#text": "Zas",
    },
    {
      "@value": "12153",
      "#text": "Zas de Rei",
    },
  ],
};
