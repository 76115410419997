import { createContext } from 'react';

type Props = {
  isFbLoaded: boolean;
  setUser: (name: string) => void;
  setProperty: (name: string, val: string) => void;
  logEvent: (name: string, params?: any) => void;
  setScreen: (name: string, val?: string) => void;
  logScreenView: (screen: string) => void;
};
const FirebaseAnalyticsContext = createContext<Props>({
  isFbLoaded: false,
  setUser: () => {},
  setProperty: () => {},
  logEvent: () => {},
  setScreen: () => {},
  logScreenView: () => {},
});

export default FirebaseAnalyticsContext;
