import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonModal,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import styles from "./quiz.module.scss";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gameActions } from "../../_redux/actions/game.actions";
import { partidaActions } from "../../_redux/actions/partida.actions";
import {
  DataGame,
  GameState,
  RankingOnlineRequest,
} from "../../_redux/models/game.model";
import { history } from "../../_helpers/history";
import { questionTypesEnum } from "../../_redux/types/question.enum";
import { exit, globe, image, pauseCircle, play } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import useState from "react-usestateref";
import {
  Bannerinterstitial,
  delay,
  GuardarPartida,
  OperacionSaveR,
  RandomIntFromInterval,
  deshabilitarBoton
} from "../../utils/functions";
import { PartidaState } from "../../_redux/models/partida.model";
import { useSound } from "use-sound";
import CorrectoLottie from "../../components/Lotties/CorrectoLottie";
import ErrorLottie from "../../components/Lotties/ErrorLottie";
import NivelLottie from "../../components/Lotties/NivelLottie";
import { AuthState } from "../../_redux/models/auth.model";
import { gameService } from "../../_redux/services";
import { randomArray } from "../../_redux/utils/helper";
import { useFirebaseAnalytics } from "../../hooks/useFirebaseAnalytics";
import AdSense from 'react-adsense';
const Quiz: React.FC<any> = (props) => {
  const { logScreenView, setScreen } = useFirebaseAnalytics();
  useIonViewDidEnter(() => {
    logScreenView("Quiz");
    setScreen("Quiz");
  });

  const dispatch = useDispatch();

  const partida: PartidaState = useSelector((state: any) => state.partida);
  const game: GameState = useSelector((state: any) => state.game);
  const authUser: AuthState = useSelector((state: any) => state.auth);
  const [showModal, setShowModal] = useState(false);
  const [logroModal, setLogroModal] = useState(false);

  const [ayudaBoton50, setAyudaBoton50] = useState(false);
  const [ayudaBotonMasTiempo, setAyudaBotonMasTiempo] = useState(false);
  const [ayudaBotonPreguntaCorrecta, setAyudaBotonPreguntaCorrecta] =
    useState(false);
  const [ayudaBotonConservarPuntos, setAyudaBotonConservarPuntos] =
    useState(false);
  const [ayudaBotonContestar2Veces, setAyudaBotonContestar2Veces] =
    useState(false);

  const [respuesta1, setrespuesta1] = useState(false);
  const [respuesta2, setrespuesta2] = useState(false);
  const [respuesta3, setrespuesta3] = useState(false);
  const [respuesta4, setrespuesta4] = useState(false);

  const [posicion, setPosicion] = useState<number>(0);

  const [activaAyuda2Oportunidades, setActivaAyuda2Oportunidades] =
    useState(false);
  const [
    activaAyuda2NoPerderPuntosSiFalla,
    setactivaAyuda2NoPerderPuntosSiFalla,
  ] = useState(false);

  const [screengame, setScreengame] = useState(true);
  const [screengameBien, setScreengameBien] = useState(false);
  const [screengameMal, setScreengameMal] = useState(false);
  const [screengameNivel, setScreengameNivel] = useState(false);
  const [isExploding, setIsExploding] = useState(false);
  const [respuestaCorrecta, setRespuestaCorrecta] = useState("");
  const [respuestaIncorrecta, setRespuestaIncorrecta] = useState("");
  const [sonidoCorrecto] = useSound("/assets/sound/correcto.mp3", {
    volume: 1,
  });
  const [sonidoError] = useSound("/assets/sound/error.mp3", { volume: 1 });
  const [sonidoAyuda] = useSound("/assets/sound/tienda.mp3", { volume: 1 });
  const [sonidoNivel] = useSound("/assets/sound/tienda.mp3", { volume: 1 });
  const [num, setNum, numRef] = useState(1);
  const [pause, setPause] = useState(false);
  const { t } = useTranslation();
  useIonViewWillLeave(() => {
    dispatch(gameActions.PARAR_JUEGO());
    setPause(true);
    setNum(1);
    clearInterval(intervalRef.current);
  });

  let dataRanking: RankingOnlineRequest = {
    MiAppId: 0,
    TipoRanking: 0,
    Finalizado: true,
  };

  let datosGuardados: boolean = false;
  let intervalRef = useRef<number>();
  const decreaseNum = async () => {
    if (numRef.current <= 0) {
      dispatch(gameActions.PARAR_JUEGO());
      clearInterval(intervalRef.current);

      setPause(true);

      if (!datosGuardados) {
        datosGuardados = true;
        setNum(1);
        posicionRanking(partida);
        history.replace("gameOver", {
          partida: partida,
          sinTiempo: true,
        });
      }
    } else {
      setNum((prev) => prev - 0.0005);
      /*
      if(numRef.current<0.9){
        document.getElementById("tiempo").style.display = "none";  
      }else{
        document.getElementById("tiempo").style.display = "block";  

      }
      */
    }
  };

  const masTiempoHander = () => {
    setAyudaBotonMasTiempo(false);
    sonidoAyuda();
    setNum(1);
  };

  const posicionRanking = async (partida: PartidaState) => {
    try {
      dataRanking.Punto = parseInt(partida.point.toFixed(0));
      const positicionActual = await gameService.PosicionRanking(dataRanking);
      if (positicionActual != null) {
        setPosicion(positicionActual);
        dispatch(partidaActions.actualizar_posicion(positicionActual));
      }
    } catch (error) {}
  };

  const ayuda50Hander = (partida: PartidaState) => {
    setAyudaBoton50(false);
    let contador = 0;

    do {
      const indexRandom = RandomIntFromInterval(0, 3);
      console.log(partida.quiz[indexRandom].N);
      console.log("aleatorio" + indexRandom);
      if (
        indexRandom === 0 &&
        respuesta1 === false &&
        partida.quiz[indexRandom].N == null
      ) {
        contador++;
        setrespuesta1(true);
      }
      if (
        indexRandom === 1 &&
        respuesta2 === false &&
        partida.quiz[indexRandom].N == null
      ) {
        contador++;
        setrespuesta2(true);
      }
      if (
        indexRandom === 2 &&
        respuesta3 === false &&
        partida.quiz[indexRandom].N == null
      ) {
        contador++;
        setrespuesta3(true);
      }
      if (
        indexRandom === 3 &&
        respuesta4 === false &&
        partida.quiz[indexRandom].N == null
      ) {
        contador++;
        setrespuesta4(true);
      }
    } while (contador <= 2);
    sonidoAyuda();
  };

  const ayudaRespuestaCorrectaHander = async () => {
    setRespuestaCorrecta(partida?.response?.N);

    sonidoAyuda();
    setAyudaBotonPreguntaCorrecta(false);

    GuardarPartida(partida);
    dispatch(
      partidaActions.aciertoQuestion(
        num * 100,
        partida.point,
        partida.aciertos,
        partida.tiempoControl,
        100 - num * 100
      )
    );
    sonidoCorrecto();
    setScreengameBien(true);
    setScreengame(false);

    if (partida.currentQuestion === partida.numberOfQuestions) {
      setPause(true);
      posicionRanking(partida);
      dispatch(partidaActions.finalizar_juego_fin_pregunta());
      localStorage.removeItem("jogo");
      history.replace("gameOver");
    }
    //Cambio de nivel
    else if (
      partida.aciertos > 4 &&
      partida.aciertos % 5 === 0 &&
      partida.level < partida.aciertos / 5 + 1
    ) {
      setNum(1);
      setPause(true);
      dispatch(partidaActions.cambio_nivel(partida.level));
      setScreengameBien(false);
      setScreengameMal(false);
      setScreengame(false);
      setScreengameNivel(true);
      sonidoNivel();
      if (ganoLogro(partida)) {
        setLogroModal(true);
      }
    } else {
      //Resto de casos al responder
      if (ganoLogro(partida)) {
        setLogroModal(true);
      }

      dispatch(
        partidaActions.proximaPregunta(
          partida.answered,
          partida.currentQuestion,
          game.cities,
          game.provinceCities
        )
      );
      setPause(true);

      await delay(2000);
      setScreengameBien(false);
      setScreengameMal(false);
      setScreengame(true);
      setNum(1);
      setPause(false);
      initTime(decreaseNum);
    }
  };

  const ayudaBotonContestar2VeceHander = () => {
    setActivaAyuda2Oportunidades(true);
    sonidoAyuda();
    setAyudaBotonContestar2Veces(false);
  };
  const ayudaConservarPuntosHander = () => {
    setactivaAyuda2NoPerderPuntosSiFalla(true);
    sonidoAyuda();
    setAyudaBotonConservarPuntos(false);
  };

  useEffect(() => {
    if (
      partida == null ||
      partida?.quiz == null ||
      partida?.currentQuestion === 0
    ) {
      //history.replace("dashboard");
      //return;
      partida.gameStart = true;
      dispatch(
        partidaActions.proximaPregunta([], 0, game.cities, game.provinceCities)
      );
      console.log("entrar");
      console.log(game);
      partida.gameStart = true;
    }
    console.log("teste2");
    //const testeState: PartidaState = useSelector((state: any) => state.partida);
    //console.log("teste state", testeState);
    console.log("usuario:", authUser);
    console.log("partida:", partida);
    // authUser.user.DatosExtendido.RecuperarPuntosPerdido = 5;
    if (
      authUser != null &&
      authUser.isAuth &&
      authUser.user.DatosExtendido != null
    ) {
      if (authUser.user.DatosExtendido.Ayuda50 > 0) {
        setAyudaBoton50(true);
      }
      if (authUser.user.DatosExtendido.RecuperarPuntosPerdido > 0) {
        setAyudaBotonConservarPuntos(true);
      }
      if (authUser.user.DatosExtendido.AyudaContestar2Veces > 0) {
        setAyudaBotonContestar2Veces(true);
      }
      if (authUser.user.DatosExtendido.AyudaMasTiempo > 0) {
        setAyudaBotonMasTiempo(true);
      }
      if (authUser.user.DatosExtendido.AyudaPreguntaCorrecta > 0) {
        setAyudaBotonPreguntaCorrecta(true);
      }
    }

    setNum(1);
    //setPause(false);
    initTime(decreaseNum);
    console.log("teste3 useEffect");
  }, []);

  const initTime = (decreaseNum) => {
    intervalRef.current = window.setInterval(decreaseNum, 15);

    return () => clearInterval(intervalRef.current);
  };

  const controlarTiempo = () => {
    if (!pause) {
      clearInterval(intervalRef.current);
    } else {
      intervalRef.current = window.setInterval(decreaseNum, 15);
    }

    setPause((prev) => !prev);
  };

  const cambioNivelContinuar = () => {
    setScreengameNivel(false);
    setScreengame(true);
    dispatch(
      partidaActions.proximaPregunta(
        partida.answered,
        partida.currentQuestion,
        game.cities,
        game.provinceCities
      )
    );
    setNum(1);
    setPause(false);
    initTime(decreaseNum);
  };

  const finalizarPartidaLocal = async (
    partida: PartidaState,
    eljuego: GameState
  ) => {
    partida.numberOfQuestions = eljuego.numberOfQuestions;

    partida.finalizarJuego = true;
    localStorage.removeItem("jogo");
    if (authUser.isAuth) {
      dataRanking.Finalizado = false;
      dataRanking.Punto = parseInt(partida.point.toFixed(0));
      dataRanking = OperacionSaveR(dataRanking, partida, authUser);
      const guardarRanking = await gameService.SetRanking(dataRanking);
      partida.id = guardarRanking;
      console.log("partida ID", partida);
    } else {
      //Guardamos la partida para luego
    }
    dispatch(gameActions.PARAR_JUEGO());
    dispatch(partidaActions.resetPatida());
    history.replace("dashboard");
  };
  const guardarPartidaLocal = async (
    partida: PartidaState,
    eljuego: GameState
  ) => {
    partida.numberOfQuestions = eljuego.numberOfQuestions;

    partida.finalizarJuego = false;
    GuardarPartida(partida);
    console.log("guardarPartidaLocal", authUser, partida);
    if (authUser.isAuth) {
      dataRanking.Finalizado = false;
      dataRanking.Punto = parseInt(partida.point.toFixed(0));
      dataRanking = OperacionSaveR(dataRanking, partida, authUser);
      const guardarRanking = await gameService.SetRanking(dataRanking);
      partida.id = guardarRanking;
      console.log("partida ID", partida);
    } else {
      //Guardamos la partida para luego
    }
    dispatch(gameActions.PARAR_JUEGO());
    history.replace("ranking");
  };

  const ganoLogro = (partida: PartidaState) => {
    return false;
  };

  function ClassicBox(props) {
    return <h1 className={styles.textoPregunta}>{game?.province.N}</h1>;
  }

  function PhotoBox(props) {
    return (
      <div className={styles.box}>
        <div className={styles.card}>
          <img
            className={styles.card_img_top}
            src={partida.response?.N}
            alt={partida.response?.A}
          />
          <div className={styles.card_autor}>
            Fotografía de {partida.response?.A}
          </div>
          <div className={styles.card_body}>{partida.response?.D}</div>
        </div>
      </div>
    );
  }

  function ComponentePregunta(props) {
    if (partida.tipo === questionTypesEnum.Classic) {
      return <ClassicBox />;
    }
    return <PhotoBox />;
  }

  const handleResponse = async (data: DataGame, partida: PartidaState) => {
    controlarTiempo();
    if (partida.tipo === questionTypesEnum.Classic) {
      setRespuestaCorrecta(partida?.response?.N);
    } else {
      setRespuestaCorrecta(partida?.response?.F);
    }

    //La respuesta es incorrecta
    if (
      (data?.N == null && partida.tipo === questionTypesEnum.Classic) ||
      (partida.tipo === questionTypesEnum.Photo &&
        partida.response?.F !== data?.N)
    ) {
      if (activaAyuda2Oportunidades) {
        if (partida.tipo === questionTypesEnum.Classic) {
          alert("la respuesta no es correcta" + data);
        } else {
          alert("la respuesta no es correcta" + data?.F);
        }
        setActivaAyuda2Oportunidades(false);
        return;
      }
      if (partida.tipo === questionTypesEnum.Classic) {
        setRespuestaIncorrecta(data + "");
      } else {
        setRespuestaIncorrecta(data?.N + "");
      }

      console.log("error", data);
      if (activaAyuda2NoPerderPuntosSiFalla) {
        //TODO, no quitamos los puntos
        setactivaAyuda2NoPerderPuntosSiFalla(false);
      } else {
        dispatch(
          partidaActions.erroresQuestion(
            num * 100,
            partida.point,
            partida.errores,
            partida.tiempoControl,
            100 - num * 100
          )
        );
      }
      setScreengameMal(true);
      setScreengame(false);
      sonidoError();
    } else {
      console.log("acierto");
      GuardarPartida(partida);
      dispatch(
        partidaActions.aciertoQuestion(
          num * 100,
          partida.point,
          partida.aciertos,
          partida.tiempoControl,
          100 - num * 100
        )
      );
      sonidoCorrecto();
      //history.replace("ok");
      setScreengameBien(true);
      setScreengame(false);
    }

    //Si estamos en la última pregunta, finalizar juego
    if (partida.currentQuestion == game.numberOfQuestions) {
      setPause(true);

      dispatch(partidaActions.finalizar_juego_fin_pregunta());
      localStorage.removeItem("jogo");
      history.replace("gameOver");
    }
    //Cambio de nivel - hay otro sitio igual, refactorizar
    else if (
      partida.aciertos > 4 &&
      partida.aciertos % 5 === 0 &&
      partida.level < partida.aciertos / 5 + 1
    ) {
      setNum(1);
      setPause(true);
      Bannerinterstitial();
      posicionRanking(partida);
      dispatch(partidaActions.cambio_nivel(partida.level));
      setScreengameBien(false);
      setScreengameMal(false);
      setScreengame(false);
      setScreengameNivel(true);
      if (ganoLogro(partida)) {
        setLogroModal(true);
      }
    } else {
      //Resto de casos al responder
      if (ganoLogro(partida)) {
        setLogroModal(true);
      }

      dispatch(
        partidaActions.proximaPregunta(
          partida.answered,
          partida.currentQuestion,
          game.cities,
          game.provinceCities
        )
      );
      setPause(true);
      setrespuesta1(false);
      setrespuesta2(false);
      setrespuesta3(false);
      setrespuesta4(false);
      setActivaAyuda2Oportunidades(false);
      setactivaAyuda2NoPerderPuntosSiFalla(false);
      await delay(2000);
      setScreengameBien(false);
      setScreengameMal(false);
      setScreengame(true);
      setNum(1);
      setPause(false);
      initTime(decreaseNum);
    }
  };

  const handleModal = () => {
    setNum((prev) => prev - 0.5);
    setShowModal(true);
  };

  useIonViewDidEnter(() => {
    /*
    console.log("useIonViewDidLeave");
    if (partida.gameStart) {
      history.push("/login");
    } else {
      dispatch(gameActions.initialGame());
    }
    

    console.log("vacio?", partida);
    if (!partida.gameStart) {
      setPause(true);
      history.push("/dashboard");
    }*/
  });

  const TimeGame = () => {
    dispatch(gameActions.timeRun(partida.time));
    TimeGame();
  };

  return (
    <IonPage>
      <IonContent>
        <IonRow>
          <IonCol
            size="12"
            size-md="9"
            className={
              styles.centro_pagina_quiz +
              " " +
              (screengameBien === true ? styles.bien : "") +
              " " +
              (screengameMal === true ? styles.mal : "")
            }
          >
            <div className={screengame === true ? styles.show : styles.hide}>
              <div className={styles.box_question}>
                <IonRow className={styles.puntuacion_quiz}>
                  <IonCol size="3" sizeMd="4">
                    <div className={styles.unir}>
                      <img
                        src="/assets/icon/level.png"
                        alt="level"
                        height={40}
                        width={40}
                      ></img>
                      <span className={styles.quiz_puntuacion}>
                        {" "}
                        {partida.level}
                      </span>
                    </div>
                  </IonCol>
                  <IonCol
                    size="4"
                    sizeMd="4"
                    className={styles.text_quiz_center}
                  >
                    <div className={styles.unir}>
                      <img
                        src="/assets/icon/tiempo.png"
                        className={styles.image}
                        alt="level"
                        id="tiempo"
                        height={20}
                        width={20}
                      ></img>
                      <span className={styles.puntosTiempo}>
                        {parseInt((num * 100).toString())}
                      </span>
                    </div>
                  </IonCol>
                  <IonCol size="5" sizeMd="4" className="text_end">
                    <div className={styles.unir}>
                      <img
                        src="/assets/icon/puntos.png"
                        alt="level"
                        height={40}
                        width={40}
                      ></img>
                      <span className={styles.quiz_puntuacion}>
                        {parseInt(partida.point.toString())}
                      </span>
                    </div>
                  </IonCol>
                </IonRow>
              </div>

              <progress value={num} className={styles.w100}></progress>

              <div className={styles.box_question}>
                <ComponentePregunta />
              </div>

              <div className={styles.caja_respuesta}>
                {partida.quiz.length > 0
                  ? partida?.quiz?.map((data, key) => {
                      return (
                        <IonButton
                          shape="round"
                          size="large"
                          disabled={deshabilitarBoton(
                            key,
                            respuesta1,
                            respuesta2,
                            respuesta3,
                            respuesta4
                          )}
                          className={styles.respuesta}
                          onClick={() => handleResponse(data, partida)}
                          key={key}
                        >
                          {data.N == null ? data : data?.N}
                        </IonButton>
                      );
                    })
                  : ""}
              </div>

              <div className={styles.caja_ayuda}>
                {ayudaBoton50 && (
                  <img
                    alt={t("help_50")}
                    src="/assets/icon/ayuda50.png"
                    onClick={() => ayuda50Hander(partida)}
                  ></img>
                )}
                {/* <img
                  className={
                    partida.tipo === questionTypesEnum.Photo
                      ? styles.showInline
                      : styles.hide
                  }
                    src="/assets/icon/wikifoto.png"
                    onClick={handleModal}
                  ></img> */}

                {ayudaBotonMasTiempo && (
                  <img
                    alt={t("help_longer")}
                    src="/assets/icon/ayudaMasTiempo.png"
                    onClick={masTiempoHander}
                  ></img>
                )}

                {ayudaBotonPreguntaCorrecta && (
                  <img
                    alt={t("help_correct_answer")}
                    src="/assets/icon/ayudaRespuestaCorrecta.png"
                    onClick={ayudaRespuestaCorrectaHander}
                  ></img>
                )}

                {ayudaBotonContestar2Veces && (
                  <img
                    alt={t("help_doble")}
                    src="/assets/icon/oportunidadDoble.png"
                    onClick={ayudaBotonContestar2VeceHander}
                  ></img>
                )}

                <img
                  alt="Wikipedia"
                  src="/assets/icon/wikifoto.png"
                  onClick={handleModal}
                ></img>

                {ayudaBotonConservarPuntos && (
                  <img
                    alt={t("help_not_to_lose_points")}
                    src="/assets/icon/ayudaRecuperarPuntos.png"
                    onClick={ayudaConservarPuntosHander}
                  ></img>
                )}
              </div>
            </div>
            <div
              className={screengameBien === true ? styles.show : styles.hide}
            >
              <div className={styles.container_center_item}>
                <CorrectoLottie />
              </div>
              <h2 className={styles.respuestaCorrecta}>
                {t("correct_answer")} {respuestaCorrecta}
              </h2>
            </div>

            <div className={screengameMal === true ? styles.show : styles.hide}>
              <h1 className={styles.respuestaIncorrecta}>
                {t("wrong_answer")} {respuestaIncorrecta}
              </h1>
              <div className={styles.container_center_item}>
                <ErrorLottie />
              </div>
              <h2 className={styles.respuestaCorrecta}>
                {t("the_correct_answer_was")} {respuestaCorrecta}
              </h2>
            </div>

            <div
              className={screengameNivel === true ? styles.show : styles.hide}
            >
              <div className={styles.container_center_item}>
                <NivelLottie />
              </div>

              <div className={styles.newlevel}>
                <img
                  src="/assets/icon/level.png"
                  alt="level"
                  height={60}
                  width={60}
                ></img>{" "}
                <span className={styles.levelChange}>
                  {" "}
                  {t("level")} {partida.level}
                </span>
                {posicion > 0 ? (
                  <p>{t("ranking_position", { posicion: posicion })}</p>
                ) : (
                  ""
                )}
              </div>

              <div className={styles.caja_boton}>
                <IonButton
                  color="danger"
                  shape="round"
                  onClick={() => finalizarPartidaLocal(partida, game)}
                >
                  {" "}
                  <IonIcon slot="start" icon={exit} />
                  {t("to_finalize")}
                </IonButton>
                <IonButton
                  color="warning"
                  shape="round"
                  onClick={() => guardarPartidaLocal(partida, game)}
                >
                  {" "}
                  <IonIcon slot="start" icon={pauseCircle} />
                  {t("save_and_exit")}
                </IonButton>

                <IonButton
                  color="success"
                  shape="round"
                  size="large"
                  className={styles.botonContinuar}
                  onClick={cambioNivelContinuar}
                >
                  <IonIcon slot="start" icon={play} />
                  {t("continue")}
                </IonButton>
              </div>
            </div>
          </IonCol>

          <IonCol
            size="12"
            size-md="3"
            className={styles.lateral_quiz}
          >
            <AdSense.Google
  client='ca-pub-8932590852481744'
  slot='9666943788'
  style={{ width: 300, height: 598, float: 'left' }}
  format=''
/>
          </IonCol>
        </IonRow>

        <IonModal isOpen={showModal} cssClass="fullscreen">
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="end">
                <IonButton onClick={() => setShowModal(false)}>
                  {" "}
                  {t("close")}
                </IonButton>
              </IonButtons>
              <IonTitle>{t("web")}</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <iframe
              title="Web"
              id="mymodal"
              src={partida.response?.W?.replace("http:", "https://")}
              className={styles.iframeStyle}
            ></iframe>
          </IonContent>
        </IonModal>

        <IonModal isOpen={logroModal} cssClass="fullscreen">
        <IonHeader>
            <IonToolbar>
              <IonButtons slot="end">
                <IonButton onClick={() => setLogroModal(false)}>
                  {" "}
                  {t("close")}
                </IonButton>
              </IonButtons>
              <IonTitle>{t("web")}</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <h1>Logro Modal - titulo</h1>
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default Quiz;


