import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonFabList,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRippleEffect,
  IonRow,
  IonTitle,
  IonToolbar,
  useIonActionSheet,
  useIonViewDidEnter,
} from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { Adsense } from "@ctrl/react-adsense";
import { history } from "../../_helpers/history";
import { gameActions, authActions } from "../../_redux/actions";
import { useEffect } from "react";
import { DataGame } from "../../_redux/models/game.model";
import { useTranslation } from "react-i18next";
import AdSense from "react-adsense";
import { MenuApp } from "../../utils/functions";
import {
  add,
  settings,
  share,
  person,
  arrowForwardCircle,
  arrowBackCircle,
  arrowUpCircle,
  logoVimeo,
  logoFacebook,
  logoInstagram,
  logoTwitter,
  logoYoutube,
  notifications,
  shareSocial,
  chevronForward,
  gameController,
  notificationsSharp,
  shareOutline,
  earth,
  apps,
  ellipsisHorizontal,
  ellipsisVertical,
  globe,
  map,
  images,
  appsSharp,
  logoAppleAppstore,
  storefront,
  storefrontSharp,
  contrastOutline,
} from "ionicons/icons";
import { MenuLateral } from "../../components/Menu/MenuLateral";
import AdsBanner from "../../components/Ads/AdsBanner";
import { useFirebaseAnalytics } from "../../hooks/useFirebaseAnalytics";
import { configData } from "../../data/configData";
import { appActions } from "../../_redux/actions/app.actions";

const Dashboard: React.FC<any> = (props) => {
  const { logScreenView, setScreen } = useFirebaseAnalytics();
  useIonViewDidEnter(() => {
    logScreenView("Dashboard");
    setScreen("Dashboard");
  });
  const notificationService = props.notificationService;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [present, dismiss] = useIonActionSheet();
  const handleProfile = () => {
    history.replace("profile");
  };

  const modoOscuro = () => {
    try {
      if(localStorage.getItem("appTheme")===""){
        dispatch(appActions.setAppTheme("dark"));
      }else{
        dispatch(appActions.setAppTheme(""));
      }
    } catch (error) {
      dispatch(appActions.setAppTheme(""));
    }
  };

  useEffect(() => {
    //  dispatch(authActions.checkAuth());
  }, []);

  const handleContinue = () => {};
  return (
    <IonPage id="page-dashboard" className="page-dashboard">
      <IonContent className="bg_app_content">
        <IonToolbar className="menu_movil_home">
          {MenuApp(present, t)}
          <IonTitle>{configData.provincia_o_estado}</IonTitle>
        </IonToolbar>
        <IonRow>
          <IonCol size="12" size-md="3" className="lateral">
            <MenuLateral></MenuLateral>
          </IonCol>
          <IonCol size="12" size-md="9" className="centro_pagina hide_pc">
            <IonFabButton
              size="small"
              onClick={handleProfile}
              className="icon_settings"
              color="light"
            >
              <IonIcon color="medium" icon={settings} />
            </IonFabButton>
            <IonFabButton
            size="small"
             onClick={modoOscuro}
             className="icon_settings "
             color="dark"
           >

             <IonIcon  icon={contrastOutline}  />
           </IonFabButton>
            <IonFabButton
              size="small"
              routerLink="store"
              className="icon_settings"
              color="warning"
            >
              <IonIcon color="danger" icon={storefrontSharp} />
            </IonFabButton>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default Dashboard;
