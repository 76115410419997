import { gameTypesEnum } from "../types";
import { gameService, jsonService } from "../services";
import {
  GameState,
  GameAction,
  DataGame,
  RankingOnlineResponse,
  RankingOnlineRequest,
} from "../models/game.model";
import { questionTypesEnum } from "../types/question.enum";
import { env } from "process";
import { history } from "../../_helpers/history";
import { AuthState } from "../models/auth.model";

//Game se usa para cargas las preguntas
//GameCurso en curso, para guardar los datos de la partida
const gameRequest = (): GameAction => {
  return {
    type: gameTypesEnum.REQUEST,
  };
};

const QuitarIsBusy = (): GameAction => {
  return {
    type: gameTypesEnum.QUITAR_ISBusy,
  };
};
const IsBusy = (): GameAction => {
  return {
    type: gameTypesEnum.IS_BUSY,
  };
};

const errorRequest = (error: string): GameAction => {
  return {
    type: gameTypesEnum.ERROR,
    error: error,
  };
};

const provinceLoader = (province: DataGame): GameAction => {
  return {
    type: gameTypesEnum.PROVINCE,
    province: province,
  };
};

const questionLoader = (cities: DataGame[]): GameAction => {
  return {
    type: gameTypesEnum.LOAD_QUESTION,
    provinceCities: cities,
    numberOfQuestions: cities.length,
  };
};

const questionPhoto = (cities: DataGame[]): GameAction => {
  return {
    type: gameTypesEnum.LOAD_IMAGE,
    provinceCities: cities,
    numberOfQuestions: cities.length,
  };
};

const citiesLoader = (cities: DataGame[]): GameAction => {
  return {
    type: gameTypesEnum.CITIES_OTHER,
    cities: cities,
  };
};

const timeRun = (time: number): GameAction => {
  return {
    type: gameTypesEnum.TIME_GAME_RUN,
  };
};

const newQuestion = (
  question: DataGame,
  answered: string[],
  currentQuestion: number,
  quiz: DataGame[]
): GameAction => {
  return {
    type: gameTypesEnum.NEXT_QUESTION,
    response: question,
  };
};

const firtQuestion = (
  question: DataGame,
  answered: string[],
  currentQuestion: number,
  quiz: DataGame[]
): GameAction => {
  return {
    type: gameTypesEnum.START_GAME,
    response: question,
  };
};

const PARAR_JUEGO = (): GameAction => {
  return {
    type: gameTypesEnum.PAUSAR_JUEGO
  };
};

const initialGame = () => {
  return async (dispatch: any): Promise<any> => {
    dispatch(gameRequest());
    try {
      
      const province: DataGame = await jsonService.ProvinceGameService();
      dispatch(provinceLoader(province));

      const question: DataGame[] = await jsonService.QuestionGameService();
      question.forEach((element) => {
        element.T = questionTypesEnum.Classic;
      });

      //dispatch(questionLoader(question));

      const otherCities: DataGame[] = await jsonService.OtherCities(question);
      dispatch(citiesLoader(otherCities));

      dispatch(questionPhoto(question));
       

    } catch (e) {
      dispatch(errorRequest(e.message));
    }
  };
};

const cargarFotos = () => {
  return async (dispatch: any): Promise<any> => {
    dispatch(gameRequest());
    try {
     const question: DataGame[] = await jsonService.QuestionGameService();
     

     //const question: DataGame[] = [] ;
      question.forEach((element) => {
        element.T = questionTypesEnum.Classic;
      });

      //SEPARAR LA PARTE DE FOTOS
      const photoProvince: DataGame[] = await gameService.getPhotoProvince();
     console.log("recuperando las fotos",photoProvince);
      if(photoProvince==null || photoProvince.length==0){
        throw new TypeError("No se puede cargar las fotos");
      }
      photoProvince.forEach((element) => {
        element.T = questionTypesEnum.Photo;
        element.N =
          process.env.REACT_APP_URL_FOTO + "/" + element.I + "/" + element.N;
      });
      question.push(...photoProvince);
      dispatch(questionPhoto(question));
    } catch (e) {
      //throw new TypeError(e.message);
      dispatch(errorRequest(e.message));
    }
  };
};

const startGame = (
  answered: string[],
  currentQuestion: number,
  cities: DataGame[],
  provinceCities: DataGame[]
) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(gameRequest());
    try {
      const question: DataGame = await jsonService.NextQuestionGameService(
        answered,
        provinceCities
      );
      const quiz: DataGame[] = await jsonService.QuizService(
        cities,
        provinceCities,
        question
      );
      answered.push(question.N);
      currentQuestion++;
      dispatch(newQuestion(question, answered, currentQuestion, quiz));
    } catch (e) {
      dispatch(errorRequest(e.message));
    }
  };
};

const playGame = (
  answered: string[],
  currentQuestion: number,
  cities: DataGame[],
  provinceCities: DataGame[]
) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(gameRequest());
    try {
      const question: DataGame = await jsonService.NextQuestionGameService(
        answered,
        provinceCities
      );
      const quiz: DataGame[] = await jsonService.QuizService(
        cities,
        provinceCities,
        question
      );
      answered.push(question.N);
      currentQuestion++;
      dispatch(newQuestion(question, answered, currentQuestion, quiz));
    } catch (e) {
      dispatch(errorRequest(e.message));
    }
  };
};
const sucessRanking = (ranking: RankingOnlineResponse[]): GameAction => {
  return {
    type: gameTypesEnum.SET_LOCAL_RANKING,
    ranking: ranking,
  };
};

const setLocalRanking = () => {
  return async (dispatch: any): Promise<any> => {
    dispatch(gameRequest());
    try {
      const ranking: any = await gameService.GetRanking();

      dispatch(sucessRanking(ranking));
    } catch (e) {
      dispatch(errorRequest(e.message));
    }
  };
};

const ListCity = () => {
  return async (dispatch: any): Promise<any> => {
    try {
      const question: DataGame[] = await jsonService.QuestionGameService();
    } catch (e) {
      dispatch(errorRequest(e.message));
    }
  };
};

const setRankingOnline = (data: RankingOnlineRequest) => {
  return async (dispatch: any): Promise<any> => {
    dispatch(gameRequest());
    try {
      await gameService.SetRanking(data);
      history.replace("ranking");
    } catch (e) {
      console.log(e);
      dispatch(errorRequest(e.message));
    }
  };
};

export const gameActions = {
  initialGame,
  playGame,
  startGame,
  timeRun,
  setLocalRanking,
  setRankingOnline,
  ListCity,
  cargarFotos,
  PARAR_JUEGO,
  QuitarIsBusy,
  IsBusy
};
