import {AppAction, AppState} from '../models/app.model';
import { appTypes } from '../types';

const initialState: AppState = {
  theme: '',
  localSettings: 'en',
  cookiePreferences: true
}

export const appReducer = (
    state: AppState = initialState,
    action: AppAction,
  ): any => {
    switch (action.type) {
      case appTypes.REQUEST:
        return {
          ...state,
          ...{ error: null, isLoading: true },
        };
      case appTypes.SET_THEME:
        return {
          ...state,
          ...{ theme: action.theme },
        };
     
      case appTypes.FAILURE_REQUEST:
        return {
          ...state,
          ...{ isLoading: false, error: action.error },
        };
      default:
        return state;
    }
  };
  

  