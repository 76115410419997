import { stockData } from "../../data/stockData";
import Enumerable from "linq";
import { DataGame } from "../models/game.model";
import { randomArray } from "../utils/helper";
import { parse } from "path";
import { gameTypesEnum } from "../types";
import { questionTypesEnum } from "../types/question.enum";

const ProvinceGameService = async (): Promise<any> => {
  try {
    return Enumerable.from(stockData.Estado)
      .where((p) => p.I == Number(process.env.REACT_APP_PROVINCE))
      .first();
  } catch (error) {
    console.error("--- ERROR: ", error.response.data);
    throw new TypeError(error.message);
  }
};

const NextQuestionGameService = async (answered: string[], questionProvince: DataGame[]): Promise<any> => {
  try {
    const question = Enumerable.from(questionProvince)
      .where((p) => !answered.includes(p.N))
      .orderBy((o) => o.N)
      .toArray();
    const random = randomArray(question);
    return Enumerable.from(random).first();
  } catch (error) {
    console.error("--- ERROR: ", error.response.data);
    throw new TypeError(error.message);
  }
};

const QuestionLocalGameService = async (answered: string[]): Promise<any> => {
  try {
    const question = Enumerable.from(stockData.Ciudad)
      .where((p) => p.I == Number(process.env.REACT_APP_PROVINCE) && !answered.includes(p.N))
      .orderBy((o) => o.N)
      .toArray();
    const random = randomArray(question);
    return Enumerable.from(random).first();
  } catch (error) {
    console.error("--- ERROR: ", error.response.data);
    throw new TypeError(error.message);
  }
};

const OtherCities = async (questionProvince: DataGame[]): Promise<any> => {
  try {
    const cities = Enumerable.from(stockData.Ciudad)
      .where((p) => p.I != Number(process.env.REACT_APP_PROVINCE))
      .toArray();
    const citiesFilter = Enumerable.from(cities)
      .select((p) => p.N)
      .distinct()
      .toArray();

    const questionFilter = Enumerable.from(questionProvince)
      .select((p) => p.N)
      .toArray();

    return Enumerable.from(citiesFilter).except(questionFilter).toArray();
  } catch (error) {
    console.error("--- ERROR: ", error.response.data);
    throw new TypeError(error.message);
  }
};

const QuestionGameService = async (): Promise<any> => {
  try {
    return Enumerable.from(stockData.Ciudad)
      .where((p) => p.I == Number(process.env.REACT_APP_PROVINCE))
      .orderBy((o) => o.N)
     // .take(5)//TODO, eliminar luego, es solo para probar con menas preguntas
      .toArray();
  } catch (error) {
    console.error("--- ERROR: ", error.response.data);
    throw new TypeError(error.message);
  }
};

const QuizService = async (
  cities: DataGame[],
  provinceCities : DataGame[],
  question: DataGame
): Promise<any> => {
  try {
    randomArray(cities);
    
    const quizOption = question.T== questionTypesEnum.Classic ? Enumerable.from(cities).take(4).toArray() : Enumerable.from(provinceCities).where(p=> p.N!=question.N).take(4).toArray();
    
    if( question.T== questionTypesEnum.Photo){
        console.log(quizOption);
        quizOption[0].N = question.F;
    }else{
      quizOption[0] = question;
    }
   
    return randomArray(quizOption);
  } catch (error) {
    console.error("--- ERROR: ", error.response.data);
    throw new TypeError(error.message);
  }
};

export const jsonService = {
  ProvinceGameService,
  NextQuestionGameService,
  QuestionGameService,
  OtherCities,
  QuizService,
};
