import { authTypes } from "../types";
import {
   MyUser,
   AuthState,
   AuthAction
} from "../models/auth.model";

const initialState = {
   error: null,
   isLoading: false,
   isAuth: false,
   user:null
 };
 
 export const authReducer = (
   state: AuthState = initialState,
   action: AuthAction
 ): any => {
   switch (action.type) {
     case authTypes.REQUEST:
       return {
         ...state,
         ...{ error: null, isLoading: true },
       };
       case authTypes.AUTH_INVITATION:
       return {
         ...state,
         ...{ error: null, isLoading: false , isAuth : false , user : action.user },
       };
       case authTypes.AUTH_SUCCESS:
         return {
           ...state,
           ...{ error: null, isLoading: false , isAuth : true , user : action.user },
         };
         case authTypes.FAILURE:
           return {
             ...state,
             ...{ error: action.error, isLoading: false , isAuth : false , user : action.user},
           };
           case authTypes.ERROR:
            return {
              ...state,
              ...{ error: action.error},
            };
         case authTypes.REFRESH_TOKEN:
           return {
             ...state,
             ...{ error: null, isLoading: false , isAuth : true , user : action.user  },
           };
           case authTypes.LOGOUT:
            return {
              ...state,
              ...{ error: null, isLoading: false , isAuth : false , user : null  },
            };
     default:
       return state;
   }
 };
 

