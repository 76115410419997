import {AppAction, CookiePreferences,  localSettings} from '../models/app.model';
import { cookiesServices } from '../services/cookies.services';
import { appTypes } from '../types';


const setAppTheme = (appTheme: string): any => {
    localStorage.setItem('appTheme',appTheme);
    return { type: appTypes.SET_THEME, theme: appTheme };
};

const appRequest = (): AppAction => {
    return {
      type: appTypes.REQUEST,
    };
  };

const getAppSettings = () => {
    return async (dispatch: any): Promise<any> => {
      try {
        dispatch(appRequest());
  
        const theme = localStorage.getItem('appTheme');
        if (theme) dispatch(setAppTheme(theme));
      } catch (e) {
        console.log("error",e);
      }
    };
  };

  const setLocalSettings = (localSettings: localSettings): any => {
    return {
      type: appTypes.SET_LOCAL_SETTINGS,
      localSettings: localSettings,
    };
  };
  
  const setCookiePreferences = (cookiePreferences: CookiePreferences): any => {
    return {
      type: appTypes.SET_COOKIE_PREFERENCES,
      cookiePreferences: cookiePreferences,
    };
  };

  const loadCookiePreferences = (cookiesAuth: boolean) => {
    return async (dispatch: any): Promise<any> => {
      try {
        cookiesServices.saveCookiePreference(cookiesAuth);
        dispatch(setCookiePreferences({ ga_: cookiesAuth }));
      } catch (e) {
        //dispatch(fetchAppFailure(e.message));
      }
    };
  };
  

  export const appActions = {
    setAppTheme,
    getAppSettings
  };