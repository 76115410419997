import { useEffect, useState } from "react";
import { Redirect, Route } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { IonApp, IonRouterOutlet, IonToast } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

import { authActions, gameActions } from "./_redux/actions";
import NotificationService from "./_redux/services/notifications.service";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

import { history } from "./_helpers/history";
/* Theme variables */
import "../src/app.scss";
import Test from "./pages/Test/Test";
import Login from "./pages/Login/Login";
import PrivateRoute from "./components/Base/PrivateRoute";
import { AppStore } from "./_redux/models/app.model";
import Dashboard from "./pages/Dashboard/Dashboard";
import Profile from "./pages/Profile/Profile";
import Quiz from "./pages/Quiz/Quiz";
import Ranking from "./pages/Ranking/Ranking";
import City from "./pages/City/City";
import EndGame from "./pages/EndGame/EndGame";
import AudioPlayer from "./components/Base/AudioPlayer";
import Register from "./pages/Register/Register";
import Correcto from "./pages/Quiz/Correcto";
import Estilo from "./pages/Test/Estilo";
import Photo from "./pages/Photo/Photo";
import Apps from "./pages/Apps/Apps";
import { gameService } from "./_redux/services";
import CityDetail from "./pages/CityDetail/CityDetail";
import { AuthStore } from "./_redux/models/auth.model";
import { GameState } from "./_redux/models/game.model";
import { appActions } from "./_redux/actions/app.actions";
import { GameAction } from "./_redux/models/game.model";
import LoaderLottie from "./components/Lotties/LoaderLottie";
import AdsBanner from "./components/Ads/AdsBanner";
import Store from "./pages/Store/Store";

const App: React.FC<any> = () => {
  const dispatch = useDispatch();
  const notificationService: NotificationService = new NotificationService();
  const myUser = useSelector((store: AuthStore) => store.auth);
  const appTheme = useSelector((state: AppStore) => state.app.theme);
  const [error, setError] = useState<string>();
  const game: GameState = useSelector((state: any) => state.game);

  useEffect(() => {
    initservice();
  }, []);

  const initservice = async () => {
    try {
      dispatch(gameActions.IsBusy());
      dispatch(appActions.getAppSettings());
      if(localStorage.getItem("appTheme")==="dark"){
        dispatch(appActions.setAppTheme("dark"));
      }
      const data = gameService.getAccount();
      dispatch(gameActions.initialGame());
      dispatch(gameActions.cargarFotos());
      //dispatch(authActions.checkAuth());
      //ACTUALIZAR PROFILE TAMBIEN AQUI - TODO
      if (data !== null) {
        gameService.MiToken();

        console.log("paso 1", data);

        if (data !== null && data.Username !== null) {
          console.log();
          console.log("paso 2", window.location);
          if (!window.location.pathname.includes("/login")) {
            console.log("paso 9 - login sin navegacion");
            dispatch(authActions.loginPekeSinNavegacion(data));
          } else {
            console.log("paso 7 - login con navegacion");
            dispatch(authActions.loginPeke(data));
          }
        } else {
          console.log("paso 3");
          const cuenta_invitado = gameService.getAccountInvitado();
          if (cuenta_invitado !== null) {
            console.log("paso 4");
            alert("cuenta_invitado");
            dispatch(authActions.cargarDatosSinNavegacion(cuenta_invitado));
          } else {
            console.log("paso 5");
            dispatch(authActions.loginInvitationArrancarApp());
          }
        }
      } else {
        console.log("paso 6");
        dispatch(authActions.loginInvitationArrancarApp());
      }
      dispatch(gameActions.QuitarIsBusy());
    } catch (error) {
      dispatch(gameActions.QuitarIsBusy());
      console.log("paso 7");
      console.log("errpr aqui");
      setError("entro aqui");
    }
  };

  return (
    <IonApp className={appTheme === "dark" ? "dark-theme" : ""}>
      <IonReactRouter history={history}>
        <IonRouterOutlet>
          <Route exact path="/profile">
            <Profile />
          </Route>

          <Route exact path="/estilo">
            <Estilo />
          </Route>

          <Route exact path="/ranking">
            <Ranking />
          </Route>

          <Route exact path="/cities">
            <City />
          </Route>

          <Route exact path="/gameOver">
            <EndGame />
          </Route>

          <Route exact path="/apps">
            <Apps />
          </Route>

          <Route exact path="/dashboard">
            <Dashboard />
          </Route>
          
          <Route exact path="/quiz">
            <Quiz />
          </Route>

          <Route exact path="/cityDetail/*">
            <CityDetail />
          </Route>

          <Route exact path="/photo">
            <Photo />
          </Route>
          <Route exact path="/login">
            <Login notificationService={notificationService} />
          </Route>
          <Route exact path="/test">
            <Test />
          </Route>
          <Route exact path="/">
            <Redirect to="/login" />
          </Route>

          <Route exact path="/register">
            <Register />
          </Route>

          <Route exact path="/store">
            <Store />
          </Route>

          <Route exact path="/ok">
            <Correcto />
          </Route>
        </IonRouterOutlet>
      </IonReactRouter>
      <IonToast
        isOpen={!!game.error}
        color="warning"
        message={game.error}
        duration={2000}
        position="top"
      />

      <IonToast
        isOpen={!!myUser.error}
        color="warning"
        message={myUser.error}
        duration={2000}
        position="top"
      />
      {game.isLoading ? (
        <div className="cargando">
          <LoaderLottie></LoaderLottie>
        </div>
      ) : (
        ""
      )}
      <AdsBanner></AdsBanner>
    </IonApp>
  );
};

export default App;
