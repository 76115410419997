import { IonButton, IonButtons, IonIcon, isPlatform } from "@ionic/react";
import { App } from "@capacitor/app";
import packageJson from "../../package.json";
import { GameState, RankingOnlineRequest } from "../_redux/models/game.model";
import { AuthState, MyUser } from "../_redux/models/auth.model";
import { useSelector } from "react-redux";
import { PartidaState } from "../_redux/models/partida.model";
import { Base64, decode } from "js-base64";
import { AdMob, RewardAdOptions, AdLoadInfo, RewardAdPluginEvents, AdMobRewardItem, AdOptions, InterstitialAdPluginEvents, AdMobBannerSize, BannerAdOptions, BannerAdPluginEvents, BannerAdPosition, BannerAdSize } from '@capacitor-community/admob';
import { apps, ellipsisHorizontal, ellipsisVertical, images, map, settings } from "ionicons/icons";
import { history } from "../_helpers/history";

export const isToday = (dateToCheck) => {
  const today = new Date();
  const date = new Date(dateToCheck);
  return (
    date.getDate() == today.getDate() &&
    date.getMonth() == today.getMonth() &&
    date.getFullYear() == today.getFullYear()
  );
};

export const currentTime = () => {
  const today = new Date();
  return dateTimeFormat(today);
};

export const dateTimeFormat = (date) => {
  const hours = (date.getHours() < 10 ? "0" : "") + date.getHours();
  const minutes = (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
  return `${hours}:${minutes}`;
};

export const diffMsDates = (dateIni, dateEnd, minutes) => {
  dateIni.setMinutes(dateIni.getMinutes() - minutes);
  const diffMs = dateIni - dateEnd;
  return diffMs;
};

export const isEmpty = (obj: any): boolean => {
  return obj == null || Object.keys(obj).length === 0;
};

export const getVersion = async () => {
  if (isPlatform("capacitor")) {
    const info = await App.getInfo();
    try {
      return ` ${info.name}-${info.id}-${info.build}-${info.version}`;
    } catch (error) {
      console.log("ERROR", error);
    }
  } else {
    return ` ${packageJson.name} - ${packageJson.version}`;
  }
};

export const groupBy = (array: any[], property: string) => {
  return array.reduce((r, a) => {
    r[a[property]] = r[a[property]] || [];
    r[a[property]].push(a);
    return r;
  }, Object.create(null));
};

//Vamos a guardar o ranking
export const OperacionSaveR = (
  dataRankingSave: RankingOnlineRequest,
  partida: PartidaState,
  AuthUser: AuthState
) => {
  dataRankingSave.Correcta = partida.aciertos;
  dataRankingSave.Incorrecta = partida.errores;
  dataRankingSave.Nivel = partida.level;
  //dataRankingSave.Punto = partida.point;
  dataRankingSave.UserId = AuthUser.user.Id;
  dataRankingSave.Finalizado = partida.finalizarJuego;
  if (partida.finalizarJuego == null) {
    partida.finalizarJuego = false;
  } else {
    partida.finalizarJuego = partida.finalizarJuego;
  }
  if (partida?.id != null) {
    dataRankingSave.Id = partida.id;
  } else {
    dataRankingSave.Id = -1;
  }
  dataRankingSave.MiAppId = parseInt(process.env.REACT_APP_MIAPPID);
  dataRankingSave.Aux = Ordenar(dataRankingSave, partida);
  dataRankingSave.MiAppId = 100;
  return dataRankingSave;
};

//
export const Ordenar = (
  dataRankingSave: RankingOnlineRequest,
  partida: PartidaState
) => {
  let meuDatos: string = Date.now().toString() + "|";
  meuDatos += dataRankingSave.UserId + "|";
  meuDatos += dataRankingSave.Correcta + "|";
  meuDatos += dataRankingSave.Incorrecta + "|";
  meuDatos += dataRankingSave.MiAppId + "|";
  meuDatos += dataRankingSave.Nivel + "|";
  meuDatos += dataRankingSave.Punto + "|";
  meuDatos += dataRankingSave.TipoRanking + "|";
  meuDatos += dataRankingSave.Finalizado + "|";
  meuDatos += partida.tiempoControl + "|";
  meuDatos += partida.currentQuestion + "|";
  meuDatos += dataRankingSave.Id + "|";
  meuDatos += "0"; //numero máximo de puntuación
  return filtrarMeuDatos(meuDatos);
};

export const OrdenarLoja = (
  loja: MyUser ,
) => {
  let meuDatos: string = Date.now().toString() + "|";
  meuDatos += loja.DatosExtendido.Ayuda50 + "|";
  meuDatos += loja.DatosExtendido.AyudaContestar2Veces + "|";
  meuDatos += loja.DatosExtendido.AyudaMasTiempo + "|";
  meuDatos += loja.DatosExtendido.AyudaPreguntaCorrecta + "|";
  meuDatos += loja.DatosExtendido.PuntosGlobales + "|";
  meuDatos += loja.aspj + "|";
  meuDatos += loja.DatosExtendido.Id + "|";
  return filtrarMeuDatos(meuDatos);
};

export const filtrarMeuDatos = (data: string) => {
  return Buffer.from(data).toString("base64");
};

export const decodeBase64 = (data: string) => {
  return Buffer.from(data, "binary").toString("base64");
};

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const GuardarPartida = (partida: PartidaState) => {
  console.log("guardamos la partida con puntos?", partida);
  localStorage.setItem("jogo", filtrarMeuDatos(JSON.stringify(partida)));
};

export const RecuperarPartida = () => {
  let midecode = localStorage.getItem("jogo");
  if (midecode === null) {
    return null;
  }

  midecode = Base64.decode(midecode);

  return JSON.parse(midecode);
};

export const VerificarSiTocaLogro = (partida: PartidaState) => {
  //verifica App de la biblia para ver como hacer los logros.
};

export const CambioDeNivel = (partida: PartidaState) => {
  //verifica App de la biblia para ver como hacer los logros.
};

export const RandomIntFromInterval = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};



export async function Bannerinterstitial(): Promise<void> {
  AdMob.addListener(InterstitialAdPluginEvents.Loaded, (info: AdLoadInfo) => {
    // Subscribe prepared interstitial
  });

  const options: AdOptions = {
    adId: 'ca-app-pub-3940256099942544/4411468910',
    // isTesting: true
    // npa: true
  };
  await AdMob.prepareInterstitial(options);
  await AdMob.showInterstitial();
}


export function MenuApp(present, t) {
  return <IonButtons slot="primary">
    <IonButton
      color="secondary"
      onClick={() => present({
        buttons: [
          {
            text: t("tourist_points"),
            icon: images,
            handler: () => history.push("photo"),
          },
          {
            text: t("cities"),
            icon: map,
            handler: () => history.push("cities"),
          },

          {
            text: t("other_apps"),
            icon: apps,
            handler: () => history.push("apps"),
          },
          {
            text: t("settings"),
            icon: settings,
            handler: () => history.push("profile"),
          },
        ],
        header: t("app_options"),
      })}
    >
      <IonIcon
        slot="icon-only"
        ios={ellipsisHorizontal}
        md={ellipsisVertical} />
    </IonButton>
  </IonButtons>;
}

export function deshabilitarBoton(
  key: number,
  respuesta1: boolean,
  respuesta2: boolean,
  respuesta3: boolean,
  respuesta4: boolean
): boolean {
  switch (key) {
    case 0:
      return respuesta1;
    case 1:
      return respuesta2;
    case 2:
      return respuesta3;
    case 3:
      return respuesta4;
    default:
      return false;
  }
}