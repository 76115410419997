import {
  AdMob,
  BannerAdOptions,
  BannerAdPluginEvents,
  BannerAdPosition,
  BannerAdSize,
} from "@capacitor-community/admob";
import { Capacitor } from "@capacitor/core";
import { isPlatform } from "@ionic/react";
import React, { useState } from "react";

export const AdsBanner: React.FC<any> = (props) => {
  const [bannerLocal, setBannerLocal] = useState<Boolean>(false);
  if (Capacitor.isNativePlatform()) {
    AdMob.initialize({
      requestTrackingAuthorization: true,
    });

    AdMob.addListener(BannerAdPluginEvents.FailedToLoad, (info) => {
      if (!bannerLocal) {
        setBannerLocal(true);
      }
      console.log("error banner", info);
    });

    const options: BannerAdOptions = {
      adId: isPlatform("android")
        ? "ca-app-pub-8932590852481744/2197587920"
        : "ca-app-pub-8932590852481744/4891996037",
      adSize: BannerAdSize.BANNER,
      position: BannerAdPosition.BOTTOM_CENTER,
      margin: 0
      // npa: true
    };
    AdMob.showBanner(options);
  } else {
    if (!bannerLocal) {
      //setBannerLocal(true);
    }
  }

  return (
    // <div className="banner">

    //   <a href="https://www.pekegames.app" target="_blank" rel="noreferrer" title="banner">
    //     <img  src="/assets/images/banner.gif" alt="publicidad"></img>
    //     </a>

    // </div>
    <div className={bannerLocal === true ? "bannerclasico" : ""}>
      {
            bannerLocal===true && 
            <img  src='/assets/images/banner.gif' alt='publicidad'></img>
        } 
    </div>
  );
};

export default AdsBanner;
