import React, { Component } from "react";
import Lottie from "react-lottie";
import animationData from "../../assets/images/ok.json";

class CorrectoLottie extends Component {
  state = { isStopped: false, isPaused: false };

  render() {
    const buttonStyle = {
      display: "inline-block",
      margin: "10px auto",
      marginRight: "10px",
      border: "none",
      color: "white",
      backgroundColor: "#647DFF",
      borderRadius: "2px",
      fontSize: "15px",
    };

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <div className="controlled">
        <Lottie options={defaultOptions} isStopped={false} isPaused={false}  height={400} width={400} />
      </div>
    );
  }
}

export default CorrectoLottie;
