import {
  IonContent,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonCol,
  IonGrid,
  IonRow,
  IonIcon,
} from "@ionic/react";
import { chevronBack } from "ionicons/icons";
import React from "react";
import styles from "./TuTiempo.module.scss";
import { history } from "../../_helpers/history";
import { useTranslation } from "react-i18next";

export const DatoTuTiempo: React.FC<any> = (props: any) => {
  const { t } = useTranslation();

  const BotonVolver = () => {
    history.goBack();
  };
  return (
    <IonCard>
      <IonCardHeader>
       
        <IonCardTitle>
        
           {/* <h1><IonIcon
              icon={chevronBack}
              size="large"
              className="boton_volver_detalle"
              onClick={BotonVolver}
            /> */}<h1> {t("the_time_in")} {props.data.locality.name}</h1></IonCardTitle>
            <IonCardSubtitle>{props.data.locality.country}</IonCardSubtitle>
      </IonCardHeader>

      <IonGrid>
        <IonRow>
          <IonCol  sizeLg="3" sizeXs="12" className="text_center">
            <img
              className={styles.icon_grande_tiempo}
              src={"/assets/icon/wi/" + props.data?.day1?.icon + ".png"}
              alt={props.data?.day1?.text}
            ></img>
            {props.data?.day1?.temperature_min}/
            {props.data?.day1?.temperature_max}
            <h3>{props.data?.day1?.text}</h3>
            <span className={styles.TemperaturaActual}>
              {props.data?.hour_hour?.hour2.temperature}º{" "}
            </span>
          </IonCol>
          <IonCol sizeLg="9" sizeXs="12">
            <IonRow>
              <IonCol sizeLg="4" sizeXs="6">
                <img
                  className={styles.mini_icon}
                  src="/assets/icon/sunrise.png"
                  alt="sunrise"
                ></img>
                {props.data?.day1?.sunrise}
              </IonCol>
              <IonCol sizeLg="4" sizeXs="12">
                <span className={styles.horas}>
                  {props.data?.hour_hour?.hour2.hour_data}{" "}
                </span>
                <img
                  className={styles.mini_icon}
                  src={
                    "/assets/icon/wi/" +
                    props.data?.hour_hour?.hour2.icon +
                    ".png"
                  }
                  alt="sunset"
                ></img>{" "}
                <span className={styles.temperatura_hora}>
                  {props.data?.hour_hour?.hour2.temperature}º -{" "}
                  {props.data?.hour_hour?.hour2.text}
                </span>
              </IonCol>
              <IonCol sizeLg="4" sizeXs="12">
                <span className={styles.horas}>
                  {props.data?.hour_hour?.hour10.hour_data}{" "}
                </span>
                <img
                  className={styles.mini_icon}
                  src={
                    "/assets/icon/wi/" +
                    props.data?.hour_hour?.hour10.icon +
                    ".png"
                  }
                  alt="sunset"
                ></img>{" "}
                <span className={styles.temperatura_hora}>
                  {props.data?.hour_hour?.hour10.temperature}ºC -{" "}
                  {props.data?.hour_hour?.hour10.text}
                </span>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol sizeLg="4" sizeXs="12">
                <img
                  className={styles.mini_icon}
                  src="/assets/icon/sunset.png"
                  alt="sunset"
                ></img>
                {props.data?.day1?.sunset}
              </IonCol>
              <IonCol sizeLg="4" sizeXs="12">
                <span className={styles.horas}>
                  {props.data?.hour_hour?.hour3.hour_data}{" "}
                </span>
                <img
                  className={styles.mini_icon}
                  src={
                    "/assets/icon/wi/" +
                    props.data?.hour_hour?.hour3.icon +
                    ".png"
                  }
                  alt="sunset"
                ></img>{" "}
                <span className={styles.temperatura_hora}>
                  {props.data?.hour_hour?.hour3.temperature}º -{" "}
                  {props.data?.hour_hour?.hour3.text}
                </span>
              </IonCol >
              <IonCol sizeLg="4" sizeXs="12">
                <span className={styles.horas}>
                  {props.data?.hour_hour?.hour13.hour_data}{" "}
                </span>
                <img
                  className={styles.mini_icon}
                  src={
                    "/assets/icon/wi/" +
                    props.data?.hour_hour?.hour13.icon +
                    ".png"
                  }
                  alt="sunset"
                ></img>{" "}
                <span className={styles.temperatura_hora}>
                  {props.data?.hour_hour?.hour13.temperature}º -{" "}
                  {props.data?.hour_hour?.hour13.text}
                </span>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol sizeLg="4" sizeXs="12">
                <img
                  className={styles.mini_icon}
                  src={
                    " https://v5i.tutiempo.net/wd/big/black/" + props.data?.day1?.icon_wind + ".png"
                  }
                  alt="sunset"
                ></img>
                {props.data?.day1?.wind}km/h
              </IonCol>
              <IonCol sizeLg="4" sizeXs="12">
                <span className={styles.horas}>
                  {props.data?.hour_hour?.hour4.hour_data}{" "}
                </span>
                <img
                  className={styles.mini_icon}
                  src={
                    "/assets/icon/wi/" +
                    props.data?.hour_hour?.hour4.icon +
                    ".png"
                  }
                  alt="sunset"
                ></img>{" "}
                <span className={styles.temperatura_hora}>
                  {props.data?.hour_hour?.hour4.temperature}º -{" "}
                  {props.data?.hour_hour?.hour4.text}
                </span>
              </IonCol>
              <IonCol sizeLg="4" sizeXs="12">
                <span className={styles.horas}>
                  {props.data?.hour_hour?.hour16.hour_data}{" "}
                </span>
                <img
                  className={styles.mini_icon}
                  src={
                    "/assets/icon/wi/" +
                    props.data?.hour_hour?.hour16.icon +
                    ".png"
                  }
                  alt="sunset"
                ></img>{" "}
                <span className={styles.temperatura_hora}>
                  {props.data?.hour_hour?.hour16.temperature}º -{" "}
                  {props.data?.hour_hour?.hour16.text}
                </span>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol sizeLg="4" sizeXs="12">
              <img
                  className={styles.mini_icon}
                  src="/assets/icon/humidity.png"
                  alt="humidity"
                ></img>    {props.data?.day1?.humidity}%

              </IonCol>
              <IonCol sizeLg="4" sizeXs="12">
                <span className={styles.horas}>
                  {props.data?.hour_hour?.hour5.hour_data}{" "}
                </span>
                <img
                  className={styles.mini_icon}
                  src={
                    "/assets/icon/wi/" +
                    props.data?.hour_hour?.hour5.icon +
                    ".png"
                  }
                  alt="sunset"
                ></img>{" "}
                <span className={styles.temperatura_hora}>
                  {props.data?.hour_hour?.hour5.temperature}º -{" "}
                  {props.data?.hour_hour?.hour5.text}
                </span>
              </IonCol>
              <IonCol sizeLg="4" sizeXs="12">
                <span className={styles.horas}>
                  {props.data?.hour_hour?.hour20.hour_data}{" "}
                </span>
                <img
                  className={styles.mini_icon}
                  src={
                    "/assets/icon/wi/" +
                    props.data?.hour_hour?.hour20.icon +
                    ".png"
                  }
                  alt="sunset"
                ></img>{" "}
                <span className={styles.temperatura_hora}>
                  {props.data?.hour_hour?.hour20.temperature}º -{" "}
                  {props.data?.hour_hour?.hour20.text}
                </span>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol sizeLg="4" sizeXs="12">
                <img
                  className={styles.mini_icon}
                  src={
                    "https://v5i.tutiempo.net/wmi/02/" +
                    props.data?.day1?.moon_phases_icon +
                    ".png"
                  }
                  alt="sunset"
                ></img>
                <p>Salida de la luna {props.data?.day1?.moonrise}</p>
                <p>Puesta de la luna {props.data?.day1?.moonset}</p>
        
              </IonCol>
              <IonCol sizeLg="4" sizeXs="12">
                <span className={styles.horas}>
                  {props.data?.hour_hour?.hour6.hour_data}{" "}
                </span>
                <img
                  className={styles.mini_icon}
                  src={
                    "/assets/icon/wi/" +
                    props.data?.hour_hour?.hour6.icon +
                    ".png"
                  }
                  alt="sunset"
                ></img>{" "}
                <span className={styles.temperatura_hora}>
                  {props.data?.hour_hour?.hour6.temperature}º -{" "}
                  {props.data?.hour_hour?.hour6.text}
                </span>
              </IonCol>
              <IonCol sizeLg="4" sizeXs="12">
                <span className={styles.horas}>
                  {props.data?.hour_hour?.hour25.hour_data}{" "}
                </span>
                <img
                  className={styles.mini_icon}
                  src={
                    "/assets/icon/wi/" +
                    props.data?.hour_hour?.hour25.icon +
                    ".png"
                  }
                  alt="sunset"
                ></img>{" "}
                <span className={styles.temperatura_hora}>
                  {props.data?.hour_hour?.hour25.temperature}º -{" "}
                  {props.data?.hour_hour?.hour25.text}
                </span>
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="text_center">
          <span className={styles.date_day}>{props.data?.day2?.date}</span>
            <img
              className={styles.mini_icon}
              src={"/assets/icon/wi/" + props.data?.day2?.icon + ".png"}
              alt={props.data?.day2?.text}
            ></img>
            <p>
              {props.data?.day2?.temperature_min}º/
              {props.data?.day2?.temperature_max}º
            </p>
            <p>{props.data?.day2?.text}</p>
          </IonCol>

          <IonCol className="text_center">
          <span className={styles.date_day}>{props.data?.day3?.date}</span>
            <img
              className={styles.mini_icon}
              src={"/assets/icon/wi/" + props.data?.day3?.icon + ".png"}
              alt={props.data?.day3?.text}
            ></img>
            <p>
              {props.data?.day3?.temperature_min}º/
              {props.data?.day3?.temperature_max}º
            </p>
            <p>{props.data?.day3?.text}</p>
          </IonCol>

          <IonCol className="text_center">
          <span className={styles.date_day}>{props.data?.day4?.date}</span>
            <img
              className={styles.mini_icon}
              src={"/assets/icon/wi/" + props.data?.day4?.icon + ".png"}
              alt={props.data?.day4?.text}
            ></img>
            <p>
              {props.data?.day4?.temperature_min}º/
              {props.data?.day4?.temperature_max}º
            </p>
            <p>{props.data?.day4?.text}</p>
          </IonCol>

          <IonCol className="text_center">
          <span className={styles.date_day}>{props.data?.day5?.date}</span>
            <img
              className={styles.mini_icon}
              src={"/assets/icon/wi/" + props.data?.day5?.icon + ".png"}
              alt={props.data?.day5?.text}
            ></img>
            <p>
              {props.data?.day5?.temperature_min}º/
              {props.data?.day5?.temperature_max}º
            </p>
            <p>{props.data?.day5?.text}</p>
          </IonCol>

          <IonCol className="text_center">
          <span className={styles.date_day}>{props.data?.day6?.date}</span>
            <img
              className={styles.mini_icon}
              src={"/assets/icon/wi/" + props.data?.day6?.icon + ".png"}
              alt={props.data?.day6?.text}
            ></img>
            <p>
              {props.data?.day6?.temperature_min}º/
              {props.data?.day6?.temperature_max}º
            </p>
            <p>{props.data?.day6?.text}</p>
          </IonCol>

          <IonCol className="text_center">
          <span className={styles.date_day}>{props.data?.day7?.date}</span>
            <img
              className={styles.mini_icon}
              src={"/assets/icon/wi/" + props.data?.day7.icon + ".png"}
              alt={props.data?.day7?.text}
            ></img>
            <p>
              {props.data?.day7?.temperature_min}º/
              {props.data?.day7?.temperature_max}º
            </p>
            <p>{props.data?.day7?.text}</p>
          </IonCol>


        </IonRow>
      </IonGrid>
      <a className={styles.enlaceCompany} href={props.data.locality.url_weather_forecast_15_days} rel="open" referrerPolicy="origin" target="_blank">{props.data.copyright}</a>
    </IonCard>
  );
};

export default DatoTuTiempo;
