import {
  IonAvatar,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonFabList,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
  useIonActionSheet,
  useIonViewDidEnter,
} from "@ionic/react";
import { useSelector } from "react-redux";
import { AuthStore } from "../../_redux/models/auth.model";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { authActions, gameActions } from "../../_redux/actions";
import styles from "./apps.module.scss";
import { useEffect, useState } from "react";
import { getVersion , MenuApp } from "../../utils/functions";
import { gameService } from "../../_redux/services";
import { Icon } from "@material-ui/core";
import {
  apps,
  chevronForward,
  earth,
  ellipsisHorizontal,
  ellipsisVertical,
  gameController,
  images,
  logoFacebook,
  logoInstagram,
  logoTwitter,
  logoYoutube,
  map,
  notifications,
  person,
  settings,
  share,
  shareSocial,
} from "ionicons/icons";
import { history } from "../../_helpers/history";
import MenuLateral from "../../components/Menu/MenuLateral";
import { GameState } from "../../_redux/models/game.model";
import { useFirebaseAnalytics } from "../../hooks/useFirebaseAnalytics";

const Apps: React.FC = () => {
  const { logScreenView , setScreen } = useFirebaseAnalytics();
  useIonViewDidEnter(() => {
    logScreenView('AppsListado');
    setScreen('AppsListado');
  });
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [listApps, setListApps] = useState(null);
  const [present, dismiss] = useIonActionSheet();
  useEffect(() => {
    async function fetchVersion() {
      try {
        dispatch(gameActions.IsBusy());
        const apps = await gameService.AppsPekeGames();
        setListApps(apps);
        dispatch(gameActions.QuitarIsBusy());
      } catch (error) {
        console.log(error);
        dispatch(gameActions.QuitarIsBusy());
      }
    }
    fetchVersion();
  }, []);

  return (
    <IonPage id="page-dashboard" className="page-dashboard">
      <IonContent className="bg_app_content">
        <IonToolbar className="menu_movil_home_2">
          {MenuApp(present, t)}
          <IonTitle> {t("app_options")}</IonTitle>
          <IonButtons slot="start">
            <IonBackButton defaultHref="dashboard" />
          </IonButtons>
        </IonToolbar>
        <IonRow>
          <IonCol size="12" size-md="3" className="lateral hide_pc">
            <MenuLateral></MenuLateral>
          </IonCol>
          <IonCol size="12" size-md="9" className="centro_pagina bgWhite">
            <div>
              <IonRow>
                {Array.isArray(listApps) &&
                  listApps.map((item, index) => {
                    return (
                      <IonCol size="12" size-md="6" key={index}>
                        <IonCard className="ionCard" key={index}>
                          <IonCardTitle>{item.Nombre}</IonCardTitle>
                          <img
                            src={`https://www.pekegames.app/Img/App/${item.Id}/logo512.png`}
                            alt={item.name}
                          ></img>
                          <IonCardContent>
                            {item.DescripcionCorta}
                          </IonCardContent>
                        </IonCard>
                      </IonCol>
                    );
                  })}
              </IonRow>
              <div className="margin_buttom_banner"></div>
            </div>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default Apps;


