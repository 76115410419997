import {
    ActionPerformed,
    PushNotificationSchema,
    PushNotifications,
    Token,
  } from '@capacitor/push-notifications';
  import { ReplaySubject } from 'rxjs';
  import { FCM } from '@capacitor-community/fcm';
  import { isPlatform } from '@ionic/react';
  
  export default class NotificationsService {
    private token: Token;
    public notificationReceivedEmmiter: ReplaySubject<any> = new ReplaySubject();
    public notificationactionEmmiter: ReplaySubject<any> = new ReplaySubject();
  
    public constructor() {
      if (isPlatform('capacitor')) {
        this.requestPermissions();
        PushNotifications.addListener('registration', this.registrationCallbacks);
        PushNotifications.addListener(
          'registrationError',
          this.registrationErrorCallback,
        );
        PushNotifications.addListener(
          'pushNotificationReceived',
          this.pushNotificationReceivedCallback,
        );
  
        PushNotifications.addListener(
          'pushNotificationActionPerformed',
          this.pushNotificationActionPerformedCallback,
        );
      }
    }
  
    requestPermissions = async () => {
      const permissions = await PushNotifications.requestPermissions();
      if (permissions.receive == 'granted') {
        await PushNotifications.register();
        return true;
      } else {
        return false;
      }
    };
  
    subscribeTo = async (userId: string) => {
      return await FCM.subscribeTo({ topic: userId });
    };
  
    registrationCallbacks = (token: Token) => {
      console.log('registration success with token', token);
      this.token = token;
    };
  
    registrationErrorCallback = (err: any) => {
      console.log('register error', err);
    };
  
    pushNotificationReceivedCallback = (data: PushNotificationSchema) => {
      console.log('push notification received with Data', data);
      this.notificationReceivedEmmiter.next(data);
    };
  
    pushNotificationActionPerformedCallback = (data: ActionPerformed) => {
      console.log('push notification action with Data', data);
      this.notificationactionEmmiter.next(data);
    };
  
    
  }
  