import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonIcon,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  useIonActionSheet,
  useIonViewDidEnter,
  useIonViewWillLeave,
} from "@ionic/react";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { partidaActions } from "../../_redux/actions/partida.actions";
import {
  GameState,
  RankingOnlineRequest,
} from "../../_redux/models/game.model";
import { history } from "../../_helpers/history";
import {
  apps,
  ellipsisHorizontal,
  ellipsisVertical,
  images,
  map,
  settings,
} from "ionicons/icons";
import useState from "react-usestateref";
import { AuthState } from "../../_redux/models/auth.model";
import { gameService } from "../../_redux/services";
import { delay } from "../../utils/functions";
import { PartidaState } from "../../_redux/models/partida.model";
import ConfettiExplosion from "@reonomy/react-confetti-explosion";
import PersonajeYTextoGameOver from "../../components/FinDeJuego/PersonajeYTextoGameOver";
import CrearCuenta from "../../components/Modal/CrearCuenta";
import { useFirebaseAnalytics } from "../../hooks/useFirebaseAnalytics";
import { MenuApp } from "../../utils/functions";
import { useTranslation } from "react-i18next";
const EndGame: React.FC<any> = (props) => {
  const { t } = useTranslation();
  const { logScreenView, setScreen } = useFirebaseAnalytics();
  useIonViewDidEnter(() => {
    logScreenView('EndGame');
    setScreen('EndGame');
  });

  const state = history.location.state as any;
  const dispatch = useDispatch();
  const authUser: AuthState = useSelector((state: any) => state.auth);
  const partida: PartidaState = useSelector((state: any) => state.partida);
  const [isExploding, setIsExploding] = useState(false);
  const [posicion, setPosicion] = useState<number>(0);
  let auxSinTiempo = state?.sinTiempo == null ? false : state?.sinTiempo;
  const [sinTiempo, setSinTiempo] = useState<boolean>(auxSinTiempo);
  const game: GameState = useSelector((state: any) => state.game);
  const [present, dismiss] = useIonActionSheet();
  const [loginModal, setLoginModal] = useState(false);
  let dataRanking: RankingOnlineRequest = {
    MiAppId: 0,
    TipoRanking: 0,
    Finalizado: true,
  };

  const abrirModal = () => {
    setLoginModal(true);
  };

  const cerrarModal = () => {
    setLoginModal(false);
  };

  const jugarDeNuevo = () => {
    dispatch(partidaActions.resetPatida());
    console.log("datos iniciales;", partida);
    dispatch(
      partidaActions.proximaPregunta([], 0, game.cities, game.provinceCities)
    );
    partida.gameStart = true;
    history.replace("quiz");
  };
  const posicionRanking = async (partida: PartidaState) => {
    try {
      dataRanking.Punto = parseInt(partida.point.toFixed(0));
      const positicionActual = await gameService.PosicionRanking(dataRanking);
      if (positicionActual != null) {
        setPosicion(positicionActual);
      }
    } catch (error) {}
  };

  useIonViewWillLeave(() => {
    dispatch(partidaActions.resetPatida());
    partida.gameStart = false;
    
    dispatch(
      partidaActions.proximaPregunta([], 0, game.cities, game.provinceCities)
    );
    
  });
 

  /*
  useEffect(() => {
    if (authUser.isAuth && sinTiempo && authUser.user.Id!="-1") {
      dataRanking.Finalizado = true;
      dataRanking = OperacionSaveR(dataRanking, partida, authUser);
      gameService.SetRanking(dataRanking);
      console.log("sin tiempo y logado");
      dispatch(partidaActions.resetPatida());
    } else if (sinTiempo) {
      console.log("sin tiempo y usuario anonimo");
    }
    localStorage.removeItem("jogo");
    posicionRanking(partida);
  }, []);
*/
  const handleClick = async () => {
    await delay(5000);
    setIsExploding(false);
    //history.replace("quiz");
  };
  useEffect(() => {
    if (partida.posicion < 100 && partida.point > 100) {
      setIsExploding(true);
      handleClick();
    }
  }, []);

  return (
    <IonPage id="page-dashboard">
      <IonContent className="">
        <IonToolbar className="menu_movil_2">
        {MenuApp(present, t)}
          <IonTitle>{t("game_over")}</IonTitle>
          <IonButtons slot="start">
            <IonBackButton defaultHref="dashboard" />
          </IonButtons>
        </IonToolbar>
        <IonRow>
          <IonCol size="12" size-md="12">
            <div className="padre">
              <div className="hijo">
                {isExploding ? (
                  <ConfettiExplosion
                    duration={5000}
                    floorWidth={1900}
                    floorHeight={900}
                    force={0.1}
                    particleCount={200}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>

            <PersonajeYTextoGameOver
              partida={partida}
              usuario={authUser}
              eventoAbrir={abrirModal}
            ></PersonajeYTextoGameOver>
          </IonCol>
        </IonRow>

        <CrearCuenta
          estadoModal={loginModal}
          eventoCerrar={cerrarModal}
          navegar={false}
        ></CrearCuenta>
      </IonContent>
    </IonPage>
  );
};

export default EndGame;