import React, { Component } from "react";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie";
import animationData from "../../assets/images/cargandolotties.json";
 
class LoaderLottie extends Component {
  state = { isStopped: false, isPaused: false };
 
  render() {

    const buttonStyle = {
      display: "inline-block",
      margin: "10px auto",
      marginRight: "10px",
      border: "none",
      color: "white",
      backgroundColor: "#647DFF",
      borderRadius: "2px",
      fontSize: "15px",
    };

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <div className="controlled">
        <Lottie options={defaultOptions} height={300} width={300} />
        <h1 className="cargando_titulo">  
        Loading...
        </h1>
      </div>
    );
  }
}

export default LoaderLottie;
