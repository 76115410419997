import {
  IonModal,
  IonContent,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonItem,
  IonInput,
  IonButton,
  IonIcon,
} from "@ionic/react";
import { text, closeOutline, closeCircle } from "ionicons/icons";
import React, { Component, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { OperacionSaveR } from "../../utils/functions";
import { authActions, gameActions, partidaActions } from "../../_redux/actions";
import { AuthState, MyUser } from "../../_redux/models/auth.model";
import { RankingOnlineRequest } from "../../_redux/models/game.model";
import { PartidaState } from "../../_redux/models/partida.model";
import { gameService } from "../../_redux/services";
import styles from "./crearcuenta.module.scss";
export const CrearCuenta: React.FC<any> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loginModal, setLoginModal] = useState(false);
  const [text, setText] = useState<string>();
  const [contrasena, setContrasena] = useState<string>();
  const [contrasenaConfirmar, setContrasenaConfirmar] = useState<string>();
  const [showModal, setShowModal] = useState(false);
  const [loginActive, setLoginActive] = useState<boolean>(true);
  const partida: PartidaState = useSelector((state: any) => state.partida);
  const authUser: AuthState = useSelector((state: any) => state.auth);
  const userData: MyUser = {
    Id: "-1",
    Avatar: "a0.svg",
    Nick: t("guest"),
    NuevoUsuarioEnElJuego: false,
    PaisId: 0,
    Token: "",
    DatosExtendido: null,
    Username: t("guest"),
    Password: "",
    Auth: false,
  };

  let dataRanking: RankingOnlineRequest = {
    MiAppId: 0,
    TipoRanking: 0,
    Finalizado: true,
  };

  const acceder = async () => {
    try {
      if (
        contrasena === null ||
        contrasena?.length <= 4 ||
        contrasena === undefined
      ) {
        dispatch(
          authActions.errorSistema(t("error_you_must_enter_your_password"))
        );
        return;
      }
      dispatch(gameActions.IsBusy());
      userData.Username = text;
      userData.Password = contrasena;
      console.log(userData);
      dispatch(authActions.loginPekeSinNavegacion(userData));
    } catch (error) {
      dispatch(gameActions.QuitarIsBusy());
    }
  };

  const crearCuenta = () => {
    dispatch(gameActions.IsBusy());
    userData.Username = text;
    userData.Password = contrasena;
    console.log(userData);
    dispatch(authActions.crearCuentaPekeSinNavegar(userData));
    dispatch(gameActions.QuitarIsBusy());
    props?.eventoCerrar();
  };

  useEffect(() => {
    if (authUser?.user?.Auth) {
      dataRanking.Finalizado = true;
      dataRanking.Punto = parseInt(partida.point.toFixed(0));
      dataRanking = OperacionSaveR(dataRanking, partida, authUser);
      gameService.SetRanking(dataRanking);
      localStorage.removeItem("jogo");
      dispatch(gameActions.QuitarIsBusy());
      props?.eventoCerrar();
    }
  }, [authUser]);
  return (
    <IonModal isOpen={props?.estadoModal} cssClass={styles.mini}>
      <IonContent>
        <img
          alt="Logo Peke Games"
          className={styles.login_logo}
          src="/assets/icon/logo_pk.png"
        ></img>
        <IonSegment
          value={loginActive ? "Login" : "Register"}
          onIonChange={(e) => {
            e.detail.value === "Login"
              ? setLoginActive(true)
              : setLoginActive(false);
          }}
        >
          <IonSegmentButton value="Login">
            <IonLabel> {t("login")}</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="Register">
            <IonLabel> {t("register")}</IonLabel>
          </IonSegmentButton>
        </IonSegment>

        <IonItem>
          <IonLabel color="secondary" position="floating">
            {t("enter_your_email_here")}
          </IonLabel>
          <IonInput
            value={text}
            onIonChange={(e) => setText(e.detail.value!)}
            clearInput
          ></IonInput>
        </IonItem>

        <IonItem>
          <IonLabel color="secondary" position="floating">
            {t("enter_your_password_here")}
          </IonLabel>
          <IonInput
            type="password"
            value={contrasena}
            placeholder="********"
            onIonChange={(e) => setContrasena(e.detail.value!)}
            onClick={(i) => setContrasena(i.detail.toString())}
            clearInput
          ></IonInput>
        </IonItem>

        {loginActive == true ? (
          ""
        ) : (
          <IonItem>
            <IonLabel color="secondary" position="floating">
              {t("confirm_password")}
            </IonLabel>
            <IonInput
              type="password"
              value={contrasenaConfirmar}
              placeholder="********"
              onIonChange={(e) => setContrasenaConfirmar(e.detail.value!)}
              clearInput
            ></IonInput>
          </IonItem>
        )}

        {loginActive === true ? (
          <div
            onClick={(p) => setShowModal(true)}
            className={styles.recuperar_contrasenia}
          >
            {t("recover_password")}
          </div>
        ) : (
          <div className={styles.salto_linea}></div>
        )}

        {loginActive === true ? (
          <IonButton
            shape="round"
            className="btn_sty"
            color="primary"
            onClick={acceder}
          >
            {t("enter")}
          </IonButton>
        ) : (
          <IonButton
            shape="round"
            className="btn_sty"
            color="primary"
            onClick={crearCuenta}
          >
            {t("create_account_button")}
          </IonButton>
        )}
      </IonContent>

      <IonIcon
        className="modal_close"
        onClick={props?.eventoCerrar}
        icon={closeCircle}
      />
    </IonModal>
  );
};

export default CrearCuenta;
