import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonPage,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToast,
  IonToolbar,
  useIonViewDidEnter,
} from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { history } from "../../_helpers/history";
import { authActions, gameActions } from "../../_redux/actions";
import { useEffect, useState } from "react";
import styles from "./login.module.scss";
import { useTranslation } from "react-i18next";
import { AuthStore, MyUser } from "../../_redux/models/auth.model";
import { closeCircle, closeCircleOutline, closeOutline, logoFacebook } from "ionicons/icons";
import { useFirebaseAnalytics } from "../../hooks/useFirebaseAnalytics";

const Login: React.FC<any> = (props) => {
  const notificationService = props.notificationService;
  const { logScreenView, setScreen } = useFirebaseAnalytics();
  useIonViewDidEnter(() => {
    logScreenView('Login');
    setScreen("Login");
  });

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const answered = useSelector((state: any) => state.game.answered);
  const currentQuestion = useSelector(
    (state: any) => state.game.currentQuestion
  );

  const loading = useSelector((store: AuthStore) => store.auth.isLoading);

  const myUser = useSelector((store: AuthStore) => store.auth);

  const [loginModal, setLoginModal] = useState(false);
  const [text, setText] = useState<string>();
  const [contrasena, setContrasena] = useState<string>();
  const [contrasenaConfirmar, setContrasenaConfirmar] = useState<string>();
  const [showModal, setShowModal] = useState(false);
  const [loginActive, setLoginActive] = useState<boolean>(true);

  const userData: MyUser = {
    Id: "-1",
    Avatar: "a0.svg",
    Nick:   t("guest"),
    NuevoUsuarioEnElJuego: false,
    PaisId: 0,
    Token: "",
    DatosExtendido: null,
    Username: "a0.svg",
    Password: "",
    Auth: false,
  };

  const handleInvitationLogin = () => {
    dispatch(authActions.loginInvitation());
  };

  const acceder = () => {
    if(contrasena === null || contrasena?.length<=4 || contrasena === undefined){
      dispatch(authActions.errorSistema(t("error_you_must_enter_your_password")));
      return;
    }
    dispatch(gameActions.IsBusy());
    userData.Username = text;
    userData.Password = contrasena;
    console.log(userData);
    dispatch(authActions.loginPeke(userData));
    dispatch(gameActions.QuitarIsBusy());
    setLoginModal(false);
  };

  const handleModalLogin = () => {
    setLoginModal(true);
  };

  const crearCuenta = () => {
    userData.Username = text;
    userData.Password = contrasena;
    console.log(userData);
    dispatch(authActions.crearCuentaPeke(userData));
    setLoginModal(false);
  };

  useEffect(() => {
    if (myUser.isAuth && window.location.pathname.includes("/login")) {
      history.replace("/dashboard");
    }
  }, [myUser]);

  return (
    <IonPage id="login-page">
      <IonContent className="bg_app_content_login">
        <div className="divNombreApp">
          <h1>{t("name_app")}</h1>
          <h2>{t("description_app")}</h2>
        </div>

        <div className={styles.login_buttons_group}>

          
        <IonButton
            shape="round"
            className="btn_sty"
            color="primary"
            onClick={handleModalLogin}
          >
            {t("login_as_user")}
          </IonButton>

          <IonButton
            shape="round"
            className="btn_sty"
            color="tertiary"
            onClick={handleInvitationLogin}
          >
            {t("guest_access")}
          </IonButton>

        </div>
        <IonLoading
          isOpen={loading}
          message={t("msg_loading")}
          duration={1000}
        />

        <IonModal isOpen={loginModal} cssClass={styles.mini}>
          <IonContent>
            <img
              alt="Logo Peke Games"
              className={styles.login_logo}
              src="/assets/icon/logo_pk.png"
            ></img>
            <IonSegment
              value={loginActive ? "Login" : "Register"}
              onIonChange={(e) => {
                e.detail.value === "Login"
                  ? setLoginActive(true)
                  : setLoginActive(false);
              }}
            >
              <IonSegmentButton value="Login">
                <IonLabel>{t("login")}</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="Register">
                <IonLabel>{t("register")}</IonLabel>
              </IonSegmentButton>
              
            </IonSegment>

            <IonItem>
              <IonLabel color="secondary" position="floating">
              {t("enter_your_email_here")}
              </IonLabel>
              <IonInput
                value={text}
                onIonChange={(e) => setText(e.detail.value!)}
                clearInput
              ></IonInput>
            </IonItem>

            <IonItem>
              <IonLabel color="secondary" position="floating">
              {t("enter_your_password_here")}
              </IonLabel>
              <IonInput
                type="password"
                value={contrasena}
                placeholder="********"
                onIonChange={(e) => setContrasena(e.detail.value!)}
                onClick={(i) => setContrasena(i.detail.toString())}
                clearInput
              ></IonInput>
            </IonItem>

            {loginActive === true ? (
              ""
            ) : (
              <IonItem>
                <IonLabel color="secondary" position="floating">
                {t("confirm_password")}
                </IonLabel>
                <IonInput
                  type="password"
                  value={contrasenaConfirmar}
                  placeholder="********"
                  onIonChange={(e) => setContrasenaConfirmar(e.detail.value!)}
                  clearInput
                ></IonInput>
              </IonItem>
            )}

            {loginActive === true ? (
              <div onClick={p => setShowModal(true)} className={styles.recuperar_contrasenia}>
                {t("recover_password")}
              </div>
            ) : (
              <div className={styles.salto_linea}></div>
            )}

            {loginActive === true ? (
              <IonButton
                shape="round"
                className="btn_sty"
                color="primary"
                onClick={acceder}
              >
                 {t("enter")}
              </IonButton>
            ) : (
              <IonButton
                shape="round"
                className="btn_sty"
                color="primary"
                onClick={crearCuenta}
              >
                {t("create_account_button")}
              </IonButton>
            )}
          </IonContent>

          <IonIcon
            className="modal_close"
            onClick={() => setLoginModal(false)}
            icon={closeCircle}
          />
        </IonModal>

        <IonModal isOpen={showModal} cssClass="fullscreen">
          <IonContent>
            <iframe
              id="mymodal"
              src="https://www.pekegames.app/en/Account/ForgotPassword"
              className="iframeStyle"
            ></iframe>
          </IonContent>
    
          <IonIcon
        className="modal_close"
        onClick={() => setShowModal(false)}
        icon={closeCircle} 
      />
        </IonModal>

        {/* {myUser.error && (
          <IonToast
            isOpen={myUser.error != null}
            message={myUser.error}
            color="danger"
            duration={5000}
          />
        )} */}
        
      </IonContent>
    </IonPage>
  );
};

export default Login;

