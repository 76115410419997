import {
  IonBackButton,
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonIcon,
  IonModal,
  IonPage,
  IonRow,
  IonSearchbar,
  IonTitle,
  IonToolbar,
  isPlatform,
  useIonActionSheet,
  useIonViewDidEnter,
} from "@ionic/react";
import { useSelector } from "react-redux";
import { AuthState, AuthStore, MyUser } from "../../_redux/models/auth.model";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import styles from "./store.module.scss";
import {
  apps,
  ellipsisHorizontal,
  ellipsisVertical,
  images,
  map,
  settings,
} from "ionicons/icons";
import { history } from "../../_helpers/history";
import MenuLateral from "../../components/Menu/MenuLateral";
import { DataGame, GameState } from "../../_redux/models/game.model";
import Enumerable from "linq";
import { gameService } from "../../_redux/services";
import { Tienda } from "../../_redux/models/tienda.model";
import { Producto } from "../../_redux/models/producto.model";
import { filtrarMeuDatos, OrdenarLoja } from "../../utils/functions";
import { authActions, gameActions } from "../../_redux/actions";
import {
  AdLoadInfo,
  AdMob,
  AdMobRewardItem,
  InterstitialAdPluginEvents,
  RewardAdOptions,
  RewardAdPluginEvents,
} from "@capacitor-community/admob";
import { Capacitor } from "@capacitor/core";
import { useFirebaseAnalytics } from "../../hooks/useFirebaseAnalytics";
import { MenuApp } from "../../utils/functions";

const Store: React.FC = () => {
  const { logScreenView, setScreen } = useFirebaseAnalytics();
  useIonViewDidEnter(() => {
    logScreenView("TiendaCompras");
    setScreen("TiendaCompras");
  });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const authUser: AuthState = useSelector((state: any) => state.auth);
  const [showModal, setShowModal] = useState(false);
  const [urlWiki, setUrlWiki] = useState("");
  const [present, dismiss] = useIonActionSheet();
  const [searchText, setSearchText] = useState("");
  const [lista, setLista] = useState<Tienda[]>([]);
  const [eventoPublicidad, setEventoPublicidad] = useState(false);
  const abrirModal = async (data: DataGame, triadvisor: boolean) => {
    setUrlWiki(triadvisor === true ? data.V : data.W);
    setShowModal(true);
  };

  const comprar = async (item: Tienda) => {
    try {
      dispatch(gameActions.IsBusy());
      authUser.user.aspj = item.id.toString();
      if (item.id === Producto.Cafe) {
        window.open("https://www.buymeacoffee.com/pekegames", "_blank");
      } else if (item.id === Producto.Moneda_Publicidad) {
        //MOSTRAR ANUNCIO, SI EL USUARIO TERMINA, LE DAMOS LAS 5 MONEDAS.
        //await actualizarPublicidad(authUser, dispatch, cargarTienda, cargado);
        await CargarPublicidadBonusVideo();
      } else {
        switch (item.id) {
          case Producto.AYUDA_50:
            authUser.user.DatosExtendido.Ayuda50++;
            break;
          case Producto.Ayuda_Contestar_2Veces:
            authUser.user.DatosExtendido.AyudaContestar2Veces++;
            break;
          case Producto.MAS_TIEMPO:
            authUser.user.DatosExtendido.AyudaMasTiempo++;
            break;
          case Producto.Ayuda_Pregunta_Correcta:
            authUser.user.DatosExtendido.AyudaPreguntaCorrecta++;
            break;
          case Producto.Recuperar_Puntos_Perdido:
            authUser.user.DatosExtendido.RecuperarPuntosPerdido++;
            break;
          default:
            break;
        }
        cargarTienda(authUser.user);
        authUser.user.aspj = OrdenarLoja(authUser.user);
        const comprarReponse = await gameService.ComprarAyuda(authUser.user);
        if (comprarReponse !== null) {
          dispatch(authActions.authSucess(comprarReponse));
          cargarTienda(comprarReponse);
        }
      }

      dispatch(gameActions.QuitarIsBusy());
    } catch (error) {
      dispatch(gameActions.QuitarIsBusy());
      dispatch(authActions.errorSistema(error.message));
    }

    async function CargarPublicidadBonusVideo() {
      if (Capacitor.isNativePlatform()) {
        console.log("cargado", eventoPublicidad);
        if (!eventoPublicidad) {
          AdMob.addListener(RewardAdPluginEvents.Loaded, (info: AdLoadInfo) => {
            // Subscribe prepared rewardVideo
          });

          AdMob.addListener(
            RewardAdPluginEvents.Rewarded,
            async (rewardItem: AdMobRewardItem) => {
              // Subscribe user rewarded
              authUser.user.DatosExtendido.MonedaGlobal += 5;
              if (authUser.user.Id === "-1") {
                localStorage.setItem(
                  "ctpk",
                  filtrarMeuDatos(JSON.stringify(authUser.user))
                );
                dispatch(authActions.authSucess(authUser.user));
                cargarTienda(authUser.user);
              } else {
                authUser.user.aspj = OrdenarLoja(authUser.user);
                const comprarReponse = await gameService.ComprarAyuda(
                  authUser.user
                );
                if (comprarReponse !== null) {
                  dispatch(authActions.authSucess(comprarReponse));
                  cargarTienda(comprarReponse);
                }
              }
              console.log(rewardItem);
            }
          );
          setEventoPublicidad(true);
        }
        const options: RewardAdOptions = {
          adId: isPlatform("android")
            ? "ca-app-pub-8932590852481744/7258342917"
            : "ca-app-pub-8932590852481744/6320380356"
          // npa: true
          // ssv: {
          //   userId: "A user ID to send to your SSV"
          //   customData: JSON.stringify({ ...MyCustomData })
          //}
        };
        await AdMob.prepareRewardVideoAd(options);
        await AdMob.showRewardVideoAd();
      } else {
        alert("impletar otros casos no nativo");
      }
    }
  };
  const cargarTienda = (perfil: MyUser) => {
    let mis_moneda = perfil?.DatosExtendido?.MonedaGlobal;
    let auxList: Tienda[] = [];
    /*
    auxList.push({
      id:Producto.Recuperar_Vida,
      producto: "Vida 1x",
      descripcion: "Para disfrutar de los juegos en la aplicación necesitas vidas. Si te quedas sin ellas, consiga una con esta opción.",
      cantidad: 1,
      precio: 10,
      icono: "/assets/icon/ayudaMasTiempo.png",
      puedoComprar: mis_moneda >= 10 ? true : false,
    });
    */
    auxList.push({
      id: Producto.Moneda_Publicidad,
      producto: t("store_publicidad_title"),
      descripcion: t("store_publicidad_descripcion"),
      cantidad: 5,
      precio: 0,
      icono: "/assets/icon/moneda.png",
      puedoComprar: true,
    });
    auxList.push({
      id: Producto.AYUDA_50,
      producto: t("store_50_title"),
      descripcion: t("store_50_description"),
      cantidad: 1,
      precio: 10,
      icono: "/assets/icon/ayuda50.png",
      puedoComprar: mis_moneda >= 10 ? true : false,
    });
    auxList.push({
      id: Producto.Ayuda_Contestar_2Veces,
      producto: t("store_2_oportunidad_title"),
      descripcion: t("store_2_oportunidad_title"),
      cantidad: 1,
      precio: 10,
      icono: "/assets/icon/oportunidadDoble.png",
      puedoComprar: mis_moneda >= 10 ? true : false,
    });
    auxList.push({
      id: Producto.MAS_TIEMPO,
      producto: t("store_mas_tiempo_title"),
      descripcion: t("store_mas_tiempo_description"),
      cantidad: 1,
      precio: 15,
      icono: "/assets/icon/ayudaMasTiempo.png",
      puedoComprar: mis_moneda >= 15 ? true : false,
    });
    auxList.push({
      id: Producto.Recuperar_Puntos_Perdido,
      producto: t("store_mas_recuperar_puntos_title"),
      descripcion: t("store_mas_recuperar_puntos_descrition"),
      cantidad: 2,
      precio: 20,
      icono: "/assets/icon/ayudaRecuperarPuntos.png",
      puedoComprar: mis_moneda >= 20 ? true : false,
    });

    auxList.push({
      id: Producto.Ayuda_Pregunta_Correcta,
      producto: t("store_respuesta_correcta_title"),
      descripcion: t("store_respuesta_correcta_description"),
      cantidad: 1,
      precio: 100,
      icono: "/assets/icon/ayudaRespuestaCorrecta.png",
      puedoComprar: mis_moneda >= 100 ? true : false,
    });

    auxList.push({
      id: Producto.Cafe,
      producto: t("store_coffe_title"),
      descripcion: t("store_coffe_description"),
      cantidad: 1,
      precio: 0,
      icono: "/assets/icon/cafe.png",
      puedoComprar: true,
    });

    setLista(auxList);
  };

  useEffect(() => {
    cargarTienda(authUser.user);
  }, []);

  const initService = async () => {
    try {
      if (authUser?.user.Auth && authUser?.user?.Id !== "-1") {
        const perfil = await gameService.Perfil();
        console.log("perfil", perfil);
        // setMyProfile(perfil);
        cargarTienda(perfil);
      } else {
        alert("modo invitado");
      }
    } catch (error) {}
  };

  const gameGlobal: GameState = useSelector((state: any) => state.game);
  return (
    <IonPage id="page-photo">
      <IonContent className="bg_app_content_photo">
        <IonToolbar className="menu_movil_2" color="primary">
          {MenuApp(present, t)}
          <IonTitle> {t("store")}</IonTitle>
          <IonButtons slot="start">
            <IonBackButton defaultHref="dashboard" />
          </IonButtons>
        </IonToolbar>

        <IonRow>
          <IonCol size="12" size-md="3" className="lateral hide_pc">
            <MenuLateral className="hide_pc"></MenuLateral>
          </IonCol>
          <IonCol size="12" size-md="7" className="centro_pagina_photo">
            <IonContent>
              <IonCard className={styles.card_ayuda}>
                <IonCardTitle className={styles.card_ayuda_titulo}>
                  {t("my_little_help")}
                </IonCardTitle>
                <IonCardSubtitle className={styles.card_ayuda_texto}>
                  {t("store_texto_parte_1", {
                    nick: authUser.user?.Nick,
                    moneda: authUser.user?.DatosExtendido?.MonedaGlobal,
                  })}
                  <img
                    width={30}
                    src="/assets/icon/moneda.png"
                    alt={t("coin")}
                  ></img>{" "}
                  {t("store_texto_parte_2")}
                </IonCardSubtitle>
                <IonCardContent>
                  <IonRow>
                    <IonCol className="text_center">
                      <img
                        alt={t("help_50")}
                        src="/assets/icon/ayuda50.png"
                        className="icon_ayuda_perfil"
                      ></img>
                    </IonCol>
                    <IonCol className="text_center">
                      <img
                        alt={t("help_longer")}
                        src="/assets/icon/ayudaMasTiempo.png"
                        className="icon_ayuda_perfil"
                      ></img>
                    </IonCol>
                    <IonCol className="text_center">
                      <img
                        alt={t("help_not_to_lose_points")}
                        src="/assets/icon/ayudaRecuperarPuntos.png"
                        className="icon_ayuda_perfil"
                      ></img>
                    </IonCol>
                    <IonCol className="text_center">
                      <img
                        alt={t("help_correct_answer")}
                        src="/assets/icon/ayudaRespuestaCorrecta.png"
                        className="icon_ayuda_perfil"
                      ></img>
                    </IonCol>
                    <IonCol className="text_center">
                      <img
                        alt={t("help_doble")}
                        src="/assets/icon/oportunidadDoble.png"
                        className="icon_ayuda_perfil"
                      ></img>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol className="text_center">
                      {authUser.user?.DatosExtendido?.Ayuda50}
                    </IonCol>
                    <IonCol className="text_center">
                      {authUser.user?.DatosExtendido?.AyudaMasTiempo}
                    </IonCol>
                    <IonCol className="text_center">
                      {authUser.user?.DatosExtendido?.RecuperarPuntosPerdido}
                    </IonCol>
                    <IonCol className="text_center">
                      {authUser.user?.DatosExtendido?.AyudaPreguntaCorrecta}
                    </IonCol>
                    <IonCol className="text_center">
                      {authUser.user?.DatosExtendido?.AyudaContestar2Veces}
                    </IonCol>
                  </IonRow>
                </IonCardContent>
              </IonCard>
              {Array.isArray(lista) &&
                lista.map((item, index) => {
                  return (
                    <IonCard className="ionCard" key={index}>
                      <IonCardHeader>
                        <IonCardTitle>{item.producto}</IonCardTitle>
                      </IonCardHeader>
                      <img
                        width={item.id === Producto.Cafe ? 300 : 50}
                        src={item.icono}
                        alt="ayuda"
                      ></img>
                      <IonCardContent>{item.descripcion}</IonCardContent>

                      {item.precio === 0 ? (
                        ""
                      ) : (
                        <IonBadge className={styles.bagge} color="primary">
                          <span className={styles.moneda}>{item.precio}</span>
                          <img
                            width={30}
                            src="/assets/icon/moneda.png"
                            alt="moneda"
                          ></img>
                        </IonBadge>
                      )}
                      <div className="botones">
                        <IonButton
                          shape="round"
                          color="success"
                          className={styles.boton_comprar}
                          size="large"
                          fill="solid"
                          disabled={!item.puedoComprar}
                          onClick={() => comprar(item)}
                        >
                          {item.id === Producto.Moneda_Publicidad
                            ? "Ver publicidad"
                            : "Comprar"}
                        </IonButton>

                        <p className="hidden"></p>
                      </div>
                    </IonCard>
                  );
                })}
                 <div className="margin_buttom_banner"></div>
            </IonContent>
           
          </IonCol>
          <IonCol size="12" size-md="2" className="hide_pc"></IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default Store;
