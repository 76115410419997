import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
  IonPage,
  IonRow,
  IonSearchbar,
  IonTitle,
  IonToolbar,
  useIonActionSheet,
  useIonViewDidEnter,
} from "@ionic/react";
import { useSelector } from "react-redux";
import { AuthStore } from "../../_redux/models/auth.model";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { gameActions } from "../../_redux/actions";
import { useEffect, useState } from "react";
import styles from "./photo.module.scss";
import {
  apps,
  close,
  closeCircle,
  closeOutline,
  ellipsisHorizontal,
  ellipsisVertical,
  fileTray,
  images,
  mail,
  map,
  settings,
} from "ionicons/icons";
import { history } from "../../_helpers/history";
import MenuLateral from "../../components/Menu/MenuLateral";
import { DataGame, GameState } from "../../_redux/models/game.model";
import Enumerable from "linq";
import { useFirebaseAnalytics } from "../../hooks/useFirebaseAnalytics";
import { MenuApp } from "../../utils/functions";
import { Icon } from "@material-ui/core";

const Photo: React.FC = () => {
  const { logScreenView, setScreen } = useFirebaseAnalytics();
  useIonViewDidEnter(() => {
    logScreenView("ListadoPuntosIntereses");
    setScreen("ListadoPuntosIntereses");
  });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const myUser = useSelector((store: AuthStore) => store.auth.user);
  const [showModal, setShowModal] = useState(false);
  const [urlWiki, setUrlWiki] = useState("");
  const [present, dismiss] = useIonActionSheet();
  const [searchText, setSearchText] = useState("");
  const [listcity, setListcity] = useState<DataGame[]>(null);

  const abrirModal = async (data: DataGame, triadvisor: boolean) => {
    setUrlWiki(triadvisor === true ? data.V : data.W);
    setShowModal(true);
  };

  useEffect(() => {
    dispatch(gameActions.ListCity);
    setListcity(gameGlobal.provinceCities);
  }, []);

  const buscador = async (data: string) => {
    setSearchText(data);
    setListcity(
      Enumerable.from(gameGlobal.provinceCities)
        .where(
          (p) =>
            p.T === "photo" &&
            (p.D.toLowerCase().includes(data.toLowerCase()) ||
              p.F.toLowerCase().includes(data.toLowerCase()))
        )
        .toArray()
    );
  };
  const gameGlobal: GameState = useSelector((state: any) => state.game);
  return (
    <IonPage id="page-photo">
      <IonContent className="bg_app_content_photo">
        <IonToolbar className="menu_movil_2">
          {MenuApp(present, t)}
          <IonTitle>{t("tourist_points")}</IonTitle>
          <IonButtons slot="start">
            <IonBackButton defaultHref="dashboard" />
          </IonButtons>
        </IonToolbar>

        <IonRow>
          <IonCol size="12" size-md="3" className="lateral hide_pc">
            <MenuLateral className="hide_pc"></MenuLateral>
          </IonCol>
          <IonCol size="12" size-md="7" className="centro_pagina_photo">
            <IonContent>
              <IonSearchbar
                placeholder={t("search")}
                value={searchText}
                onIonChange={(e) => buscador(e.detail.value!)}
              ></IonSearchbar>
              {Array.isArray(listcity) &&
                listcity.map((item, index) => {
                  if (!item.N.includes("https")) {
                    return;
                  }
                  return (
                    <IonCard className="ionCard" key={index}>
                      <img src={item?.N} alt={item.A} />
                      <div className="card_autor">{item.A}</div>
                      <IonCardHeader>
                        <IonCardTitle>{item.D}</IonCardTitle>
                        <IonCardSubtitle>{item.F}</IonCardSubtitle>
                      </IonCardHeader>

                      <IonCardContent>{item?.Z}</IonCardContent>

                      <div className="botones">
                        <IonButton
                          color="success"
                          fill="outline"
                          onClick={() => abrirModal(item, false)}
                        >
                          {t("information_wikipedia")}
                        </IonButton>

                        <IonButton
                          fill="outline"
                          color="secondary"
                          onClick={() => abrirModal(item, true)}
                        >
                          {t("informcion_triadvisor")}
                        </IonButton>
                        <p className="hidden"></p>
                      </div>
                    </IonCard>
                  );
                })}

              {(listcity?.length === 0 || listcity ===null)  && (
                <>
                  <div className="noDataContainer">
                  
                    <a href="mailto:hello@pekegames.app?subject={t('tourist_points_no_data')}&amp;body={searchText}">
                      <IonIcon
                        className="iconGrande"
                        icon={fileTray}
                        color="primary"
                      ></IonIcon>
                    </a>
                    <h3><span className="color_error">{searchText}</span>. {t("tourist_points_no_data_title")}</h3>
                    <p><a href="mailto:hello@pekegames.app?subject={t('tourist_points_no_data')}&amp;body={searchText}">{t("tourist_points_no_data")}</a></p>
                  </div>
                </>
              )}
              <div className="margin_buttom_banner"></div>
            </IonContent>
          </IonCol>
          <IonCol size="12" size-md="2" className="hide_pc"></IonCol>
        </IonRow>

        <IonModal isOpen={showModal} cssClass="fullscreen">
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="end">
                <IonButton onClick={() => setShowModal(false)}>
                  {" "}
                  {t("close")}
                </IonButton>
              </IonButtons>
              <IonTitle>Web</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <iframe id="mymodal" src={urlWiki} className="iframeStyle"></iframe>
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default Photo;
