import React, { Component, useEffect, useState } from "react";
import { TiempoResponse } from "../../_redux/models/tiempo.model";
import { gameService } from "../../_redux/services";
import DatoTuTiempo from "./DatoTuTiempo";
import Vaciotiempo from "./VacioTiempo";
export const TuTiempo: React.FC<any> = (props) => {
  const [eltiempo, setElTiempo] = useState<TiempoResponse>(null);
  useEffect(() => {
    if (props !== null && props.ciudad !== null) {
      actualizarTiempo();
    }
  });

  const actualizarTiempo = async () => {
    if (eltiempo !== null) {
      return;
    }
    const dataServicio = await gameService.GetElTiempo(props.ciudad);
    if (dataServicio !== null && dataServicio !== undefined) {
      setElTiempo(dataServicio);
    }
  };

  return (
    <div className="margin_buttom_banner">
      {eltiempo == null ? <Vaciotiempo ciudad={props.ciudad}></Vaciotiempo> : <DatoTuTiempo data={eltiempo}></DatoTuTiempo> }
    </div>
  );
};

export default TuTiempo;
