import React from 'react';
import AdSense from 'react-adsense';

const Ads = () => {
  return (
    <AdSense.Google
  client='ca-pub-8932590852481744'
  slot='9666943788'
  style={{ width: 300, height: 280, float: 'left' }}
  format=''
/>
  );
};

export default Ads;